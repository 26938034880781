//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../utils/utils.spinner.ts"  />
//imports-end

module Model.ClearableInput {

    export type PresentationType = 'list' | 'tree' | null;

    export interface IOptions {
        ID: string;
        InputType?: 'text';
        InputValue?: string;
        MinSearchTextLength?: number;
        ShowClearButton?: boolean;
        ShowFilterButton?: boolean;
        ShowPresentationButton?: boolean;
        ShowScanButton?: boolean;
        DefaultPresentation?: PresentationType;
    }

    export type SearchFilter = {
        SearchText?: string,
        Keywords?: string[]
    }

    export type PresentationFunc = (presentation: PresentationType) => void;
    export type FilterFunc = (filter: SearchFilter) => void;

    export class Settings implements IOptions {
        ID: string;
        InputType: 'text';
        InputValue?: string;
        MinSearchTextLength: number = 3;
        ShowClearButton: boolean;
        ShowFilterButton: boolean;
        ShowScanButton: boolean;
        ShowPresentationButton: boolean;
        DefaultPresentation: PresentationType;
        $control: any;
        $input: any;

        constructor(options: IOptions) {
            this.InputType = options.InputType || 'text';
            this.InputValue = options.InputValue;
            this.ID = options.ID || uuid();
            this.ShowClearButton = options.ShowClearButton || false;
            this.ShowPresentationButton = options.ShowPresentationButton || false;
            this.ShowFilterButton = options.ShowFilterButton || false;
            this.ShowScanButton = options.ShowScanButton || false;
            this.DefaultPresentation = options.DefaultPresentation || null;

            if (this.DefaultPresentation !== 'tree' && this.DefaultPresentation !== 'list') {
                this.DefaultPresentation = null;
            }

            if (options.MinSearchTextLength && options.MinSearchTextLength > 0) {
                this.MinSearchTextLength = options.MinSearchTextLength;
            }
        }
    }

    export class Control {
        ID: string;
        private Settings: Model.ClearableInput.Settings;
        private Filter: SearchFilter = {};

        private $input: any;
        private $presentationSwitch: any;
        private $filter: any;
        private SearchTimeout: number;

        private presentationListeners: PresentationFunc[] = [];
        private filterListeners: FilterFunc[] = [];

        constructor(settings: Model.ClearableInput.Settings) {
            if (!settings || !(settings instanceof Settings)) {
                return;
            }

            this.ID = settings.ID;
            this.Settings = settings;

            this.checkUniquenessOfID();
        }

        public Build($targetContainer): void {
            this.Settings.$control = $(Templates.ClearableInput({
                Type: this.Settings.InputType,
                Value: this.Settings.InputValue,
                ID: this.Settings.ID,
                ShowClearButton: this.Settings.ShowClearButton,
                ShowFilterButton: this.Settings.ShowFilterButton,
                ShowScanButton: this.Settings.ShowScanButton,
                ShowPresentationButton: this.Settings.ShowPresentationButton,
                DefaultPresentation: this.Settings.DefaultPresentation || (this.Settings.ShowPresentationButton ? 'list' : null)
            }));

            this.Settings.$input = this.Settings.$control.find('input');
            this.$input = this.Settings.$input;
            this.$presentationSwitch = this.Settings.$control.find('.presentation');
            this.$filter = this.Settings.$control.find('span.filter');

            $targetContainer.append(this.Settings.$control);

            this.bindEvents();
        }

        public Clear(preventTriggerInput: boolean): void {
            this.$input.val('');

            if (this.Filter) {
                delete this.Filter.SearchText;
            }

            if (!preventTriggerInput) {
                this.$input.trigger('input');
            }
        }

        public SetPlaceholderText(placeholderText: string) {
            if (this.$input) {
                this.$input.attr('placeholder', placeholderText);
            }
        }

        public GetFilter(): SearchFilter {
            return {
                Keywords: this.Filter.Keywords,
                SearchText: this.Filter.SearchText
            }
        }

        public SetFilter(filter: SearchFilter) {
            let hasChanges = false;

            if (filter.hasOwnProperty('SearchText') &&
                filter.SearchText != this.Filter.SearchText) {
                this.Filter.SearchText = filter.SearchText;
                hasChanges = true;

                if (this.$input && this.$input.length) {
                    this.$input.val(filter.SearchText);
                }
            }

            if (filter.hasOwnProperty('Keywords') &&
                !Utils.EqualsArray(this.Filter.Keywords, filter.Keywords)) {
                this.Filter.Keywords = filter.Keywords;
                this.$filter.toggleClass('red', (this.Filter.Keywords || []).length > 0);

                hasChanges = true;
            }

            if (hasChanges) {
                this.CallFilterListeners();
            }
        }

        public SetFilterButtonVisible(visible: boolean): void {
            if (!this.$filter) {
                return;
            }

            this.Settings.ShowFilterButton = visible;
            this.$filter.toggleClass('hidden', !visible);
        }

        public SetSearchPresentation(visible: false): void
        public SetSearchPresentation(visible: boolean, searchPresentation: PresentationType): void
        public SetSearchPresentation(visible: boolean, searchPresentation?: PresentationType): void {
            if (!this.$presentationSwitch) {
                return;
            }

            this.Settings.ShowPresentationButton = visible;
            this.Settings.DefaultPresentation = searchPresentation || null;

            this.$presentationSwitch.toggleClass('hidden', !visible);

            if (!visible) {
                return;
            }

            this.$presentationSwitch.toggleClass('icon-tree', searchPresentation === 'tree');
            this.$presentationSwitch.toggleClass('icon-list', searchPresentation !== 'tree');
        }

        public GetSearchPresentation(): PresentationType {
            if (!this.Settings.ShowPresentationButton) {
                return null;
            }

            if (this.$presentationSwitch.hasClass('icon-list')) {
                return 'list';
            } else if (this.$presentationSwitch.hasClass('icon-tree')) {
                return 'tree';
            }

            return null;
        }

        public AddPresentationListener(listener: PresentationFunc): Control {
            if (this.presentationListeners.indexOf(listener) < 0) {
                this.presentationListeners.push(listener);
            }

            return this;
        }

        public RemovePresentationListener(listener: PresentationFunc): Control {
            for (let i = this.presentationListeners.length - 1; i >= 0; i--) {
                if (this.presentationListeners[i] === listener) {
                    this.presentationListeners.splice(i, 1);
                }
            }

            return this;
        }

        public ClearPresentationListeners(): Control {
            this.presentationListeners = [];

            return this;
        }

        public AddFilterListener(listener: FilterFunc): Control {
            if (this.filterListeners.indexOf(listener) < 0) {
                this.filterListeners.push(listener);
            }

            return this;
        }

        public RemoveFilterListener(listener: FilterFunc): Control {
            for (let i = this.filterListeners.length - 1; i >= 0; i--) {
                if (this.filterListeners[i] === listener) {
                    this.filterListeners.splice(i, 1);
                }
            }

            return this;
        }

        public ClearFilterListeners(): Control {
            this.filterListeners = [];

            return this;
        }

        private onScanBarcodeClick(): void {
            Utils.StartScanner((result: { format: string, text: string }) => {
                this.SetFilter({ SearchText: result.text });
            });
        }

        private onClearButtonClick(): void {
            const hasChanges = this.Filter.Keywords && this.Filter.Keywords.length > 0 || !!this.Filter.SearchText;

            this.Filter.SearchText = null;
            this.Filter.Keywords = null;

            this.$input.val('');
            this.$filter.removeClass('red');

            if (hasChanges) {
                this.CallFilterListeners();
            }
        }

        private onFilterButtonClick(): void {
            const selectedKeywords = Utils.CloneArray(this.Filter.Keywords);

            Utils.ElementPickerPopup.Show({
                RootItem: DAL.Properties.GetRootByType(Enums.PropertyType.Keyword),
                Items: DAL.Properties.GetByType(Enums.PropertyType.Keyword),
                VisibleObjectTypes: [Enums.PropertyType.Keyword],
                IsNotALocationTree: true,
                ForceExpand: true,
                MultiSelectionAllowed: true,
                SelectedItemIdentifiers: selectedKeywords,
                CannotCollapseRoot: true,
                ShowResetButton: true,
                SearchFieldPlaceholder: i18next.t('SearchfieldPlaceholders.Keywords'),
                WindowTitle: i18next.t('ElementPicker.SelectKeywordFilter'),
                OnConfirmSelection: (result: Utils.ElementPickerPopup.ConfirmSelectionResult) => {
                    if (Utils.EqualsArray(this.Filter.Keywords, result.SelectedItems)) {
                        return;
                    }

                    this.Filter.Keywords = result.SelectedItems && result.SelectedItems.length ? result.SelectedItems : null;
                    this.$filter.toggleClass('red', this.Filter.Keywords && this.Filter.Keywords.length > 0);

                    this.CallFilterListeners();
                },
                OnResetButtonClick: () => {
                    this.$filter.removeClass('red');

                    if (this.Filter.Keywords != null ||
                        this.Filter.Keywords.length) {
                        this.Filter.Keywords = null;

                        this.CallFilterListeners();
                    }
                }
            });
        }

        private onChangePresentationButtonClick(evt: MouseEvent): void {
            evt.preventDefault();
            evt.stopPropagation();

            const $btn = $(evt.currentTarget);
            const showListIcon = $btn.hasClass('icon-tree');

            $btn.toggleClass('icon-list', showListIcon);
            $btn.toggleClass('icon-tree', !showListIcon);

            // Listener Events triggern
            const type = showListIcon ? 'list' : 'tree';
            for (const listener of this.presentationListeners) {
                listener(type);
            }
        }

        private checkUniquenessOfID(): void {
            if ($('#' + this.ID).length && console && console.debug) {
                console.debug(`ID ${this.ID} already exists`);
            }
        }

        private bindEvents(): void {
            if (this.Settings.ShowScanButton) {
                this.Settings.$control.find('.icon-barcode').on('click', () => { this.onScanBarcodeClick(); });
            }

            if (this.Settings.ShowClearButton) {
                this.Settings.$control.find('.icon-clear').on('click', () => { this.onClearButtonClick(); });
            }

            if (this.Settings.ShowFilterButton) {
                this.Settings.$control.find('.icon-filter').on('click', () => { this.onFilterButtonClick(); });
            }

            if (this.Settings.ShowPresentationButton) {
                this.Settings.$control.find('.presentation').on('click', (evt: MouseEvent) => { this.onChangePresentationButtonClick(evt); });
            }

            this.$input
                .off('input')
                .on('input', (evt: KeyboardEvent) => {
                    this.onHandleSearchInput(evt);
                })
                .off('blur')
                .on('blur', (evt: KeyboardEvent) => {
                    this.onHandleSearchInput(evt, true);
                })
                .off('keyup')
                .on('keyup', (evt: KeyboardEvent) => {
                    if (evt.key === 'Enter') {
                        this.onHandleSearchInput(evt, true);
                    } else if (evt.key === 'Escape') {
                        // Suchfeld zurücksetzen
                        (<HTMLInputElement>evt.currentTarget).value = '';
                        this.onHandleSearchInput(evt, true);
                    }
                });
        }

        private onHandleSearchInput(evt: KeyboardEvent, userTriggered: boolean = false) {
            const $input = $(evt.currentTarget);
            const searchText = $.trim($input.val().trim()) || null;
            userTriggered = userTriggered || evt.key === 'Enter';

            window.clearTimeout(this.SearchTimeout);

            // Prüfen ob relevante Änderungen existieren
            if (this.Filter.SearchText === searchText ||
                (searchText && searchText.length < this.Settings.MinSearchTextLength && !userTriggered)) {
                return;
            }

            this.Filter.SearchText = searchText;

            if (userTriggered) {
                this.CallFilterListeners();
            } else {
                this.SearchTimeout = window.setTimeout(() => {
                    this.CallFilterListeners();
                }, 350);
            }
        }

        public CallFilterListeners() {
            for (const listener of this.filterListeners) {
                listener(this.Filter);
            }
        }
    }
}