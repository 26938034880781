//imports-start
/// <reference path="../definitions.d.ts"  />
//imports-end

module Utils {

    export type KeyFunction<T> = (item: T) => string | number;

    export class OrderedDictionary<T extends object> {
        private ArrayBuffer: T[] = [];
        private BufferIndex: Dictionary<number> = {};

        private readonly keyFunc: KeyFunction<T>;

        get length(): number {
            return this.ArrayBuffer.length;
        }

        constructor(keyFunc: KeyFunction<T>)
        constructor(item: T, keyFunc: KeyFunction<T>)
        constructor(items: T[] | OrderedDictionary<T>, keyFunc: KeyFunction<T>)
        constructor(key_items?: T | T[] | OrderedDictionary<T> | KeyFunction<T> | null, keyFunc?: KeyFunction<T>) {
            if (typeof key_items === 'function') {
                this.keyFunc = <KeyFunction<T>>key_items;
            } else {
                if (typeof keyFunc != 'function') {
                    throw Error(`${this.constructor.name}: key function missing!`);
                }

                this.keyFunc = keyFunc;

                if (key_items instanceof OrderedDictionary) {
                    this.putRangeUnique(key_items);
                } else if (key_items instanceof Array) {
                    this.putRangeUnique(key_items);
                } else if (key_items) {
                    this.pushUnique(<T>key_items);
                }
            }
        }

        public pushUnique(item: T, key?: string | number, overwriteExisting: boolean = true): boolean {
            if (item == null && key == null) {
                return false;
            }

            key = key || this.keyFunc(item);

            const existingIndex = this.indexOf(key);

            if (existingIndex >= 0) {
                if (!overwriteExisting) {
                    // bisheriger Eintrag mit selbem Key wird behalten
                    return false;
                }

                // bisherigen Eintrag mit selbem Key überschreiben
                this.ArrayBuffer[existingIndex] = item;
                this.BufferIndex[key] = existingIndex;
            } else {
                // neuen Eintrag hinzufügen
                const newIndex = this.ArrayBuffer.length;

                this.ArrayBuffer.push(item);
                this.BufferIndex[key] = newIndex;
            }

            return true;
        }

        public putRangeUnique(items: T[], keyFunc?: KeyFunction<T>, overwriteExisting?: boolean): void
        public putRangeUnique(items: OrderedDictionary<T>, keyFunc?: KeyFunction<T>, overwriteExisting?: boolean): void
        public putRangeUnique(items: T[] | OrderedDictionary<T>, keyFunc?: KeyFunction<T>, overwriteExisting: boolean = true): void {
            if (!items) {
                return;
            }

            const itemsArray = items instanceof OrderedDictionary ? items.toArray() : items;

            keyFunc = keyFunc || this.keyFunc;

            for (let i = 0, len = itemsArray.length; i < len; ++i) {
                const tmpItem = itemsArray[i];
                const key = keyFunc(tmpItem);

                this.pushUnique(tmpItem, key, overwriteExisting);
            }
        }

        public get(index: number): T | null {
            return this.ArrayBuffer[index] || null;
        }

        public getByKey(key: string | number): T | null {
            const index = this.BufferIndex[key];

            return index >= 0 ? this.get(index) : null;
        }

        public remove(item: T): boolean {
            const index = this.indexOf(item);

            if (index < 0) {
                return false;
            }

            return this.removeByIndex(index);
        }

        public removeByKey(key: string | number): boolean {
            if (key == null) {
                return false;
            }

            const index = this.BufferIndex[key];

            if (index === null) {
                return false;
            }

            return this.removeByIndex(index);
        }

        public removeByIndex(index: number): boolean {
            if (index == null) {
                return false;
            }

            this.ArrayBuffer.splice(index, 1);

            this.updateBufferIndices(index);

            return true;
        }

        private updateBufferIndices(index: number): void {
            let keyToDelete: string | number;

            for (const key in this.BufferIndex) {
                if (this.BufferIndex.hasOwnProperty(key)) {
                    const itemIndex = this.BufferIndex[key];

                    if (itemIndex > index) {
                        this.BufferIndex[key] = itemIndex - 1;
                    } else if (itemIndex === index) {
                        keyToDelete = key;
                    }
                }
            }

            if (keyToDelete) {
                delete this.BufferIndex[keyToDelete];
            }
        }

        public clear(): void {
            this.ArrayBuffer = [];
            this.BufferIndex = {};
        }

        public has(key: number | string): boolean
        public has(item: T): boolean
        public has(item_key: number | string | T): boolean {
            return this.indexOf(<any>item_key) >= 0;
        }

        public indexOf(key: number | string): number
        public indexOf(item: T): number
        public indexOf(item_key: number | string | T): number {
            if (item_key == null) {
                return -1;
            }

            let indexOf: number;

            if (typeof item_key == 'string' ||
                typeof item_key == 'number') {
                indexOf = this.BufferIndex[item_key];
            } else {
                const key = this.keyFunc(item_key);
                if (key && this.BufferIndex[key] != null) {
                    indexOf = this.BufferIndex[key];
                } else {
                    indexOf = this.ArrayBuffer.indexOf(item_key);
                }
            }

            if (indexOf == null) {
                return -1;
            }

            return indexOf;
        }

        public size(): number {
            return this.ArrayBuffer.length;
        }

        public isEmpty(): boolean {
            return this.ArrayBuffer.length === 0;
        }

        public toArray(): T[] {
            return [].concat(this.ArrayBuffer);
        }

        public toDictionary(): Dictionary<T> {
            const resultDict: Dictionary<T> = {};

            for (const key in this.BufferIndex) {
                if (this.BufferIndex.hasOwnProperty(key)) {
                    const index = this.BufferIndex[key];
                    resultDict[key] = this.ArrayBuffer[index];
                }
            }

            return resultDict;
        }

        public keysToSet(): HashSet {
            return new HashSet(Object.keys(this.BufferIndex));
        }

        public keysToArray(): string[] {
            return Object.keys(this.BufferIndex);
        }
    }

    // Global Test Variable
    if (typeof window.__TEST__ != 'undefined') {
        window.__TEST__.Utils = window.__TEST__.Utils || Utils;
        window.__TEST__.Utils.OrderedDictionary = OrderedDictionary;
    }
}
