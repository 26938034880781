//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../dal/users.ts" />
/// <reference path="../dal/contacts.ts" />
/// <reference path="../app/app.sync-center.ts" />
/// <reference path="../model/model.logger.ts" />
/// <reference path="../model/menu/imenu-item-config.ts" />
/// <reference path="../utils/utils.cloning.ts" />
/// <reference path="../model/files/missing-file.ts"  />
//imports-end

module Utils.Synchronisation.Download {
    export type OnSyncErrorFunc = (httpStatusCode?: Enums.HttpStatusCode, rejectedRequest?, syncEntity?: Model.Synchronisation.IEntityDescription) => void;
    export type OnSyncSuccessFunc = () => void;

    let issueCommandToUpdateTree: boolean = false;
    let isDownloadingFiles: boolean = false;
    let _logger: Model.ILogger = new Model.NullLogger();
    const options = {
        IsInitialLogin: false
    };

    export enum FileSource {
        Recorditem = 0,
        Issue = 1,
        FileCatalog = 2
    }

    export enum FileUsage {
        Generic = 0,
        UsersAndTeams = 1,
        Menu = 2,
        Element = 3,
        ElementLayout = 4,
        IndividualData = 5
    }

    function onAfterFileDownloadFinished(): void {
        if (!Session.User) {
            return;
        }

        App.PrepareUserImageInHeader();
        SyncCenter.RemoveFileDownloadInfos();
    }

    function onFileDownloadError(response: Error): void {
        if (!Session.User) {
            return;
        }

        SyncCenter.OnSyncError(response);
    }

    function onFileDownloadProgress(currentFileIndex: number, numberOfFiles: number): void {
        SyncCenter.UpdateFileDownloadInfo(currentFileIndex, numberOfFiles);
    }

    function onAfterMainDownload(downloadManager: DownloadManager): Deferred {
        const resultDeferred = $.Deferred();

        let processQueue = $.Deferred().resolve();
        DAL.Cache.Rights.ResetUserRights();

        // Nach dem Download ist die Prozent-Anzeige bei 50 %
        let percentage = 50;
        // In der App wird die Prozent-Anzeige öfter aktualisiert (9x) und im Web 3x
        const percentageIncrease = percentage / (Session.IsSmartDeviceApplication ? 9 : 3);

        function notifyProgress() {
            percentage += percentageIncrease;
            resultDeferred.notify(percentage);
        }

        resultDeferred.notify(percentage);

        if (!Session.IsSmartDeviceApplication) {
            DAL.Elements.Store($.extend(true, [], downloadManager.ElementsLoader.GetElements()));
            notifyProgress();

            assignTeamsToElements();
            notifyProgress();

            // set menuItems
            Menu.GenerateMenuItems(downloadManager.MenuItemsLoader.GetMenuItems());
            notifyProgress();
        } else {
            determineWhetherToIssueCommandToUpdateTree(downloadManager.ElementsLoader.GetElements());

            const recorditems = downloadManager.RecorditemsCollector.GetRecorditems();

            updatePreviousRecorditems(downloadManager);

            notifyProgress();

            // save system data
            processQueue = processQueue
                .then(() => {
                    Session.LastKnownDataModelVersion = Session.LatestDataModelVersion;
                    Session.SaveSystemData(true)
                })
                .then(notifyProgress)
                .then(() => downloadManager.FilenamesCollector.Flush())
                .then(notifyProgress)
                .then(() => assignRecorditemsToElements(recorditems))
                .then(notifyProgress)
                .then(() => assignRecorditemsToIssues(recorditems))
                .then(notifyProgress)
                .then(() => updateAppMenuItems(downloadManager))
                .then(notifyProgress)
                .then(() => assignTeamsToElements())
                .then(notifyProgress)
                .then(() => updateScanCodesCache(downloadManager, recorditems))
                .then(notifyProgress)
                .then(() => cleanUpElementRecorditems(recorditems))
                .then(notifyProgress)
                .then(() => {
                    // async files download, no return required
                    downloadFilesAsync();
                })
                .always(() => {
                    Utils.Spinner.Hide();
                });
        }

        processQueue.then(resultDeferred.resolve, resultDeferred.reject);

        return resultDeferred;
    }

    function updateAppMenuItems(downloadedManager: DownloadManager): Deferred {
        // App: load all menuitems, if any changed, to generate treecache
        const newMenuItems = downloadedManager.MenuItemsLoader.GetMenuItems();
        if (newMenuItems && newMenuItems.length) {
            return window.Database.GetAllFromStorage(Enums.DatabaseStorage.CustomMenuItems)
                .then((menuItems: Array<Model.Menu.IMenuItemConfig>) => {
                    // create tree cache
                    DAL.TreeCache.GenerateTreeCacheFromMenuItems(menuItems);

                    // set menuItems
                    Menu.GenerateMenuItems(menuItems);
                });
        } else if (!Menu.HasMenuItems()) {
            // GenerateMenuItems after role & right changes (required especially on old API versions)
            Menu.GenerateMenuItems();
        }
    }

    /**
     * Aktualisiert den ScanCode Cache abhängig von den übergebenen Recorditems
     * @param downloadManager - Der DownloadManger welcher die Synchronisationsdaten beinhaltet
     * @param recorditems - Liste von Recorditems
     * @private
     */
    function updateScanCodesCache(downloadManager: DownloadManager, recorditems: Model.RawRecorditem[]): Deferred {
        const deferred = $.Deferred();
        const scanCodes: Model.Scancodes.ScancodeInfo[] = [];
        const recorditemsToCheck: Model.RawRecorditem[] = [];
        const missingElementRevisions: Dictionary<boolean> = {};
        const missingIssues: Dictionary<boolean> = {};
        const elements: Dictionary<Model.Elements.Element> = {};
        const issues: Dictionary<{ ID: number, IsArchived?: boolean, IsDeleted?: boolean }> =
            downloadManager.ScanCodesIssuesCollector.GetIssuesInfo();
        const oldElementOIDs = [];

        for (const recorditem of recorditems) {
            // Wenn kein Wert gesetzt ist oder der ElementType gesetzt ist und nicht Scancode ist überspringen
            if (!Utils.IsSet(recorditem.Value) ||
                (Utils.IsSet(recorditem.ElementType) && recorditem.ElementType !== Enums.ElementType.Scancode)) {
                continue;
            }

            // Wird erst zwischengespeichert, da es weitere Bedingungen gibt, die das Laden ggf. unnötig machen
            let loadIssueFromDatabase = false;

            if (recorditem.IssueID) {
                //Prüfen ob der Vorgang vorhanden ist und weder archiviert noch gelöscht ist
                const issue = issues[recorditem.IssueID];
                if (issue) {
                    if (issue.IsArchived || issue.IsDeleted) {
                        continue;
                    }
                } else {
                    // Vorgang muss aus der Datenbank geladen werden
                    loadIssueFromDatabase = true;
                }
            }

            const element = DAL.Elements.GetByRevisionOID(recorditem.ElementRevisionOID);
            if (element) {
                // Wenn das Element kein Scancode ist oder CheckForUniqueness nicht gesetzt ist das Recorditem ignorieren
                if (element.Type !== Enums.ElementType.Scancode ||
                    !element.AdditionalSettings || !element.AdditionalSettings.CheckForUniqueness) {
                    continue;
                }

                elements[element.RevisionOID] = element;
            } else {
                // Das Element ist nicht mehr aktiv
                missingElementRevisions[recorditem.ElementRevisionOID] = true;
            }

            recorditemsToCheck.push(recorditem);

            if (loadIssueFromDatabase) {
                missingIssues[recorditem.IssueID] = true;
            }
        }

        if (!recorditemsToCheck.length) {
            return deferred.resolve().promise();
        }

        // Historischen Elemente laden
        window.Database.GetManyByKeys(Enums.DatabaseStorage.HistoricalElements, Object.keys(missingElementRevisions), 'IDX_RevisionOID')
            .then((historicalElements: Model.Elements.Element[]) => {
                for (const historicalElement of (historicalElements || [])) {
                    elements[historicalElement.RevisionOID] = historicalElement;
                }
            })
            // Vorgänge laden
            .then(() => window.Database.GetManyByKeys(Enums.DatabaseStorage.Issues, Object.keys(missingIssues), 'IDX_ID'))
            .then((databaseIssues: Model.Issues.RawIssue[]) => {
                for (const issue of (databaseIssues || [])) {
                    if (Utils.IsSet(issue.ID)) {
                        issues[issue.ID] = <{ ID: number, IsArchived: boolean, IsDeleted?: boolean }>issue;
                    }
                }
            })
            .then(() => {
                for (const recorditem of recorditemsToCheck) {
                    const element = elements[recorditem.ElementRevisionOID];

                    // Wenn kein Element gefunden oder das Element kein Scancode ist oder CheckForUniqueness nicht gesetzt ist das Recorditem ignorieren
                    if (!element || element.Type !== Enums.ElementType.Scancode ||
                        !element.AdditionalSettings || !element.AdditionalSettings.CheckForUniqueness) {
                        continue;
                    }

                    if (Utils.IsSet(recorditem.IssueID)) {
                        // Prüfen ob der Vorgang vorhanden ist und weder archiviert noch gelöscht ist
                        const issue = issues[recorditem.IssueID];
                        if (!issue || issue.IsArchived || issue.IsDeleted) {
                            continue;
                        }
                    }

                    const scanCodeInfo = Model.Recorditem.CreateScanCodeInfo(recorditem);

                    // ElementOIDs alter AdHoc Werte
                    if (scanCodeInfo.ElementOID && !scanCodeInfo.ResubmissionitemOID &&
                        !Utils.InArray(oldElementOIDs, scanCodeInfo.ElementOID)) {
                        oldElementOIDs.push(scanCodeInfo.ElementOID);
                    }

                    scanCodes.push(scanCodeInfo);
                }
            })
            .then(() => DAL.ScancodeInfos.DeleteFromDatabaseByElementOID(oldElementOIDs))
            .then(() => window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.ScanCodes, scanCodes))
            .then(() => DAL.ScancodeInfos.Store(scanCodes))
            .then(deferred.resolve, deferred.reject);

        return deferred.promise();
    }

    function resolveDescendants(syncedIssues: Array<Model.Issues.RawIssue>): Array<Model.Issues.RawIssue> {
        /*
        * Clean issues from [Descendants]
        */
        if (!(syncedIssues || []).length) {
            return [];
        }

        const issues: Array<Model.Issues.RawIssue> = [];
        const issuesLookup: { [id: string]: boolean } = {};

        for (const issue of syncedIssues) {
            if (!issuesLookup[issue.OID]) {
                issues.push(issue);
                issuesLookup[issue.OID] = true;
            }

            if (!(issue.Descendants || []).length) {
                continue;
            }

            const subIssues = resolveDescendants(issue.Descendants);
            for (let cCnt = 0, cLen = subIssues.length; cCnt < cLen; cCnt++) {
                const child = subIssues[cCnt];

                if (!issuesLookup[child.OID]) {
                    issues.push(child);
                    issuesLookup[child.OID] = true;
                }
            }
            // remove [Descendants] from issue
            delete issue.Descendants;
        }

        return issues;
    }

    function determineWhetherToIssueCommandToUpdateTree(elements: Array<Model.Elements.Element>): void {
        issueCommandToUpdateTree = false;

        if (SyncCenter.GetHasTreeStructureChanged()) {
            issueCommandToUpdateTree = true;
            return;
        }

        if ((elements || []).length) {
            for (let eCnt = 0, eLen = elements.length; eCnt < eLen; eCnt++) {
                const element = elements[eCnt];
                const oldElement = DAL.Elements.GetByOID(element.OID);

                if (element.Deleted && (!oldElement || oldElement.Type === Enums.ElementType.Location)) {
                    issueCommandToUpdateTree = true;
                    break;
                } else if (Utils.InArray([Enums.ElementType.Root, Enums.ElementType.Location], element.Type)) {
                    if (!oldElement
                        || element.Title !== oldElement.Title
                        || element.ParentOID !== oldElement.ParentOID
                        || element.Position !== oldElement.Position) {
                        issueCommandToUpdateTree = true;
                        break;
                    }
                }
            }
        }
    }

    function assignRecorditemsToElements(recorditems: Array<Model.Recorditem>): Deferred {
        return DAL.Elements.AddRecorditems(recorditems);
    }

    function updatePreviousRecorditems(downloadManager: DownloadManager): void {
        if (!downloadManager.PreviousRecorditemsLoader) {
            return;
        }

        const previousRecorditems = downloadManager.PreviousRecorditemsLoader.GetRecorditems();

        if (!previousRecorditems || !previousRecorditems.length) {
            return;
        }

        for (const recorditem of previousRecorditems) {
            if (recorditem) {
                ParameterList.PreviousRecorditemsOfElements[recorditem.ElementOID] = recorditem;
            }
        }
    }

    function assignRecorditemsToIssues(srcRecorditems: Array<Model.Recorditem>): Deferred {
        const deferred = $.Deferred();
        const issueIdentifiers: { [key: number]: boolean } = {};
        const recorditems = (srcRecorditems || []).filter(function(recorditem) {
            if (+recorditem.IssueID) {
                if (!issueIdentifiers[recorditem.IssueID]) {
                    issueIdentifiers[parseInt(<any>recorditem.IssueID, 10)] = true;
                }

                return Utils.PrepareRecorditem(recorditem);
            }
        });

        if (Utils.HasProperties(issueIdentifiers)) {
            window.Database.GetManyByKeys(Enums.DatabaseStorage.Issues, Object.keys(issueIdentifiers), 'IDX_ID')
                .then((dbEntities: Array<Model.Issues.RawIssue>) => {
                    const issues: { [key: number]: Model.Issues.Issue } = {};

                    for (const i of dbEntities) {
                        const issue = new Model.Issues.Issue(i);

                        if (!issues[issue.ID] ||
                            issues[issue.ID].Revision < issue.Revision) {
                            issues[issue.ID] = issue;
                        }
                    }

                    DAL.Issues.AddRecorditems(recorditems, getArrayFromLookup(issues))
                        .then(() => {
                            deferred.resolve();
                        });
                });
        } else {
            deferred.resolve();
        }

        return deferred.promise();
    }

    function cleanUpElementRecorditems(recorditems: Model.Recorditem[]): Deferred {
        /**
         * Entfernt alle Erfassungen für Prüfpunkte am Raum, die nicht mehr benötigt werden.
         * @param {array} recorditems - Die zu Prüfenden und zu bereinigenden Erfassungen
         */

        // betroffene Elemente ermitteln
        const elementOIDsDict: Dictionary<boolean> = {};
        for (let i = 0; i < recorditems.length; i++) {
            const rec = recorditems[i];

            if (rec.ElementOID) {
                elementOIDsDict[rec.ElementOID] = true;
            }
        }

        // alle Elemente betreffenden Erfassungen laden
        return window.Database.GetManyByKeys(Enums.DatabaseStorage.Recorditems, Object.keys(elementOIDsDict), 'IDX_ElementOID')
            .then((affectedRecorditems: Model.Recorditem[]) => {
                // Sortierfunktion mit Datumswandlung
                const sortFunc = function(a: Model.Recorditem, b: Model.Recorditem) {
                    if (!b['TmpModificationTimestampAsDate']) {
                        b['TmpModificationTimestampAsDate'] = new Date(b.ModificationTimestamp);
                    }

                    if (!a['TmpModificationTimestampAsDate']) {
                        a['TmpModificationTimestampAsDate'] = new Date(a.ModificationTimestamp);
                    }

                    return b['TmpModificationTimestampAsDate'].getTime() - a['TmpModificationTimestampAsDate'].getTime();
                };

                // nach ElementOID aufteilen
                const byElementOID: Dictionary<Model.Recorditem[]> = {};
                for (let i = 0; i < affectedRecorditems.length; i++) {
                    const rec = affectedRecorditems[i];
                    // lokal erfasste, unsynchronisierte Erfassungen überspringen (ohne ID)
                    if (rec && rec.ID) {
                        byElementOID[rec.ElementOID] = byElementOID[rec.ElementOID] || [];
                        byElementOID[rec.ElementOID].push(rec);
                    }
                }

                const withIssueConstraint: Dictionary<Model.Recorditem[]> = {};
                const recorditemsToBeRemoved: Dictionary<Model.Recorditem> = {};

                // Analysieren, welche Elemente >2 Erfassungen haben, sortieren und zu prüfende Vorgänge aufnehmen
                for (const oid in byElementOID) {
                    if (!byElementOID.hasOwnProperty(oid)) {
                        continue;
                    }

                    const records = byElementOID[oid];
                    if (!records || records.length < 3) {
                        continue;
                    }

                    // sortieren nach Aktualität
                    records.sort(sortFunc);

                    // bisherige Erfassungen bis auf die letzten 2 Zum löschen aufnehmen
                    for (let ri = 2; ri < records.length; ri++) {
                        const rec = records[ri];
                        // Von Vorgängen abhängige Erfassungen
                        if (rec.IssueID) {
                            withIssueConstraint[rec.IssueID] = withIssueConstraint[rec.IssueID] || [];
                            withIssueConstraint[rec.IssueID].push(rec);
                        } else {
                            // unabhängige Erfassungen direkt hinzufügen
                            recorditemsToBeRemoved[rec.OID] = rec;
                        }
                    }
                }

                const issueIDs = Object.keys(withIssueConstraint);

                // keine Abhängigkeit zu Vorgängen => keine Prüfung der Vorgänge notwendig
                if (!issueIDs || !issueIDs.length) {
                    return recorditemsToBeRemoved;
                }

                // Prüfen welche Vorgänge lokal noch existieren
                return window.Database.Exists(Enums.DatabaseStorage.Issues, Object.keys(withIssueConstraint), 'IDX_ID')
                    .then((existingIssues: string[]) => {
                        // alle exitierenden Vorgänge aus der Liste entfernen
                        for (let i = 0; i < existingIssues.length; i++) {
                            const issueID = existingIssues[i];
                            delete withIssueConstraint[issueID];
                        }

                        // übrigen Erfassungen der Liste hinzufügen
                        for (const issueID in withIssueConstraint) {
                            if (!withIssueConstraint.hasOwnProperty(issueID)) {
                                continue;
                            }

                            const records = withIssueConstraint[issueID];
                            for (let i = 0; i < records.length; i++) {
                                const rec = records[i];
                                recorditemsToBeRemoved[rec.OID] = rec;
                            }
                        }

                        return recorditemsToBeRemoved;
                    });
            })
            .then(function(recorditemsToBeRemoved: Dictionary<Model.Recorditem>) {
                // alle Zugehörigkeiten der Erfassungen ermitteln und entfernen, wenn nicht unsynchronisiert
                const recordsArray = $.map(recorditemsToBeRemoved, (item: Model.Recorditem) => item);
                return Model.Recorditem.DeleteRecorditemsFromDatabase(recordsArray, false);
            });
    }

    function getArrayFromLookup(lookup: any): Array<any> {
        if (!lookup) {
            return [];
        }

        const array = [];

        for (let key in lookup) {
            if (lookup.hasOwnProperty(key)) {
                array.push(lookup[key]);
            }
        }

        return array;
    }

    function assignTeamsToElements() {
        const elements = DAL.Elements.GetAll();

        if (!elements) {
            return;
        }

        for (let identifier in elements) {
            DAL.Elements.AssignTeamsToElement(elements[identifier]);
        }
    }

    function downloadFilesAsync(): Deferred {
        return window.Database.GetAllFromStorage(Enums.DatabaseStorage.MissingFiles)
            .then((files: Model.Files.MissingFile[]) => {
                const indexedFiles = new OrderedDictionary<Model.Files.MissingFile>(files, file => file.Filename);

                try {
                    return FilesRequirementChecker.RunFilesCheckAndUpdate(indexedFiles)
                        .then(null, (e?: Error) => $.Deferred().reject(e, indexedFiles));
                } catch (e) {
                    return $.Deferred().reject(e, indexedFiles)
                }
            })
            .then(null, function(error: Error | any, indexedFiles: OrderedDictionary<Model.Files.MissingFile>): OrderedDictionary<Model.Files.MissingFile> | null | Deferred {
                const result = indexedFiles instanceof OrderedDictionary ? indexedFiles : null;

                if (error instanceof Error) {
                    console.log(error);

                    // Fehler wegschreiben für spätere Nachverfolgung
                    return SyncCenter.SaveError(error)
                        .then(() => result, () => result);
                }

                return result;
            })
            .then(function(indexedFiles: OrderedDictionary<Model.Files.MissingFile>): Deferred {
                const endDefer = $.Deferred();

                if (indexedFiles == null || !indexedFiles.size()) {
                    return endDefer.resolve().promise();
                }

                let hasConnectionError = false;
                let errorResponse = null;

                // Timeout global erhöhen und anschließend wieder zurücksetzen
                const timeoutBackup = cordova.plugin.http.getRequestTimeout();
                cordova.plugin.http.setRequestTimeout(Utils.Http.TIMEOUT_MS_MEDIUM / 1000);

                const onDownloadFailed = function(filename: string, response: { error: string, status: number, type: string }): Deferred {
                    const file = indexedFiles.getByKey(filename);

                    if (!file) {
                        return;
                    }

                    return Utils.CheckIfDeviceIsOnline()
                        .then(function() {
                            // als fehlgeschlagener Versuch bewerten, wenn Internetverbindung nicht Ursache war
                            if ((file.Tries || 0) >= 5) {
                                return window.Database.DeleteFromStorage(Enums.DatabaseStorage.MissingFiles, filename)
                                    .then(() => $.Deferred().reject()); // pass previous rejection
                            }

                            file.Tries = (file.Tries || 0) + 1;

                            return window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.MissingFiles, file)
                                .then(() => $.Deferred().reject()); // pass previous rejection
                        }, function() {
                            // Verbindungsprobleme als Fehler werten, weiteren Download abbrechen
                            hasConnectionError = true;
                            errorResponse = response;
                        });
                }

                const downloadNextFile = function(fileIndex: number): Deferred {
                    const fileInfo = indexedFiles.get(fileIndex);
                    const filename = fileInfo.Filename;
                    const hasLayoutUse = fileInfo.Usages && fileInfo.Usages.includes(FileUsage.ElementLayout);

                    onFileDownloadProgress(fileIndex + 1, indexedFiles.size());

                    if (Utils.InArray([FileSource.Recorditem, FileSource.Issue], fileInfo.Source)) {
                        return Utils.CheckIfFileExists(filename)
                            .then(function(fileExists: boolean) {
                                if (fileExists) {
                                    return window.Database.DeleteFromStorage(Enums.DatabaseStorage.MissingFiles, filename);
                                } else {
                                    return Utils.Http.DownloadFile(filename, hasLayoutUse)
                                        .then(null, onDownloadFailed);
                                }
                            });
                    } else {
                        return Utils.Http.DownloadFile(filename, hasLayoutUse)
                            .then(null, onDownloadFailed);
                    }
                };

                // start download files here
                isDownloadingFiles = true;
                let fileCounter = -1;

                (function nextStep(): Deferred {
                    if (fileCounter === indexedFiles.size() - 1 || !Session.User || hasConnectionError) {
                        isDownloadingFiles = false;

                        if (hasConnectionError) {
                            const connectionError = new Model.Errors.HttpError(errorResponse ? errorResponse.error : null, errorResponse);
                            endDefer.reject(connectionError);
                        } else {
                            endDefer.resolve();
                        }

                        return endDefer.promise();
                    } else {
                        return downloadNextFile(++fileCounter)
                            .always(nextStep);
                    }
                })();

                return endDefer
                    .always(() => {
                        // Timeout auf ursprünglichen Wert zurücksetzen
                        cordova.plugin.http.setRequestTimeout(timeoutBackup);
                    });
            })
            .then(onAfterFileDownloadFinished, onFileDownloadError);
    }

    export function GetAccountByCredentials() {
        return Utils.Http.Get('accounts', null, true)
            .then(function(account, state, response) {
                const apiVersion: number = parseInt(Utils.Http.GetResponseHeader(response, 'API-Version', '1'), 10);

                Utils.SetApiVersion(apiVersion);

                if (apiVersion >= 16 &&
                    account &&
                    account.User &&
                    account.User.LicenseType === Enums.LicenseType.ViewRight) {
                    return $.Deferred().reject().promise();
                }

                return account;
            }, function(_response, _state, _error) {
                throw new Model.Errors.HttpError(_error, _response);
            });
    }

    export function GetUserSettings(): Deferred {
        // available since API Version 13 only
        if (Session.LastKnownAPIVersion < 13) {
            return $.Deferred().resolve();
        }

        let requestUrl = 'userappsettings?withDefault=true';

        if (Utils.DateTime.IsValid(Session.LastSettingsSyncTimestamp)) {
            requestUrl += `&modifiedsince=${Utils.DateTime.ToGMTString(Session.LastSettingsSyncTimestamp)}`
        }

        return Utils.Http.Get(requestUrl)
            .then(function(settings: Array<{ IsLocked: boolean, Key: Enums.UserSettings, Value: any; LastModification: string }>, state, response) {
                if (!settings || !settings.length) {
                    return;
                }

                // save/update modified UserSettings
                for (let i = 0; i < settings.length; i++) {
                    const item = settings[i];

                    // special treatment for settings before login
                    if (Session.SettingsMetadata[item.Key] &&
                        Session.SettingsMetadata[item.Key].HasUnsyncedChanges) {
                        if (item.IsLocked) {
                            // reset settings if locked
                            Session.SettingsMetadata[item.Key] = null;
                        } else {
                            // pass settings from login dialog
                            item.Value = Session.Settings[item.Key];
                        }
                    }

                    // apply value from server
                    const hasChanges = Session.Settings.Set(item.Key, item.Value);

                    // save synced metadata
                    if (!Session.SettingsMetadata[item.Key]) {
                        Session.SettingsMetadata[item.Key] = new Model.SettingsMetadata();
                    }
                    Session.SettingsMetadata[item.Key].IsLocked = item.IsLocked;
                    Session.SettingsMetadata[item.Key].LastModificationTimestamp = item.LastModification;

                    // save highest last sync timestamp
                    const lastModification = new Date(item.LastModification)
                    if (!Session.LastSettingsSyncTimestamp ||
                        Session.LastSettingsSyncTimestamp < lastModification) {
                        Session.LastSettingsSyncTimestamp = lastModification;
                    }

                    // set value/execute setting changes
                    if (hasChanges) {
                        Settings.ApplyPostAction(item.Key, item.Value, true);
                    }
                }

                // update UserDeviceSettings with values from UserSettings
                Session.UserDeviceSettings = new Model.UserDeviceSettings((Session.User || {}).OID);

                // store newest settings to app database
                if (Session.IsSmartDeviceApplication) {
                    return Settings.Save();
                }
            }, function(_response, _state, _error) {
                throw new Model.Errors.HttpError(_error, _response);
            });
    }

    export function Start(logger: Model.ILogger, isInitialLogin?: boolean): Deferred {
        // create null-logger if none passed
        _logger = logger || new Model.NullLogger();

        const isSmartDevice = Session.IsSmartDeviceApplication;

        options.IsInitialLogin = isInitialLogin || false;

        if (Session.IsSmartDeviceApplication) {
            Utils.Spinner.UpdateText(i18next.t('Synchronization.SyncFromServer'));

            Session.CheckDataModelVersion();
        }

        _logger.LogMessage('Download', 'Starting download from ' + Session.BaseURI);

        const resultDeferred = $.Deferred();
        const downloadManager = new DownloadManager(isSmartDevice, _logger);

        downloadManager.StartDownload()
            .progress((percent: number) => {
                resultDeferred.notify(percent);
            })
            .then(() => onAfterMainDownload(downloadManager))
            .progress((percent: number) => {
                resultDeferred.notify(percent);
            })
            .then(() => {
                resultDeferred.notify(100);
            }, function(xhr: XMLHttpRequest | Error, status: string, errorText: string, request) {
                if (xhr instanceof Error) {
                    return $.Deferred().reject(xhr);
                }

                return $.Deferred().reject((xhr || { status: null }).status, request || this);
            })
            .then(resultDeferred.resolve, resultDeferred.reject);

        return resultDeferred.promise();
    }

    export function GetIsDownloadingFiles(): boolean {
        return isDownloadingFiles;
    }

    export function GetWhetherToIssueCommandToUpdateTree(): boolean {
        return issueCommandToUpdateTree;
    }

    export function UpdateAccount(): Deferred {
        return GetAccountByCredentials()
            .then((account: { Client: any, User: Model.Users.RawUser }) => {
                // Prüfen ob Passwortänderung erforderlich
                if (account.User.ChangePassword) {
                    const isInEditMode = !!IssueView.GetCurrentIssue() || !!IssueViewer.GetCurrentIssue();
                    if (isInEditMode) {
                        // Beim Vorgang Bearbeiten keine Passwortänderung anfragen
                        return $.Deferred().reject({ status: -1 });
                    }

                    return Utils.PasswordEditor.Show(account.User)
                        .then(function(newPassword: string) {
                            if (Session.IsSmartDeviceApplication && Session.LastKnownAPIVersion < 21) {
                                const username = Session.AuthHash.fromBase64().split(':')[0];
                                Session.AuthHash = (`${username}:${newPassword}`).toBase64();

                                delete account.User.ChangePassword;
                            }

                            return account;
                        });
                }

                return account;
            })
            .then((account: { Client: any, User: Model.Users.RawUser }) => {
                const rootHasChanged = account.User.RootElementOID !== Session.User.RootElementOID;
                const elementRightsHasChanged = !Utils.Equals(account.User.ElementRights, Session.User.ElementRights, 'elementRights');
                const reloadIssuesFromServer = account.Client.Settings.SyncOpenSurveys !== Session.Client.Settings.SyncOpenSurveys ||
                    account.Client.Settings.SyncOpenInvestigations !== Session.Client.Settings.SyncOpenInvestigations;

                const isInEditMode = !!IssueView.GetCurrentIssue() || !!IssueViewer.GetCurrentIssue();
                const requiresTreeUpdate = rootHasChanged || elementRightsHasChanged || Session.RequiresFullUpdate;
                const requiresIssuesReload = reloadIssuesFromServer || Session.RequiresIssuesReload;
                const hasCriticalChanges = requiresTreeUpdate || requiresIssuesReload;
                const preventDownload = hasCriticalChanges && isInEditMode;

                if (preventDownload || !hasCriticalChanges) {
                    return $.Deferred().resolve(account, {
                        TreeStructureChanged: requiresTreeUpdate,
                        ReloadIssuesFromServer: requiresIssuesReload,
                        PreventDownload: preventDownload
                    });
                }

                // bei vorhandensein von Unsync-Daten, Bestätigung erst nach Timeout ermöglichen
                return DAL.Sync.GetSyncEntitiesCount()
                    .then((count: number) => {
                        // nachfragen ob bei kritischen Änderungen mit dem Download fortgefahren werden soll, sonst nur Upload durchführen
                        const askDefer: Deferred = $.Deferred();

                        Utils.Message.Show(i18next.t('Synchronization.FullSyncConfirmation.MessageHeader'),
                            i18next.t('Synchronization.FullSyncConfirmation.MessageBody'),
                            {
                                No: () => {
                                    // nur Upload erlauben
                                    askDefer.resolve(account, {
                                        TreeStructureChanged: requiresTreeUpdate,
                                        ReloadIssuesFromServer: requiresIssuesReload,
                                        PreventDownload: true
                                    });
                                },
                                Yes: {
                                    Fn: () => {
                                        // Bestätigung Komplettsynchronisation
                                        askDefer.resolve(account, {
                                            TreeStructureChanged: requiresTreeUpdate,
                                            ReloadIssuesFromServer: requiresIssuesReload,
                                            PreventDownload: false
                                        });
                                    },
                                    Timeout: count > 0 ? 5 : 0
                                },
                                OnHide: () => {
                                    // Sync-Aktion abbrechen
                                    askDefer.reject({ status: -1 });
                                }
                            });
                        return askDefer;
                    });
            })
            .then((account: { Client: any, User: Model.Users.RawUser },
                changeInfo: { TreeStructureChanged: boolean, ReloadIssuesFromServer: boolean, PreventDownload: boolean }) => {

                if (!changeInfo.PreventDownload) {
                    // evtl. geänderte Benutzerrechte und Mandanteinstellungen
                    // sollen ohne "Komplettsynchronisation" nicht übernommen werden
                    Session.Client = account.Client;
                    Session.User = new Model.Users.User(account.User);
                }

                Session.User.AuthHash = Session.AuthHash;
                Session.RequiresFullUpdate = changeInfo.TreeStructureChanged;
                Session.RequiresIssuesReload = changeInfo.ReloadIssuesFromServer;
                Session.CheckIfAppUpdateIsRequired();

                return changeInfo;
            });
    }

    export function UpdateElements(modifiedSince: Date, elementTreeHasChanged: boolean) {
        const deferred = $.Deferred();

        if (Session.IsSmartDeviceApplication) {
            return deferred.reject().promise();
        }

        if (elementTreeHasChanged) {
            const onAfterSync = function() {
                if (!Session.CurrentLocation || Session.CurrentLocation.Deleted || !DAL.Elements.IsChildOfPersonalRoot(Session.CurrentLocation)) {
                    App.GetAndSelectLocation(DAL.Elements.Root.OID);
                }

                deferred.resolve();
            };

            const onSyncFail = function() {
                deferred.resolve();
            };

            DAL.Clear();
            DAL.Sync.Start(onAfterSync, onSyncFail, true, false, false);
        } else {
            Utils.Http.Get('/elements?modifiedsince=' + modifiedSince.toUTCString(), null, undefined, undefined, Utils.Http.TIMEOUT_MS_MEDIUM)
                .then(function(elements: Model.Elements.Element[]) {
                    DAL.Elements.Store(elements);

                    if (!Session.CurrentLocation || Session.CurrentLocation.Deleted || !DAL.Elements.IsChildOfPersonalRoot(Session.CurrentLocation)) {
                        App.GetAndSelectLocation(DAL.Elements.Root.OID);
                    }

                    deferred.resolve();
                }, function(_response, _state, _error) {
                    throw new Model.Errors.HttpError(_error, _response);
                });
        }

        return deferred.promise();
    }

    class IssueComparator {
        protected issuesIDs: number[];
        protected workQueue: Deferred;
        protected assignDeferreds: Deferred[];
        protected issueCache: Dictionary<Model.Issues.Issue>;

        protected static readonly MAX_ITEMS_PER_BATCH = 20;

        constructor() {
            this.Reset();
        }

        public Reset() {
            this.issuesIDs = [];
            this.workQueue = $.Deferred().resolve();
            this.assignDeferreds = [];
            this.issueCache = {};
        }

        public Feed(curIssue: Model.Issues.Issue): void {
            if (!curIssue) {
                return;
            }

            // add further Issues for comparison
            this.issueCache[curIssue.ID] = curIssue;
            const issueCacheKeys = Object.keys(this.issueCache);

            // process issues if cache limit is reached
            if (issueCacheKeys.length >= IssueComparator.MAX_ITEMS_PER_BATCH) {
                this.ProcessIssues(this.issueCache);
                this.issueCache = {};
            }
        }

        private ProcessIssues(issuesByIDs: Dictionary<Model.Issues.Issue>): Deferred {
            if (!issuesByIDs) {
                return $.Deferred().resolve();
            }
            // check for currently edited issue
            const editingIssue = IssueView.GetCurrentIssue();
            let subProcess: Deferred = null;
            if (editingIssue && issuesByIDs[editingIssue.ID]) {
                // show spinner, prevent editing Issue
                Utils.Spinner.Show();
                const issueLock = Utils.Spinner.Lock("issueUpdSync");

                const singleIssue = {};
                const tmpIssue = issuesByIDs[editingIssue.ID];
                singleIssue[editingIssue.ID] = tmpIssue;
                subProcess = this.SubProcessIssues(singleIssue)
                    .then(() => { IssueView.UpdateIssue(tmpIssue) })
                    .always(() => {
                        issueLock.Unlock();
                        Utils.Spinner.Hide();
                    });

                // remove processed issue from list
                delete issuesByIDs[editingIssue.ID];
            }

            const mainProcess = this.SubProcessIssues(issuesByIDs);
            if (subProcess) {
                return $.when(subProcess, mainProcess);
            }
            return mainProcess;
        }

        private SubProcessIssues(issuesByIDs: Dictionary<Model.Issues.Issue>): Deferred {
            const issuesIDs = Object.keys(issuesByIDs || {});
            if (!issuesIDs.length) {
                return $.Deferred().resolve();
            }

            this.workQueue = this.workQueue
                .then(() => window.Database.GetManyByKeys(Enums.DatabaseStorage.Issues, issuesIDs, 'IDX_ID'))
                .then((dbEntities: Model.Issues.Issue[]) => {
                    // add update issue worker
                    if (dbEntities && dbEntities.length) {
                        dbEntities.sort(Utils.SortDescendingByRevision)
                        const passedIssuesByID: Dictionary<boolean> = {};
                        for (let i = 0; i < dbEntities.length; i++) {
                            const dbEntity = dbEntities[i];
                            // distinct issues to latest revision per ID
                            if (passedIssuesByID[dbEntity.ID]) {
                                continue;
                            }
                            const newIssue = issuesByIDs[dbEntity.ID];
                            this.assignDeferreds.push(this.AssignAndSaveIssue(newIssue, dbEntity));
                            passedIssuesByID[dbEntity.ID] = true;
                        }
                    }

                    return $.when.apply($, this.assignDeferreds);
                });

            return this.workQueue;
        }

        private AssignAndSaveIssue(remoteIssue: Model.Issues.Issue, localDBIssue: Model.Issues.Issue): Deferred {
            // Vorgang Aktualisieren und Sichern
            if (localDBIssue.Type !== Enums.IssueType.Inspection) {
                // ResubmissionItems zusammenführen
                remoteIssue.Resubmissionitems = this.MergeResubmissionitemRows(localDBIssue, remoteIssue);
            }

            return this.UpdateRecorditems(remoteIssue, localDBIssue);
        }

        private MergeResubmissionitemRows(localIssue: Model.Issues.Issue, remoteIssue: Model.Issues.Issue): Model.Issues.ResubmissionItem[] {
            const localResubItems: Model.Issues.ResubmissionItem[] = localIssue.Resubmissionitems;
            const remoteResubitems: Model.Issues.ResubmissionItem[] = remoteIssue.Resubmissionitems;

            if (!(localResubItems || []).length) {
                // remoteResubitems verwenden, wenn lokal noch keine vorhanden
                return remoteResubitems;
            }

            if (!(remoteResubitems || []).length) {
                // localResubitems verwenden, wenn remote noch keine vorhanden
                return localResubItems;
            }

            // ResubmissionItems zusammenfügen
            const mergeResult = $.extend(true, [], remoteResubitems);
            const mergedResubitemOIDs: Dictionary<boolean> = {};
            const remoteResubitemsByOID: Dictionary<Model.Issues.ResubmissionItem> = {};
            let currentlyHighestRow: Dictionary<number> = {};

            localResubItems.sort(function(a: Model.Issues.ResubmissionItem, b: Model.Issues.ResubmissionItem) {
                return (a.Row || 0) - (b.Row || 0);
            });

            // höchstwerte für Teilproben Row ID ermitteln (nach ElementOID gruppiert)
            for (let rrCnt = 0, rrLen = remoteResubitems.length; rrCnt < rrLen; rrCnt++) {
                const remoteResubitem = remoteResubitems[rrCnt];
                const rowID = +remoteResubitem.Row;

                // remote Resubitem für schnellen Zugriff dem Dictionary hinzufügen
                remoteResubitemsByOID[remoteResubitem.OID] = remoteResubitem;

                if (!rowID) {
                    continue;
                }

                // höchste Row ID per Teilproben Prüfpunkt ermitteln
                if (!currentlyHighestRow[remoteResubitem.ElementOID] ||
                    rowID > currentlyHighestRow[remoteResubitem.ElementOID]) {
                    currentlyHighestRow[remoteResubitem.ElementOID] = rowID;
                }
            }

            // lokale Resubitems nach PGruppen aufteilen
            const localResubItemsByParentOID: Dictionary<Model.Issues.ResubmissionItem[]> = {};
            const localResubItemsByOID: Dictionary<Model.Issues.ResubmissionItem> = {};
            for (let lrCnt = 0, lrLen = localResubItems.length; lrCnt < lrLen; lrCnt++) {
                const localResubitem = localResubItems[lrCnt];
                localResubItemsByOID[localResubitem.OID] = localResubitem;

                // nur Teilproben oder noch nicht vorhandene Resubitems hinzufügen
                if (+localResubitem.Row || !remoteResubitemsByOID[localResubitem.OID]) {
                    const localParentOID = localResubitem.ParentOID;
                    if (!localResubItemsByParentOID[localParentOID]) {
                        localResubItemsByParentOID[localParentOID] = [];
                    }
                    localResubItemsByParentOID[localParentOID].push(localResubitem);
                }
            }

            // lokale Resubitems hinzufügen
            for (const parentOID in localResubItemsByParentOID) {
                // Resubitem kann direkt das Formular sein, dann überspringen
                if (!localResubItemsByParentOID.hasOwnProperty(parentOID)) {
                    continue;
                }

                // nicht Teilproben oder bereits aktualisierte Resubitems überspringen
                const localGroupResubitem = localResubItemsByOID[parentOID];
                const localGroupRowID = localGroupResubitem.Row;
                if (localGroupRowID && !mergedResubitemOIDs[localGroupResubitem.OID]) {
                    // Prüfe auf vorhandensein in remotes
                    const remoteResubitem = remoteResubitemsByOID[localGroupResubitem.OID];
                    if (remoteResubitem) {
                        continue;
                    }

                    // lokale Teilprobenzeile zu remotes hinzufügen

                    // neue RowID ermitteln
                    let newRowID = localGroupRowID;
                    if (!currentlyHighestRow[localGroupResubitem.ElementOID]) {
                        newRowID = currentlyHighestRow[localGroupResubitem.ElementOID] = 1;
                    } else {
                        newRowID = ++currentlyHighestRow[localGroupResubitem.ElementOID];
                    }

                    // alle PP zu der Teilprobe anpassen
                    const localResubitemsOfGroup = [localGroupResubitem].concat(localResubItemsByParentOID[localGroupResubitem.OID]);
                    for (let riCnt = 0, riLen = localResubitemsOfGroup.length; riCnt < riLen; riCnt++) {
                        const resubitemCopy: Model.Issues.ResubmissionItem = $.extend(true, {}, localResubitemsOfGroup[riCnt]);

                        resubitemCopy.Row = newRowID;

                        mergeResult.push(resubitemCopy);
                        mergedResubitemOIDs[resubitemCopy.OID] = true;

                        // recorditem für gemergtes Resubitem ebenfalls dem remoteIssue Hinzufügen
                        if (localIssue.Recorditems) {
                            const localRecorditem = Utils.Where(localIssue.Recorditems, 'ResubmissionitemOID', '===', resubitemCopy.OID);
                            if (localRecorditem) {
                                const recorditemCopy: Model.Recorditem = $.extend(true, {}, localRecorditem);
                                recorditemCopy.Row = newRowID;
                                if (!remoteIssue.Recorditems) {
                                    remoteIssue.Recorditems = [recorditemCopy];
                                } else {
                                    remoteIssue.Recorditems.push(recorditemCopy);
                                }
                            }
                        }
                    }
                } else {
                    // TODO (für später?) bei ungünstigem timing kann das ResubItem schon synchronisiert sein,
                    // das recorditem noch nicht, ist aber am localIssue dran und muss zu remoteIssue kopiert werden
                }
            }

            return mergeResult;
        }

        private UpdateRecorditems(remoteIssue: Model.Issues.Issue, localIssue: Model.Issues.Issue): Deferred {
            // Mergen von Recorditems
            remoteIssue.Recorditems = remoteIssue.Recorditems || [];

            // Dictionary für remote Recorditems erstellen
            const remoteRecorditemsByResubitemOID: Dictionary<Model.Recorditem> = {};
            for (let i = 0; i < remoteIssue.Recorditems.length; i++) {
                const remoteRecordItem = remoteIssue.Recorditems[i];
                remoteRecorditemsByResubitemOID[remoteRecordItem.ResubmissionitemOID] = remoteRecordItem;
            }

            // Vergleich und Mergen mit lokalen Recorditems
            const recorditemsToCheckByOID: Dictionary<Model.Recorditem> = {};
            for (let i = 0; i < (localIssue.Recorditems || []).length; i++) {
                const localRecordItem = localIssue.Recorditems[i];
                const remoteRecordItem = remoteRecorditemsByResubitemOID[localRecordItem.ResubmissionitemOID];

                if (!remoteRecordItem) {
                    // Recorditems vormerken zur Prüfung (löschen oder neu hinzugügen)
                    recorditemsToCheckByOID[localRecordItem.OID] = localRecordItem;
                }
            }

            // Prüfe SyncEntities für "fehlende" Recorditems
            return window.Database.GetManyByKeys(Enums.DatabaseStorage.SyncEntities, Object.keys(recorditemsToCheckByOID))
                .then((syncEntities: Model.Synchronisation.IEntityDescription[]) => {
                    for (let i = 0; i < syncEntities.length; i++) {
                        const entity = syncEntities[i];
                        // Update! Recorditem hinzufügen, die noch synchronisiert werden sollen
                        const localRecordItem = recorditemsToCheckByOID[entity.OID];
                        if (localRecordItem.ID) {
                            // ID entfernen, wenn der Datensatz auf dem Server nicht mehr existiert, um einen neuen erstellen
                            delete localRecordItem.ID;
                        }

                        delete recorditemsToCheckByOID[entity.OID];

                        remoteIssue.Recorditems.push(localRecordItem);
                    }

                    return window.Database.GetManyByKeys(Enums.DatabaseStorage.Recorditems, [remoteIssue.OID], 'IDX_IssueOID');
                })
                .then(function(recorditems: Array<Model.Recorditem>) {
                    if (!recorditems || !recorditems.length) {
                        return $.Deferred().resolve();
                    }

                    const updatedRecordItems: Array<Model.Recorditem> = [];
                    remoteIssue.Recorditems = remoteIssue.Recorditems || [];

                    for (let idx = 0; idx < recorditems.length; idx++) {
                        const recorditem = recorditems[idx];
                        const existingRecorditemIdx = Utils.Where(remoteIssue.Recorditems, 'OID', '===', recorditem.OID, true);

                        // IssueID aktualisieren, falls nicht vorhanden
                        if (!recorditem.IssueID) {
                            recorditem.IssueID = remoteIssue.ID;
                            updatedRecordItems.push(recorditem);
                        }

                        // entferne alte Revision des Recorditem
                        if (existingRecorditemIdx > -1) {
                            remoteIssue.Recorditems.splice(existingRecorditemIdx, 1);
                        }

                        // gelöschtes Recorditem, nicht mehr hinzugügen
                        if (recorditemsToCheckByOID.hasOwnProperty(recorditem.OID)) {
                            continue;
                        }

                        remoteIssue.Recorditems.push(recorditem);
                    }

                    return window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.Recorditems, updatedRecordItems);
                });
        }

        public Finish(): Deferred {
            // wait for completion of comparison and updates
            if (Utils.HasProperties(this.issueCache)) {
                this.ProcessIssues(this.issueCache);
                this.issueCache = {};
            }

            // make them work in queue
            return this.workQueue
                .then(() => $.when.apply($, this.assignDeferreds));
        }
    }

    class SyncEntitiesChecker {
        private issueIDCollector: Utils.HashSet;
        private issueOIDCollector: Utils.HashSet;
        private recorditemElementOIDCollector: Utils.HashSet;
        private recorditemElementOIDtoIssueOID: Dictionary<Utils.HashSet>;

        public Init(): Deferred {
            const issueIDCollector = new Utils.HashSet();
            const issueOIDCollector = new Utils.HashSet();
            const recorditemElementOIDCollector = new Utils.HashSet();
            const recorditemElementOIDtoIssueOID: Dictionary<Utils.HashSet> = {};

            return window.Database.GetAllFromStorage(Enums.DatabaseStorage.SyncEntities, (item: Model.Synchronisation.IEntityDescription) => {
                if (item.IssueID) {
                    issueIDCollector.put(item.IssueID);
                }

                if (item.Type === Enums.SyncEntityType.Issue) {
                    if (item.ID) {
                        issueIDCollector.put(item.ID);
                    }

                    if (item.OID) {
                        issueOIDCollector.put(item.OID);
                    }
                } else if (item.Type === Enums.SyncEntityType.Recorditem &&
                    (<Model.Synchronisation.RecordItemEntityDescription>item).ElementOID) {
                    const elementOID = (<Model.Synchronisation.RecordItemEntityDescription>item).ElementOID;
                    recorditemElementOIDCollector.put(elementOID);

                    if (item.IssueID) {
                        // Beziehung ElementOID <-> IssueID
                        recorditemElementOIDtoIssueOID[elementOID] = recorditemElementOIDtoIssueOID[elementOID] || new Utils.HashSet();
                        recorditemElementOIDtoIssueOID[elementOID].put(item.IssueID);
                    }
                }

                return null;
            }).then(() => {
                this.issueIDCollector = issueIDCollector;
                this.issueOIDCollector = issueOIDCollector;
                this.recorditemElementOIDCollector = recorditemElementOIDCollector;
                this.recorditemElementOIDtoIssueOID = recorditemElementOIDtoIssueOID;
            });
        }

        public IsIssuesCacheFilled(): boolean {
            return (this.issueIDCollector && !this.issueIDCollector.isEmpty()) ||
                (this.issueOIDCollector && !this.issueOIDCollector.isEmpty());
        }

        public HasIssueID(id: number): boolean {
            return this.issueIDCollector ? this.issueIDCollector.has(id) : false;
        }

        public HasIssueOID(oid: string): boolean {
            return this.issueOIDCollector ? this.issueOIDCollector.has(oid) : false;
        }

        public IsRecorditemsCacheFilled(): boolean {
            return this.recorditemElementOIDCollector && this.recorditemElementOIDCollector.size() > 0;
        }

        public HasRecorditemOnElementOID(elementOID: string, issueID?: number) {
            if (issueID && this.recorditemElementOIDtoIssueOID) {
                const set = this.recorditemElementOIDtoIssueOID[elementOID];
                return set ? set.has(issueID) : false;
            }

            return this.recorditemElementOIDCollector ? this.recorditemElementOIDCollector.has(elementOID) : false;
        }
    }

    interface DownloaderListener<T> {
        onCacheData(data: T[]): void;
    }

    class Downloader<T> {
        protected storageType: Enums.DatabaseStorage;
        protected path: Enums.EntityType | string;
        protected logger: Model.ILogger;
        protected saveData: boolean;
        protected autoSaveResponseTime: boolean;
        protected resultData: Array<T>;
        protected response: HttpResponse;
        protected onPrepareSave: (T) => T | any;
        protected onBeforeSave: (items: Array<T>) => Deferred;
        protected listeners: Dictionary<DownloaderListener<T>> = {};
        protected customTimeout: number = Utils.Http.TIMEOUT_MS_SHORT;
        private idCounter = 0;

        constructor(path: Enums.EntityType | string, storageType: Enums.DatabaseStorage, saveData: boolean = false, autoSaveResponseTime: boolean = true, logger?: Model.ILogger) {
            this.path = path;
            this.logger = logger || new Model.NullLogger();
            this.storageType = storageType;
            this.saveData = saveData;
            this.autoSaveResponseTime = autoSaveResponseTime;
        }

        public GetStarter(): Function {
            return $.proxy(this.Start, this);
        }

        public Start(): Deferred {
            // start download, save data and return downloader object
            return this.downloadFromServer()
                .then($.proxy(this.onAfterDownload, this))
                .then($.proxy(this.saveToDatabase, this))
                .then(() => {
                    if (this.resultData && this.resultData.length) {
                        return this.cacheResponse(this.resultData)
                    }
                }, function(_response, _state, _error) {
                    throw new Model.Errors.HttpError(_error, _response);
                })
                .then(() => {
                    return this;
                });
        }

        public setListener(listener: DownloaderListener<T>): string {
            const listenerID = (++this.idCounter).toString();
            this.listeners[listenerID] = listener;
            return listenerID;
        }

        public removeListener(listenerID: string): boolean {
            if (this.listeners[listenerID]) {
                delete this.listeners[listenerID];
            }

            return false;
        }

        public setOnPrepareSave(onPrepareSave: (T) => any | T) {
            this.onPrepareSave = onPrepareSave;
        }

        public setBeforeSave(onBeforeSave: (items: Array<T>) => Deferred) {
            this.onBeforeSave = onBeforeSave;
        }

        protected getLastModifiedDate(path: Enums.EntityType | string): string {
            return Session && Session.SynchronisationInformation ?
                Session.SynchronisationInformation.GetModifiedSinceParameter(path) :
                null;
        }

        protected buildUri(path: Enums.EntityType | string, additionalParams?: Dictionary<string | boolean | number | null>, suppressModifiedSince?: boolean): string {
            // ModifiedSince Parameter anfügen, wenn nicht bereits in additionalParams vorhanden
            if (!suppressModifiedSince &&
                (!additionalParams || !additionalParams['modifiedsince'])) {
                const modifiedSince = this.getLastModifiedDate(path);

                if (modifiedSince != null && modifiedSince.length) {
                    const keyValue = modifiedSince.split('=');
                    const key = keyValue[0];
                    const value = String(keyValue[1]);

                    additionalParams = additionalParams || {};
                    additionalParams[key] = value;
                }
            }

            let uri: string = path;

            if (additionalParams != null) {
                const uriParameter: string[] = [];

                // URI Parameter zusammenfügen
                for (const key in additionalParams) {
                    if (additionalParams.hasOwnProperty(key)) {
                        const paramValue = additionalParams[key];
                        uriParameter.push(`${key}=${paramValue}`);
                    }
                }
                // URI Parameter an URI anfügen
                if (uriParameter.length) {
                    uri += `?${uriParameter.join('&')}`;
                }
            }

            // log uri
            this.logger.LogData('Download', path, uri);

            return uri;
        }

        protected getDownloadUri(): string {
            return this.buildUri(this.path);
        }

        protected downloadFromServer(): Deferred {
            return Utils.Http.Get(this.getDownloadUri(), null, undefined, undefined, this.customTimeout);
        }

        protected onAfterDownload(data: T | Array<T>, state: string, response: HttpResponse, cacheResult: boolean = true): Deferred {
            // log download data
            this.logger.LogData('Download', `Download ${this.path} finished`, data);
            this.response = response;

            // Daten zu Array normalisieren
            const dataToSave: Array<T> = !data ? [] : data instanceof Array ? data : [data];

            // cache results
            if (cacheResult && dataToSave && dataToSave.length) {
                if (!this.resultData || !this.resultData.length) {
                    this.resultData = dataToSave;
                } else if (dataToSave.length > this.resultData.length) {
                    this.resultData = dataToSave.concat(this.resultData);
                } else {
                    this.resultData = this.resultData.concat(dataToSave);
                }
            }

            // alle registrierten Listener über Daten benachrichtigungen
            this.notifyListeners(dataToSave);

            return $.Deferred().resolve(dataToSave, state, response);
        }

        protected saveToDatabase(data: Array<T>): Deferred {
            if (!this.saveData) {
                return this.onAfterSave();
            }

            if (!window.Database) {
                // TODO replace logging with unified logger
                return this.onAfterSave();
            }

            if (!data || !data.length) {
                return this.updateResponseInfo()
                    .then(() => this.onAfterSave());
            }

            if (this.onPrepareSave) {
                for (let dCnt = 0, dLen = data.length; dCnt < dLen; dCnt++) {
                    const tmpResult = this.onPrepareSave(data[dCnt])
                    if (tmpResult) {
                        data[dCnt] = tmpResult;
                    }
                }
            }

            const deferred = this.onBeforeSave ? this.onBeforeSave(data) : $.Deferred().resolve().promise();

            return deferred
                .then(() => window.Database.SetInStorageNoChecks(this.storageType, data))
                .then(() => {
                    // log saved data
                    this.logger.LogData('Save Download', `Saved downloaded ${this.path} data`, data);

                    return this.updateResponseInfo();
                })
                .then(() => this.onAfterSave());
        }

        protected onAfterSave(): Deferred {
            return $.Deferred().resolve();
        }

        protected updateResponseInfo(): Deferred {
            const syncInfo = this.GetResponseInformation();
            if (window.Session && Session.SynchronisationInformation) {
                Session.SynchronisationInformation.AddInfo(syncInfo);
            }

            if (this.autoSaveResponseTime && syncInfo) {
                return window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.SynchronisationInformation, syncInfo.GetDBItem());
            }

            return $.Deferred().resolve();
        }

        protected notifyListeners(_data: Array<T>): Deferred {
            if (_data && _data.length) {
                for (let key in this.listeners) {
                    const listener: DownloaderListener<T> = this.listeners[key];
                    if (listener) {
                        listener.onCacheData(_data);
                    }
                }
            }

            return $.Deferred().resolve();
        }

        protected cacheResponse(_data: Array<T>): Deferred {
            return $.Deferred().resolve();
        }

        public GetResponseInformation(alternateEntityName?: string): Model.Synchronisation.ResponseInformation {
            if (this.response == null) {
                return null;
            }

            const entityName = alternateEntityName || this.path;
            const responseDate = Utils.Http.GetResponseHeader(this.response, 'Date');
            const modifiedDate = Utils.Http.GetResponseHeader(this.response, 'Last-Modified');

            if (window.Session && Session.SynchronisationInformation &&
                Session.SynchronisationInformation.GetByType) {

                // update response information
                let responseInfo = Session.SynchronisationInformation.GetByType(entityName);
                if (responseInfo) {
                    // check if sync is repeating
                    if (responseInfo.LastModifiedDate && modifiedDate) {
                        responseInfo.IsRepeating = responseInfo.LastModifiedDate.getTime() == new Date(modifiedDate).getTime();
                    }
                    responseInfo.LastModifiedDate = modifiedDate ? new Date(modifiedDate) : null;
                    responseInfo.ResponseDate = responseDate ? new Date(responseDate) : null;

                    return responseInfo;
                }
            }

            // erste Synchronisation, noch keine Daten
            return new Model.Synchronisation.ResponseInformation(
                entityName,
                responseDate ? new Date(responseDate) : null,
                modifiedDate ? new Date(modifiedDate) : null,
                false
            );
        }
    }

    class RolesDownloader extends Downloader<Model.Roles.Role> {

        constructor(saveData: boolean = false, autoSaveResponseTime: boolean = true, logger?: Model.ILogger) {
            super(Enums.EntityType.ROLES, Enums.DatabaseStorage.Roles, saveData, autoSaveResponseTime, logger);
        }

        protected cacheResponse(_data: Array<Model.Roles.Role>): Deferred {
            DAL.Roles.Store(_data);
            return super.cacheResponse(_data);
        }
    }

    class TeamsDownloader extends Downloader<Model.Teams.RawTeam> {

        constructor(saveData: boolean = false, autoSaveResponseTime: boolean = true, logger?: Model.ILogger) {
            super(Enums.EntityType.TEAMS, Enums.DatabaseStorage.Teams, saveData, autoSaveResponseTime, logger);
        }

        protected cacheResponse(_data: Array<Model.Teams.RawTeam>): Deferred {
            DAL.Teams.Store(_data);
            return super.cacheResponse(_data);
        }
    }

    class UsersDownloader extends Downloader<Model.Users.RawUser> {

        constructor(saveData: boolean = false, autoSaveResponseTime: boolean = true, logger?: Model.ILogger) {
            super(Enums.EntityType.USERS, Enums.DatabaseStorage.Persons, saveData, autoSaveResponseTime, logger);
        }

        protected cacheResponse(_data: Array<Model.Users.RawUser>): Deferred {
            DAL.Users.Store(_data);
            return super.cacheResponse(_data);
        }
    }

    class PropertiesDownloader extends Downloader<Model.Properties.Property> {
        protected customTimeout: number = Utils.Http.TIMEOUT_MS_MEDIUM;

        constructor(saveData: boolean = false, autoSaveResponseTime: boolean = true, logger?: Model.ILogger) {
            super(Enums.EntityType.PROPERTIES, Enums.DatabaseStorage.Properties, saveData, autoSaveResponseTime, logger);
        }

        protected cacheResponse(_data: Array<Model.Properties.Property>): Deferred {
            DAL.Properties.Store(Utils.Clone(_data));
            return super.cacheResponse(_data);
        }
    }

    class SchedulingsDownloader extends Downloader<Model.Scheduling.RawScheduling> {
        protected customTimeout: number = Utils.Http.TIMEOUT_MS_MEDIUM;

        constructor(saveData: boolean = false, autoSaveResponseTime: boolean = true, logger?: Model.ILogger) {
            super(Enums.EntityType.SCHEDULING, Enums.DatabaseStorage.Scheduling, saveData, autoSaveResponseTime, logger);
        }

        protected cacheResponse(_data: Array<Model.Scheduling.RawScheduling>): Deferred {
            DAL.Scheduling.Store(_data);
            return super.cacheResponse(_data);
        }
    }

    class ContactsDownloader extends Downloader<Model.Contacts.Contact> {
        protected customTimeout: number = Utils.Http.TIMEOUT_MS_MEDIUM;

        constructor(saveData: boolean = false, autoSaveResponseTime: boolean = true, logger?: Model.ILogger) {
            super(Enums.EntityType.CONTACTS, Enums.DatabaseStorage.Contacts, saveData, autoSaveResponseTime, logger);
        }

        protected cacheResponse(_data: Array<Model.Contacts.Contact>): Deferred {
            DAL.Contacts.Store(_data);
            return super.cacheResponse(_data);
        }
    }

    class ContactGroupsDownloader extends Downloader<Model.ContactGroups.ContactGroup> {
        protected customTimeout: number = Utils.Http.TIMEOUT_MS_MEDIUM;

        constructor(saveData: boolean = false, autoSaveResponseTime: boolean = true, logger?: Model.ILogger) {
            super(Enums.EntityType.CONTACTGROUPS, Enums.DatabaseStorage.ContactGroups, saveData, autoSaveResponseTime, logger);
        }

        protected cacheResponse(_data: Array<Model.ContactGroups.ContactGroup>): Deferred {
            DAL.ContactGroups.Store(_data);
            return super.cacheResponse(_data);
        }
    }

    class SchemasDownloader extends Downloader<Model.IndividualData.Schema> {

        constructor(saveData: boolean = false, autoSaveResponseTime: boolean = true, logger?: Model.ILogger) {
            super(Enums.EntityType.SCHEMAS, Enums.DatabaseStorage.Schemas, saveData, autoSaveResponseTime, logger);
        }

        public GetData(): Array<Model.IndividualData.Schema> {
            // TODO test
            return this.resultData;
        }

        protected cacheResponse(_data: Array<Model.IndividualData.Schema>): Deferred {
            DAL.Schemas.Store(_data);
            return super.cacheResponse(_data);
        }
    }

    class ByChangeIDDownloader<T> extends Downloader<T> {
        protected customTimeout: number = Utils.Http.TIMEOUT_MS_MEDIUM;
        protected syncInfo: Model.Synchronisation.ChangeResponseInformation;
        protected MAX_TAKE: number = 20000;
        protected ApplyModifiedSince: boolean = true;

        constructor(path: Enums.EntityType | string, storageType: Enums.DatabaseStorage, saveData: boolean = false, autoSaveResponseTime: boolean = true, logger?: Model.ILogger) {
            super(path, storageType, saveData, autoSaveResponseTime, logger);

            // SyncInfo aufbereiten
            if (window.Session && Session.SynchronisationInformation &&
                Session.SynchronisationInformation.GetByType) {

                // bestehende SyncInfo übernehmen und erweitern
                this.syncInfo = <Model.Synchronisation.ChangeResponseInformation>Session.SynchronisationInformation.GetByType(path);
            }

            if (!this.syncInfo) {
                this.syncInfo = new Model.Synchronisation.ChangeResponseInformation(path);
            } else {
                if (this.syncInfo.RemainingDataItems) {
                    // Unfertige Synchronisation wird fortgesetzt, keine Prüfung auf If-Modified-Since erlauben
                    this.ApplyModifiedSince = false;
                }
            }
        }

        protected downloadFromServer(): Deferred {
            let additionalHeaders: Dictionary<string> = undefined;

            if (this.ApplyModifiedSince && this.syncInfo.LastModifiedDate) {
                // mit If-Modified-Since Datenbank Abfragen auf dem Server reduzieren
                additionalHeaders = {
                    'If-Modified-Since': Utils.DateTime.ToGMTString(this.syncInfo.LastModifiedDate),
                    'If-None-Match': this.syncInfo.ETag || ''
                };
            }

            return Utils.Http.Get(this.getDownloadUri(), null, undefined, undefined, this.customTimeout, additionalHeaders)
                .then(null, function(response, status: string, error: string) {
                    if (response.status === Enums.HttpStatusCode.Not_Modified) {
                        // 304 Meldung nicht als Fehler behandeln
                        return $.Deferred().resolveWith(this, [null, status, response]);
                    }

                    return $.Deferred().rejectWith(this, [response, status, error]);
                });
        }

        protected getDownloadUri(): string {
            if (Session.LastKnownAPIVersion >= 32) {
                // Synchronisation nach ChangeID anwenden
                const urlParams = {
                    'lastchangeid': this.syncInfo.LastChangeID || '0',
                    'take': this.MAX_TAKE
                };

                if (!this.syncInfo.InitialSyncFinished) {
                    // bei Erstsynchronisation nur aktive Daten abrufen
                    urlParams['activeonly'] = 'true';
                };

                return this.buildUri(this.path, urlParams, true);
            }

            return this.buildUri(this.path);
        }

        protected onAfterDownload(data: T[], state: string, response: HttpResponse): Deferred
        protected onAfterDownload(data: T[], state: string, response: HttpResponse, suppressSyncInfoUpdate: boolean): Deferred
        protected onAfterDownload(data: T[], state: string, response: HttpResponse, suppressSyncInfoUpdate?: boolean): Deferred {
            if (!suppressSyncInfoUpdate) {
                // prüfen und syncInfo aktualisieren, wenn weitere Downloads nötig sind
                this.updateSyncInfo(data, response);
            }

            // Daten zur "Speichern" Methode weitergeben
            return super.onAfterDownload(data, state, response);
        }

        protected updateSyncInfo(data: { ChangeID?: string }[], response: HttpResponse): void {
            if (Session.LastKnownAPIVersion < 32) {
                // keine weiteren Aktionen bei API < 32 nötig
                return;
            }

            const remainingitems = Utils.Http.GetResponseHeader(response, 'RemainingItems');
            this.syncInfo.RemainingDataItems = remainingitems && !isNaN(+remainingitems) ? Number(remainingitems) : 0;

            const lastModified = Utils.Http.GetResponseHeader(response, 'Last-Modified');
            if (lastModified) {
                this.syncInfo.LastModifiedDate = new Date(lastModified);
            }

            this.syncInfo.ETag = Utils.Http.GetResponseHeader(response, 'ETag', '');

            const lastChangeID = Utils.Http.GetResponseHeader(response, 'LastChangeID');
            if (lastChangeID) {
                this.syncInfo.LastChangeID = lastChangeID || this.syncInfo.LastChangeID || '0';
            } else if (data && data.length) {
                this.syncInfo.LastChangeID = this.syncInfo.LastChangeID || '0';

                for (const file of data) {
                    if (!file.ChangeID) {
                        continue;
                    }

                    if (file.ChangeID.length > this.syncInfo.LastChangeID.length ||
                        file.ChangeID > this.syncInfo.LastChangeID) {
                        this.syncInfo.LastChangeID = file.ChangeID;
                    }
                }
            }

            if (this.syncInfo.RemainingDataItems) {
                // Es gab Änderungen auf dem Server, If-Modified-Since darf
                // nicht angewandt werden, bevor alle Daten geladen wurden
                this.ApplyModifiedSince = false;
            } else {
                this.syncInfo.InitialSyncFinished = true;
                this.ApplyModifiedSince = true;
            }
        }

        public GetResponseInformation(): Model.Synchronisation.ResponseInformation {
            if (Session.LastKnownAPIVersion >= 32) {
                return this.syncInfo;
            }

            return super.GetResponseInformation();
        }

        protected onAfterSave(): Deferred {
            if (Session.LastKnownAPIVersion < 32 ||
                !this.syncInfo || !this.syncInfo.RemainingDataItems) {
                // keine weiteren Downloads nötig bei API < 32
                // oder wenn keine Daten zum Download verbleiben
                return super.onAfterSave();
            }

            return this.downloadFromServer()
                .then($.proxy(this.onAfterDownload, this))
                .then($.proxy(this.saveToDatabase, this));
        }
    }

    class FilesDownloader extends ByChangeIDDownloader<Model.Files.RawFile> {
        protected customTimeout: number = Utils.Http.TIMEOUT_MS_MEDIUM;
        protected syncInfo: Model.Synchronisation.ChangeResponseInformation;

        constructor(saveData: boolean = false, autoSaveResponseTime: boolean = true, logger?: Model.ILogger) {
            super(Enums.EntityType.FILES, Enums.DatabaseStorage.Files, saveData, autoSaveResponseTime, logger);
        }

        protected cacheResponse(_data: Array<Model.Files.RawFile>): Deferred {
            DAL.Files.Store(_data);
            return super.cacheResponse(_data);
        }
    }

    class ElementsDownloader extends Downloader<Model.Elements.Element> {
        protected customTimeout: number = Utils.Http.TIMEOUT_MS_LONG;
        protected readonly MAX_ELEMENTS_TAKE: number = 1000;
        protected readonly RELOAD_FIX_TAKE: number = 10;
        protected take: number = this.MAX_ELEMENTS_TAKE;
        protected skip: number = 0;
        protected nextModifiedChange: Date;
        protected needNextStep: boolean = true;
        protected syncInfo: Model.Synchronisation.ElementsResponseInformation;

        constructor(saveData: boolean = false, autoSaveResponseTime: boolean = true, logger?: Model.ILogger) {
            super(Enums.EntityType.ELEMENTS, Enums.DatabaseStorage.Elements, saveData, autoSaveResponseTime, logger);

            // Aktionen vor dem Speichern durchführen
            this.setBeforeSave(this.updateSchedulingAndHistoricalElements);

            if (!saveData) {
                // wenn in Weberfassung, keine Aufbereitung der syncInfo nötig
                return;
            }

            // SyncInfo aufbereiten
            if (window.Session && Session.SynchronisationInformation &&
                Session.SynchronisationInformation.GetByType) {

                // bestehende SyncInfo übernehmen und erweitern
                this.syncInfo = <Model.Synchronisation.ElementsResponseInformation>Session.SynchronisationInformation.GetByType(Enums.EntityType.ELEMENTS);
                if (this.syncInfo) {
                    this.nextModifiedChange = this.syncInfo.LastModifiedDate;

                    if (this.syncInfo.FixRequest) {
                        // beginne mit letztem FixRequest
                        this.skip = this.syncInfo.FixRequest.Skip;
                        this.take = this.syncInfo.FixRequest.Take;
                    } else {
                        // regulären Download fortsetzen
                        this.take = this.MAX_ELEMENTS_TAKE;
                        this.skip = this.syncInfo.Skip || 0;
                    }
                }
            }

            if (!this.syncInfo) {
                this.syncInfo = new Model.Synchronisation.ElementsResponseInformation();
                this.syncInfo.Take = this.take;
                this.syncInfo.Skip = this.skip;
            }
        }

        private updateSchedulingAndHistoricalElements(items: Model.Elements.Element[]): Deferred {
            // Schedulings Beziehungen zu Elementen aktualisieren
            let updateDeferred = this.updateSchedulingElements(items);

            if (this.saveData && !options.IsInitialLogin && this.syncInfo.InitialSyncFinished) {
                // historische Elemente aktualisieren
                updateDeferred = updateDeferred.then(() => this.updateHistoricalElements(items));
            }

            return updateDeferred;
        }

        private updateSchedulingElements(items: Model.Elements.Element[]): Deferred {
            // Nur Prüfgruppen und Formulare nach Plänen prüfen.
            // Und gelöschte Elemente pauschal berücksichtigen (da hier der Type fehlt)
            const elementsToUpdate = (items || []).filter(e => e.Type === Enums.ElementType.Form || e.Type === Enums.ElementType.Parametergroup || e.Deleted);

            if (!elementsToUpdate || !elementsToUpdate.length) {
                return $.Deferred().resolve();
            }

            const elementsToRemove: Dictionary<boolean> = {};
            const elementsToSave: Model.Scheduling.Relation[] = [];

            for (let i = 0; i < elementsToUpdate.length; i++) {
                const element = elementsToUpdate[i];

                // bisherige Beziehungen zum Element komplett löschen
                elementsToRemove[element.OID] = true;

                if (!element.Scheduling || !element.Scheduling.length) {
                    continue;
                }

                // Neue Plan Beziehungen aufstellen
                for (let si = 0; si < element.Scheduling.length; si++) {
                    const schedulingElement = element.Scheduling[si];
                    const schedulingOID = schedulingElement.OID;
                    const tmpResult = DAL.Scheduling.GetRelations(schedulingOID, element, schedulingElement);

                    if (tmpResult && tmpResult.length) {
                        elementsToSave.push.apply(elementsToSave, tmpResult);
                    }
                }
            }

            // Plan<>Element Beziehungen aktualisieren
            const clearElementsOIDs = Object.keys(elementsToRemove);
            return window.Database.DeleteFromStorageByIndex(Enums.DatabaseStorage.SchedulingElements, 'IDX_ExecutingElementOID', clearElementsOIDs)
                .then(() => window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.SchedulingElements, elementsToSave))
                .then(() => {
                    DAL.Scheduling.ResetSchedulingByElement(clearElementsOIDs.map(function(elementOID: string) {
                        return {
                            ElementOID: elementOID,
                            LocationOID: null,
                            SchedulingOID: null
                        };
                    }));
                    DAL.Scheduling.FillSchedulingRelations(elementsToSave);
                });
        }

        private updateHistoricalElements(elements: Model.Elements.Element[]): Deferred {
            if (!elements || !elements.length) {
                return $.Deferred().resolve().promise();
            }

            // Alle Revisionen ermitteln
            const revisionOIDsByElementOID: Dictionary<string> = {};
            const eLen = elements.length;

            for (let eCnt = 0; eCnt < eLen; eCnt++) {
                const item = elements[eCnt];
                revisionOIDsByElementOID[item.OID] = item.RevisionOID;
            }

            const oids: string[] = Object.keys(revisionOIDsByElementOID);
            return window.Database.GetManyByKeys(Enums.DatabaseStorage.Elements, oids)
                .then((existingElements: Model.Elements.Element[]) => {
                    if (!(existingElements || []).length) {
                        return;
                    }

                    for (let eCnt = existingElements.length - 1; eCnt >= 0; eCnt--) {
                        const existingElement = existingElements[eCnt];

                        // prüfen ob neu synchronisierte lokal Element bereits in alter Revision existieren
                        if (existingElement.RevisionOID === revisionOIDsByElementOID[existingElement.OID]) {
                            existingElements.splice(eCnt, 1);
                        }
                    }

                    return existingElements;
                })
                .then((existingElements: Model.Elements.Element[]) => {
                    if ((existingElements || []).length) {
                        // Elemente zu denen es ein Update gibt, als historische Elemente speichern
                        return window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.HistoricalElements, existingElements);
                    }
                })
                .then(() => this.deleteOldAdHocScanCodes(elements));
        }

        /**
         * Löscht ScanCodes von gelöschten AdHoc werten
         * @param downloadManager - Der DownloadManger welcher die Synchronisationsdaten beinhaltet
         * @private
         */
        private deleteOldAdHocScanCodes(elements: Model.Elements.Element[]): Deferred {
            const deletedElements = [];

            for (const element of elements) {
                if (element.Deleted) {
                    deletedElements.push(element.OID);
                }
            }

            return DAL.ScancodeInfos.DeleteFromDatabaseByElementOID(deletedElements);
        }

        protected cacheResponse(_data: Array<Model.Elements.Element>): Deferred {
            DAL.Elements.Store(_data);

            return super.cacheResponse(_data);
        }

        protected onAfterDownload(data: Model.Elements.Element[], state: string, response: HttpResponse): Deferred {
            // log download data
            this.logger.LogData('Download', `Download ${this.path} finished`, data);

            // prüfen und syncInfo aktualisieren, wenn weitere Downloads nötig sind
            this.updateSyncInfo(data, response);

            // Daten zur "Speichern" Methode weitergeben
            return super.onAfterDownload(data, state, response);
        }

        protected updateSyncInfo(data: Model.Elements.Element[], response: HttpResponse): void {
            // Abwärtskompatibilität für API < 30 & Download ganzheitlich für Weberfassung
            if (!this.saveData || Session.LastKnownAPIVersion < 30) {
                // Alle Daten sind synchronisiert
                this.needNextStep = false;

                // keine weiteren Aktionen bei API < 30 nötig
                return;
            }

            // prüfen ob weitere Downloads oder Fix-Downloads nötig sind
            const lastElementsDownloadCount = (data || []).length;

            let fixRequestRemoved = false;
            if (lastElementsDownloadCount) {
                if (this.syncInfo.FixRequest) {
                    // Prüfen, welche weiteren Elemente fehlen zum FixRequest
                    const fixRequest = this.syncInfo.FixRequest;
                    // wenn fixRequest.Skip == 0 → FixRequest beenden → keine weiteren Elemente zu erwarten
                    if (fixRequest.Skip > 0 && this.areElementsMissingFromPrecedingRequest(fixRequest.LastSyncedElementOIDs, response)) {
                        // versuche [RELOAD_FIX_TAKE] weitere Elemente "davor" zu laden
                        fixRequest.Skip = Math.max(fixRequest.Skip - this.RELOAD_FIX_TAKE, 0);
                        // neu geladene Elemente der Liste hinzufügen
                        for (const issue of data) {
                            fixRequest.LastSyncedElementOIDs[issue.OID.toLowerCase()] = true;
                        }
                    } else {
                        // Fix request erfolgreich, wieder entfernen
                        this.syncInfo.FixRequest = null;
                        fixRequestRemoved = true;
                    }
                } else {
                    // Prüfen welche Elemente fehlen zum letzten Request
                    if (this.skip > 0 && this.areElementsMissingFromPrecedingRequest(this.syncInfo.LastSyncedElementOIDs, response)) {
                        // versuche [RELOAD_FIX_TAKE] Elemente "davor" zu laden
                        this.syncInfo.FixRequest = {
                            Take: Math.max(this.RELOAD_FIX_TAKE, 1),
                            Skip: Math.max(this.skip - this.RELOAD_FIX_TAKE, 0),
                            LastSyncedElementOIDs: this.syncInfo.LastSyncedElementOIDs
                        };
                    }

                    // aktuelle Elemente zur (neuen) Liste zufügen
                    this.syncInfo.LastSyncedElementOIDs = {};
                    for (const issue of data) {
                        this.syncInfo.LastSyncedElementOIDs[issue.OID.toLowerCase()] = true;
                    }

                    // nächsten Skip ermitteln
                    const newSkip = (this.syncInfo.Skip || 0) + lastElementsDownloadCount;

                    if (newSkip == this.syncInfo.Skip) {
                        // Endlosschleife vorbeugen, wenn Skip sich nicht ändert
                        this.syncInfo.Skip++;
                    } else {
                        this.syncInfo.Skip = newSkip || 0;
                    }
                }
            } else if (this.syncInfo.FixRequest) {
                // Fix request lieferte kein ergebnis, wieder entfernen
                this.syncInfo.FixRequest = null;
                fixRequestRemoved = true;
            } else {
                // Daten vollständig geladen, LastSyncedIssueOIDs zurücksetzen
                this.syncInfo.LastSyncedElementOIDs = null;
            }

            // determine next step in sync
            this.needNextStep = lastElementsDownloadCount > 0 || fixRequestRemoved;
        }

        private areElementsMissingFromPrecedingRequest(lastSyncedIssueOIDs: Dictionary<boolean>, response: HttpResponse): boolean {
            if (!lastSyncedIssueOIDs) {
                return false;
            }

            // prüfen ob vorangehender Vorgang (zu diesem Request) auch im letzten Request existierte
            const previousElementOID = Utils.Http.GetResponseHeader(response, 'PreviousItemOID');
            return previousElementOID ? !lastSyncedIssueOIDs[previousElementOID.toLowerCase()] : false;
        }

        protected startNextDownload(): Deferred | null {
            if (!this.saveData && Session.LastKnownAPIVersion < 30) {
                // keine weiteren Downloads nötig bei API < 30
                return null;
            }

            if (this.syncInfo.FixRequest) {
                // Fix-Download durchführen
                this.skip = this.syncInfo.FixRequest.Skip;
                this.take = this.syncInfo.FixRequest.Take;
            } else if (this.needNextStep) {
                // regulären Download durchführen
                this.skip = this.syncInfo.Skip;
                this.take = this.MAX_ELEMENTS_TAKE;
            }

            return this.downloadFromServer()
                .fail(function(_response, _state, _error) {
                    throw new Model.Errors.HttpError(_error, _response);
                });
        }

        protected onAfterSave(): Deferred {
            if (!this.needNextStep) {
                return super.onAfterSave();
            }

            const downloadDeferred = this.startNextDownload();
            if (!downloadDeferred) {
                return super.onAfterSave();
            }

            return downloadDeferred
                .then($.proxy(this.onAfterDownload, this))
                .then($.proxy(this.saveToDatabase, this));
        }

        public GetElements(): Model.Elements.Element[] {
            return this.resultData || [];
        }

        public GetResponseInformation(): Model.Synchronisation.ResponseInformation {
            // das Last-Modified vom ersten Request (skip=0) als nächstes ModifiedChange vormerken
            if (this.response && !this.skip) {
                const newLastModified = new Date(Utils.Http.GetResponseHeader(this.response, 'Last-Modified'));
                if (!!Number(newLastModified)) {
                    if (!this.nextModifiedChange || newLastModified > this.nextModifiedChange) {
                        this.nextModifiedChange = newLastModified;

                        this.syncInfo.NextLastModifiedDate = newLastModified;
                    }
                }
            } else if (!!Number(this.syncInfo.NextLastModifiedDate)) {
                this.nextModifiedChange = this.syncInfo.NextLastModifiedDate;
            }

            if (this.response) {
                // responseDate vom Server merken
                const responseDate = Utils.Http.GetResponseHeader(this.response, 'Date');
                this.syncInfo.ResponseDate = responseDate ? new Date(responseDate) : null;
            }

            if (this.response == null || this.needNextStep) {
                // nötige Änderungen an syncInfo wurden in updateSyncInfo bereits gemacht
                return this.syncInfo;
            }

            const responseInfo = this.syncInfo;

            // check if sync is repeating
            if (responseInfo.LastModifiedDate && this.nextModifiedChange) {
                responseInfo.IsRepeating = responseInfo.LastModifiedDate.getTime() == this.nextModifiedChange.getTime();
            }

            // nächstes Sync mit neuem LastModifiedDate anfangen
            responseInfo.LastModifiedDate = this.nextModifiedChange || null;
            delete responseInfo.Skip;
            delete responseInfo.Take;

            if (this.saveData) {
                // kompletter (erster) Download abgeschlossen,
                // SyncInfo für nächsten Synchronisationsversuch vorbereiten
                responseInfo.InitialSyncFinished = true;
                delete responseInfo.LastSyncedElementOIDs;
            }

            return responseInfo;
        }

        protected getDownloadUri(): string {
            const extraParams = {};

            // für Weberfassung kein skip/take verwenden, erst ab API v30 verfügbar
            if (this.saveData && Session.LastKnownAPIVersion >= 30) {
                if (this.take > 0) {
                    // Anzahl der Elemente, die zurückgegeben werden sollen
                    extraParams['take'] = this.take;
                }

                if (this.skip > 0) {
                    // Anzahl der Elemente, die übersprungen werden sollen
                    extraParams['skip'] = this.skip;
                }

                if (!this.syncInfo.InitialSyncFinished) {
                    // bei Erstsynchronisation nur aktive Elemente zurückgeben, um Datenmenge zu reduzieren
                    extraParams['activeonly'] = 1;
                }

                if (!this.syncInfo.LastModifiedDate) {
                    // letztes Änderungsdatum für Erstsynchronisation auf 01.01.2010 festlegen
                    extraParams['modifiedsince'] = 'Fri, 01 Jan 2010';
                }
            } else if (!this.saveData) {
                // Daten für Weberfassung laden, benötigt API >= 30
                // nur [Root], [Location] & [Form] Elemente beziehen
                extraParams['types'] = `${Enums.ElementType.Root},${Enums.ElementType.Location},${Enums.ElementType.Form}`;
            }

            return this.buildUri(this.path, extraParams);
        }
    }

    class MenuItemsDownloader extends Downloader<Model.Menu.IMenuItemConfig> {
        protected customTimeout: number = Utils.Http.TIMEOUT_MS_MEDIUM;

        constructor(saveData: boolean = false, autoSaveResponseTime: boolean = true, logger?: Model.ILogger) {
            super(Enums.EntityType.CUSTOMMENUITEMS, Enums.DatabaseStorage.CustomMenuItems, saveData, autoSaveResponseTime, logger);
        }

        public GetMenuItems(): Model.Menu.IMenuItemConfig[] {
            return this.resultData;
        }
    }

    class RecorditemsDownloader extends ByChangeIDDownloader<Model.Recorditem> {
        protected syncEntitiesChecker: SyncEntitiesChecker;
        protected customTimeout: number = Utils.Http.TIMEOUT_MS_MEDIUM;
        protected MAX_TAKE: number = 50000;

        constructor(saveData: boolean = false, syncEntitiesChecker: SyncEntitiesChecker, autoSaveResponseTime: boolean = true, logger?: Model.ILogger) {
            super(Enums.EntityType.RECORDITEMS, Enums.DatabaseStorage.Recorditems, saveData, autoSaveResponseTime, logger);
            this.syncEntitiesChecker = syncEntitiesChecker;
        }

        protected onAfterDownload(data: Model.Recorditem[], state: string, response: HttpResponse): Deferred {
            // ChangeID aktualisieren, bevor Daten aussortiert werden
            this.updateSyncInfo(data, response);

            // Recorditems aussortieren, die lokal geändert wurden
            if (data && data.length && this.syncEntitiesChecker.IsRecorditemsCacheFilled()) {
                for (let i = data.length - 1; i >= 0; i--) {
                    const recorditem: Model.Recorditem = data[i];
                    // Prüfen ob Erfassung zu einem Vorgang (Formular/Plan) gehört und lokal noch Änderungen besitzt
                    if (!recorditem.IsDeleted && recorditem.ResubmissionitemOID &&
                        this.syncEntitiesChecker.HasRecorditemOnElementOID(recorditem.ElementOID, recorditem.IssueID)) {
                        // Nur bei Erfassungen die einem Vorgang angehören, da eine Folgesynchronisation
                        // zur Aktualisierung der Werte auf dem Server führt!

                        // Erfassung für Prüfpunkt vom Server ignorieren
                        data.splice(i, 1);
                    }
                }
            }

            return super.onAfterDownload(data, state, response, true);
        }
    }

    /*
    * Download previous recorditems from formula properties with 'LastValue'
    */
    class PreviousRecorditemsDownloader extends Downloader<Model.Recorditem> implements DownloaderListener<Model.Elements.Element> {
        private elementsDownloader: ElementsDownloader;
        protected elementOIDsCache: Dictionary<boolean>;
        protected customTimeout: number = Utils.Http.TIMEOUT_MS_MEDIUM;

        constructor(elementsDownloader: ElementsDownloader, saveData: boolean = false, logger?: Model.ILogger) {
            super(Enums.EntityType.RECORDITEMS, Enums.DatabaseStorage.Recorditems, saveData, false, logger);
            this.elementsDownloader = elementsDownloader;
            this.elementsDownloader.setListener(this);
        }

        public GetRecorditems(): Model.Recorditem[] {
            return this.resultData;
        }

        protected getDownloadRecorditemsUri(elementOID: string) {
            return `recorditems?elementoid=${elementOID}&count=2`;
        }

        protected cacheResponseItem(data, state, response): Deferred {
            if ($.isArray(data)) {
                this.resultData = this.resultData.concat(data);
            } else if ($.isPlainObject(data)) {
                this.resultData.push(data);
            }

            return $.Deferred().resolve(data, state, response);
        }

        protected downloadPreviousRecorditemsFromServer(elementOID: string): Deferred {
            return Utils.Http.Get(this.getDownloadRecorditemsUri(elementOID), null, undefined, undefined, this.customTimeout)
                .then($.proxy(this.cacheResponseItem, this))
                .fail(function(_response, _state, _error) {
                    throw new Model.Errors.HttpError(_error, _response);
                });
        }

        protected downloadFromServer(): Deferred {
            let downloadQueue = $.Deferred().resolve();
            this.resultData = [];

            const elementOIDs = this.getElementOIDsFromLastValueParameters();

            for (let i = 0; i < (elementOIDs || []).length; i++) {
                const elementOID = elementOIDs[i];
                downloadQueue = downloadQueue
                    .then($.proxy(this.downloadPreviousRecorditemsFromServer, this, elementOID));
            }

            return downloadQueue.then(() => {
                return $.Deferred().resolve(this.resultData, null, null);
            });
        }

        onCacheData(elementsData: Model.Elements.Element[]) {
            // Daten vom ElementsDownloader abfangen und vormerken
            this.elementOIDsCache = this.elementOIDsCache || {};

            for (let i = 0; i < (elementsData || []).length; i++) {
                const element = elementsData[i];

                if (!element || !element.Formula) {
                    continue;
                }

                const tokens = window.Formula.tokenize(element.Formula);
                let hasLastValue = false;

                for (let to = 0; to < tokens.length; to++) {
                    const token = tokens[to];

                    if (token._name !== 'LastValue' && !hasLastValue) {
                        continue;
                    }

                    const tokenId = tokens[to]._id;

                    if (tokenId && !this.elementOIDsCache[tokenId]) {
                        this.elementOIDsCache[tokenId] = true;
                        hasLastValue = false;
                    } else {
                        hasLastValue = true;
                    }
                }
            }
        }

        protected getElementOIDsFromLastValueParameters(): Array<string> {
            return this.elementOIDsCache ? Object.keys(this.elementOIDsCache) : [];
        }
    }

    class HistoricalElementCache {
        private elementOIDs: Dictionary<boolean>;
        private newElementOIDs: Array<Dictionary<string>>;

        constructor() {
            this.elementOIDs = {};
            this.newElementOIDs = [];
        }

        public collectElementOIDs(issues: Array<Model.Issues.RawIssue>) {
            if (!issues && !issues.length) {
                return;
            }

            for (let iCnt = 0, iLen = issues.length; iCnt < iLen; iCnt++) {
                const issue = issues[iCnt];

                if (!issue || !issue.Resubmissionitems) {
                    continue;
                }

                for (let riCnt = 0, riLen = issue.Resubmissionitems.length; riCnt < riLen; riCnt++) {
                    const resubitem: Model.Issues.ResubmissionItem = issue.Resubmissionitems[riCnt];

                    if (resubitem.ElementRevisionOID &&
                        !this.elementOIDs[resubitem.ElementRevisionOID]) {
                        if (DAL.Elements.GetByRevisionOID(resubitem.ElementRevisionOID)) {
                            this.elementOIDs[resubitem.ElementRevisionOID] = true;
                            continue;
                        }

                        this.elementOIDs[resubitem.ElementRevisionOID] = true;
                        this.newElementOIDs.push({
                            OID: resubitem.ElementRevisionOID
                        });
                    }
                }
            }
        }

        public save(): Deferred {
            if (this.newElementOIDs && this.newElementOIDs.length) {
                return window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.RequiredElementOIDs, this.newElementOIDs)
                    .then(() => {
                        this.newElementOIDs = [];
                    });
            }
            return $.Deferred().resolve();
        }
    }

    class IssuesDownloader extends Downloader<Model.Issues.RawIssue> {
        protected readonly MAX_ISSUES_TAKE: number = 500;
        protected readonly RELOAD_FIX_TAKE: number = 5;
        protected take: number = 500;
        protected skip: number = 0;
        protected needNextStep: boolean = true;
        protected syncInfo: Model.Synchronisation.IssuesResponseInformation;
        protected nextModifiedChange: Date;
        protected elementOIDsCache: HistoricalElementCache;
        protected lastItemsDownloaded: number;
        protected issueComparator: IssueComparator;
        protected syncEntitiesChecker: SyncEntitiesChecker;
        protected customTimeout: number = Utils.Http.TIMEOUT_MS_LONG;
        protected lastModifiedUntil?: string = null;
        protected activesToSkip?: number = null;

        constructor(saveData: boolean, syncEntitiesChecker: SyncEntitiesChecker, logger?: Model.ILogger) {
            super(Enums.EntityType.ISSUES, Enums.DatabaseStorage.Issues, saveData, true, logger);
            this.resultData = [];
            this.issueComparator = new IssueComparator();
            this.elementOIDsCache = new HistoricalElementCache();
            this.lastItemsDownloaded = 0;
            this.syncEntitiesChecker = syncEntitiesChecker;

            if (window.Session && Session.SynchronisationInformation &&
                Session.SynchronisationInformation.GetByType) {

                // update response information
                this.syncInfo = <Model.Synchronisation.IssuesResponseInformation>Session.SynchronisationInformation.GetByType(Enums.EntityType.ISSUES);
                if (this.syncInfo) {
                    this.nextModifiedChange = this.syncInfo.LastModifiedDate;
                    this.lastModifiedUntil = this.syncInfo.LastModifiedDateUntil || null;

                    if (this.syncInfo.FixRequest) {
                        // beginne mit letztem FixRequest
                        this.skip = this.syncInfo.FixRequest.Skip;
                        this.take = this.syncInfo.FixRequest.Take;
                        this.activesToSkip = this.syncInfo.FixRequest.ActivesToSkip;
                    } else {
                        // regulären Download fortsetzen
                        this.take = this.MAX_ISSUES_TAKE;
                        this.skip = this.syncInfo.Skip;
                        this.activesToSkip = this.syncInfo.ActivesToSkip;
                    }
                }
            }

            if (!this.syncInfo) {
                this.syncInfo = new Model.Synchronisation.IssuesResponseInformation();
                this.syncInfo.Take = this.take;
                this.syncInfo.Skip = this.skip;
                this.syncInfo.ActivesToSkip = this.activesToSkip;
            }
        }

        protected onAfterDownload(data: Model.Issues.RawIssue[], state: string, response: HttpResponse): Deferred {
            // log download data
            this.logger.LogData('Download', `Download ${this.path} finished`, data);

            const takeActiveItems = Utils.Http.GetResponseHeader(response, 'TakeActiveItems');
            if (takeActiveItems) {
                this.lastItemsDownloaded = Number(takeActiveItems);
            }

            this.lastItemsDownloaded = this.lastItemsDownloaded || (data || []).length;

            // lastModifiedUntil darf nach dem ersten Setzen nicht geändert werden,
            // um den Anfangszustand der Sync nicht zu verändern (führt sonst zum Überspringen von gelöschter Vorgänge).
            if (!this.lastModifiedUntil) {
                this.lastModifiedUntil = Utils.Http.GetResponseHeader(response, 'Last-Modified-Until');
            }

            this.updateSyncInfo(data, response);

            // Daten zur "Speichern" Methode weitergeben
            return super.onAfterDownload(data, state, response);
        }

        private updateSyncInfo(data: Model.Issues.RawIssue[], response: HttpResponse) {
            let fixRequestFinished = false;

            const activeSkip = Utils.Http.GetResponseHeader(response, 'ActivesToSkip');
            if (activeSkip && !isNaN(+activeSkip)) {
                this.activesToSkip = Number(activeSkip);
            } else {
                this.activesToSkip = null;
            }

            this.syncInfo.LastModifiedDateUntil = this.lastModifiedUntil;

            if (data && data.length) {
                if (this.syncInfo.FixRequest) {
                    // Prüfen, welche weiteren Vorgänge fehlen zum FixRequest
                    const fixRequest = this.syncInfo.FixRequest;
                    // wenn fixRequest.Skip == 0 → FixRequest beenden → keine weiteren Vorgänge zu erwarten
                    if (fixRequest.Skip > 0 && this.lastItemsDownloaded &&
                        this.areIssuesMissingFromPrecedingRequest(fixRequest.LastSyncedIssueOIDs, data, response)) {
                        // versuche [RELOAD_FIX_TAKE] weitere Vorgänge "davor" zu laden
                        fixRequest.Skip = Math.max(fixRequest.Skip - this.RELOAD_FIX_TAKE, 0);
                        fixRequest.ActivesToSkip = Math.max(fixRequest.ActivesToSkip - this.RELOAD_FIX_TAKE, 0);
                        // neu geladene Vorgänge der Liste hinzufügen
                        for (const issue of data) {
                            fixRequest.LastSyncedIssueOIDs[issue.OID.toLowerCase()] = true;
                        }
                    } else {
                        // Fix request erfolgreich, wieder entfernen
                        this.syncInfo.Skip = this.syncInfo.FixRequest.Skip;
                        this.syncInfo.ActivesToSkip = this.activesToSkip;
                        this.syncInfo.FixRequest = null;
                        fixRequestFinished = true;
                    }
                }

                if (this.lastItemsDownloaded) {
                    // Prüfen welche Vorgänge fehlen zum letzten Request
                    if (!fixRequestFinished && this.skip > 0 && this.areIssuesMissingFromPrecedingRequest(this.syncInfo.LastSyncedIssueOIDs, data, response)) {
                        // versuche [RELOAD_FIX_TAKE] Vorgänge "davor" zu laden
                        this.syncInfo.FixRequest = {
                            Take: this.RELOAD_FIX_TAKE,
                            Skip: Math.max(this.skip - this.RELOAD_FIX_TAKE, 0),
                            ActivesToSkip: Math.max(this.activesToSkip - this.RELOAD_FIX_TAKE, 0),
                            LastSyncedIssueOIDs: this.syncInfo.LastSyncedIssueOIDs
                        };
                    }

                    // aktuelle Vorgänge zur (neuen) Liste zufügen
                    this.syncInfo.LastSyncedIssueOIDs = {};

                    for (const issue of data) {
                        this.syncInfo.LastSyncedIssueOIDs[issue.OID.toLowerCase()] = true;
                    }
                } else {
                    this.syncInfo.LastSyncedIssueOIDs = null;
                }

                // Issues vom Server aussortieren, die lokal unsynchronisierte Änderungen haben
                if (this.syncEntitiesChecker && this.syncEntitiesChecker.IsIssuesCacheFilled()) {
                    for (let i = data.length - 1; i >= 0; i--) {
                        const issue: Model.Issues.RawIssue = data[i];

                        if (issue.IsDeleted) {
                            // auf Server gelöschte Vorgänge immer an App weiterreichen
                            continue;
                        }

                        if (this.syncEntitiesChecker.HasIssueID(issue.ID) ||
                            this.syncEntitiesChecker.HasIssueOID(issue.OID)) {
                            // entferne lokal bearbeitetes Issue aus Ergebnis, um Konfliktänderungern vorzubeugen
                            data.splice(i, 1);
                        }
                    }
                }

                // abhängige Vorgänge als flache Liste zurückgeben
                data = resolveDescendants(data);
            } else if (this.syncInfo.FixRequest) {
                // Fix request liefert kein ergebnis, wieder entfernen
                this.syncInfo.Skip = this.syncInfo.FixRequest.Skip;
                this.syncInfo.ActivesToSkip = this.syncInfo.FixRequest.ActivesToSkip;
                this.syncInfo.FixRequest = null;
                fixRequestFinished = true;
            } else {
                this.syncInfo.LastSyncedIssueOIDs = null;
            }

            // save new last-modified date from current response
            const newLastModified = new Date(Utils.Http.GetResponseHeader(response, 'Last-Modified'));
            if (!!Number(newLastModified)) {
                if (!this.nextModifiedChange || newLastModified > this.nextModifiedChange) {
                    this.nextModifiedChange = newLastModified;
                }
            }

            // update syncInfo
            if (Session.LastKnownAPIVersion >= 11) {
                // nächsten skip ermitteln
                let newSkip = this.syncInfo.Skip + this.lastItemsDownloaded;
                if (Session.LastKnownAPIVersion < 25) {
                    // Lösung für API ohne LastSyncedOID
                    // -1 um beim nächsten Request einen Vorgang aus diesem Request zu bekommen, für einen Vergleich
                    newSkip--;
                }

                if (newSkip == this.syncInfo.Skip) {
                    // Endlosschleife vorbeugen, wenn Skip sich nicht ändert
                    this.syncInfo.Skip++;
                } else {
                    this.syncInfo.Skip = newSkip;
                }

                this.syncInfo.ActivesToSkip = this.activesToSkip;

                if (Session.LastKnownAPIVersion < 29) {
                    const activeItems = this.getActiveIssuesCount(data);
                    this.needNextStep = activeItems > 0 || fixRequestFinished;
                } else {
                    this.needNextStep = this.lastItemsDownloaded > 0 || fixRequestFinished;
                }

                // save response time
                if (!this.needNextStep) {
                    this.response = response;
                }
            } else {
                // Abwärtskompatibilität für API < 11
                // Alle Daten sind synchronisiert
                this.syncInfo.InitialSyncFinished = true;
                this.syncInfo.Skip = 0;
                this.syncInfo.ActivesToSkip = null;
                this.needNextStep = false;
                this.lastModifiedUntil = null;
                // save response time
                this.response = response;
                // update modifiedSince parameter
                this.syncInfo = <any>this.GetResponseInformation();
            }
        }

        private areIssuesMissingFromPrecedingRequest(lastSyncedIssueOIDs: Dictionary<boolean>, data: Model.Issues.RawIssue[], response: HttpResponse): boolean {
            if (!lastSyncedIssueOIDs || !data || !data.length) {
                return false;
            }

            // korrekte Reihenfolge der Vorgänge und notwendige Header erst ab API 25
            if (Session.LastKnownAPIVersion >= 25) {
                // prüfen ob vorangehender Vorgang (zu diesem Request) auch im letzten Request existierte
                let previousIssueOID = Utils.Http.GetResponseHeader(response, 'PreviousIssueOID');
                if (!previousIssueOID) {
                    // alternative Header Eigenschaft für previousIssueOID
                    previousIssueOID = Utils.Http.GetResponseHeader(response, 'PreviousItemOID');
                }
                return previousIssueOID ? !lastSyncedIssueOIDs[previousIssueOID.toLowerCase()] : false;
            }

            // Alternative (heuristische) Prüfung für alte API Versionen
            return !data.some((issue) => !!lastSyncedIssueOIDs[issue.OID.toLowerCase()]);
        }

        private getActiveIssuesCount(data: Model.Issues.RawIssue[]) {
            if (!data || !data.length) {
                return 0;
            }

            let numInactiveItems = 0;
            const countArchived = !this.syncInfo.InitialSyncFinished;
            for (const issue of data) {
                if (!issue) {
                    continue;
                }

                if (issue.IsDeleted ||
                    (issue.IsArchived && countArchived)) {
                    numInactiveItems++;
                }
            }

            // returns count of issues without deleted ones
            return data.length - numInactiveItems;
        }

        protected saveToDatabase(data: Model.Issues.RawIssue[]): Deferred {
            if (!this.saveData) {
                return $.Deferred().resolve();
            }

            if (!window.Database) {
                // TODO replace logging with unified logger
                return $.Deferred().resolve();
            }

            if ((!data || !data.length) && !this.lastItemsDownloaded) {
                return this.nextMove(null);
            }

            // nächsten Download beginnen, während aktuelle Vorgänge gespeichert werden
            const downloadDeferred: Deferred = this.startNextDownload();

            // get all element references
            this.elementOIDsCache.collectElementOIDs(data);

            // save issues & historical element references
            return this.elementOIDsCache.save()
                .then(() => this.compareRemoteWithLocalIssues(data))
                .then(() => DAL.Issues.SaveToDatabase(data, true))
                .then(() => this.removeOldIssueRevisionsFromDatabase(data))
                .then(() => {
                    // log saved data
                    this.logger.LogData('Save Download', `Saved downloaded ${this.path} data`, data);
                })
                .then(() => this.nextMove(downloadDeferred));
        }

        protected startNextDownload(): Deferred {
            if (Session.LastKnownAPIVersion < 11) {
                // keine weiteren Downloads nötig bei API < 11
                return null;
            }

            if (this.syncInfo.FixRequest) {
                // Fix-Download durchführen
                this.skip = this.syncInfo.FixRequest.Skip;
                this.take = this.syncInfo.FixRequest.Take;
                this.activesToSkip = this.syncInfo.FixRequest.ActivesToSkip;

                return this.downloadFromServer()
                    .fail(function(_response, _state, _error) {
                        throw new Model.Errors.HttpError(_error, _response);
                    });
            } else if (this.needNextStep) {
                // regulären Download durchführen
                this.skip = this.syncInfo.Skip;
                this.take = this.MAX_ISSUES_TAKE;
                this.activesToSkip = this.syncInfo.ActivesToSkip;

                return this.downloadFromServer()
                    .fail(function(_response, _state, _error) {
                        throw new Model.Errors.HttpError(_error, _response);
                    });
            }
        }

        protected nextMove(downloadDeferred: Deferred): Deferred {
            if (Session.LastKnownAPIVersion >= 11) {
                // determine next step in sync
                this.needNextStep = this.needNextStep && !!downloadDeferred;
                if (!this.needNextStep) {
                    // Chunk Download abgeschlossen, SyncInfo für nächste Synchronisation vorbereiten
                    this.syncInfo = <any>this.GetResponseInformation();
                    this.syncInfo.InitialSyncFinished = true;
                    this.syncInfo.LastSyncedIssueOIDs = null;
                    if (Session.LastKnownAPIVersion < 29) {
                        if (this.nextModifiedChange && this.syncInfo.LastModifiedDate &&
                            this.syncInfo.LastModifiedDate < this.nextModifiedChange) {
                            this.syncInfo.LastModifiedDate = this.nextModifiedChange;
                        }
                    } else {
                        this.syncInfo.LastModifiedDate = this.nextModifiedChange;
                    }
                }
            }

            let safeSyncInfoDeferred: Deferred;
            // save responseInfo
            if (this.autoSaveResponseTime) {
                if (Session && Session.SynchronisationInformation) {
                    Session.SynchronisationInformation.AddInfo(this.syncInfo);
                }

                safeSyncInfoDeferred = window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.SynchronisationInformation, this.syncInfo.GetDBItem())
            } else {
                safeSyncInfoDeferred = $.Deferred().resolve();
            }

            if (!this.needNextStep) {
                return safeSyncInfoDeferred;
            }

            if (!downloadDeferred) {
                throw new Error('downloadDeferred does not exist!');
            }

            return safeSyncInfoDeferred
                .then(() => downloadDeferred)
                .then($.proxy(this.onAfterDownload, this))
                .then($.proxy(this.saveToDatabase, this));
        }

        protected compareRemoteWithLocalIssues(dataIssues: Model.Issues.RawIssue[]): Deferred {
            if (!(dataIssues || []).length) {
                return $.Deferred().resolve();
            }

            //
            this.issueComparator.Reset();

            // update correct types only
            for (let i = 0; i < dataIssues.length; i++) {
                const curIssue = dataIssues[i];
                if (curIssue.Type == Enums.IssueType.Form ||
                    curIssue.Type == Enums.IssueType.Scheduling ||
                    curIssue.Type == Enums.IssueType.Inspection) {
                    this.issueComparator.Feed(<any>curIssue);
                }
            }

            // Wait for completion
            return this.issueComparator.Finish();
        }

        protected removeOldIssueRevisionsFromDatabase(dataIssues: Model.Issues.RawIssue[]): Deferred {
            const deferred = $.Deferred();

            if (options.IsInitialLogin) {
                return deferred.resolve().promise();
            }

            if (!(dataIssues || []).length) {
                _logger.LogMessage('Download', 'No issues to update');
                return deferred.resolve().promise();
            }

            const issueIdentifiers = {};
            const syncedRevisionIdentifiers = {};
            const completelyRemovedIssues = {};

            for (const issue of dataIssues) {
                issueIdentifiers[issue.ID] = true;
                syncedRevisionIdentifiers[issue.OID] = true;

                if ((issue.IsArchived && !issue.ParentID) || issue.IsDeleted) {
                    completelyRemovedIssues[issue.ID] = true;
                }
            }

            _logger.LogData('Download', 'Updating Issues', issueIdentifiers);
            window.Database.GetManyByKeys(Enums.DatabaseStorage.Issues, Object.keys(issueIdentifiers), 'IDX_ID')
                .then((selectedIssues: Model.Issues.Issue[]) => {
                    let iCnt = -1;
                    const checkSyncEntitiesFor = {};
                    const ignoreIssuesFromOID = {};
                    const ignoreIssuesFromID = {};

                    // Vorgänge nach ID und Revision vor sortieren
                    selectedIssues.sort((a: Model.Issues.Issue, b: Model.Issues.Issue) => {
                        if (a.ID === b.ID) {
                            return a.Revision - b.Revision;
                        }

                        return a.ID - b.ID;
                    });

                    _logger.LogData('Download', 'Issues loaded', selectedIssues);

                    const next = () => {
                        const issue = selectedIssues[++iCnt];

                        if (!issue) {
                            deferred.resolve();
                            return;
                        }

                        const issueIsRemovedCompletely = completelyRemovedIssues[issue.ID];
                        // keine Daten löschen, wenn eine Revision noch synchronisiert werden muss
                        if ((issue.HasNotBeenSynced && ignoreIssuesFromOID[issue.OID]) ||
                            ignoreIssuesFromID[issue.ID]) {
                            // alle folgenden Revisionen sollen ignoriert werden, damit der archived/deleted Status nicht verloren geht
                            ignoreIssuesFromID[issue.ID] = true;

                            setTimeout(() => next(), 5);
                        } else if (issueIsRemovedCompletely || (!syncedRevisionIdentifiers[issue.OID] && issueIdentifiers[issue.ID])) {
                            this.removeIssueRevisionFromDatabase(issue)
                                .then(function() {
                                    // Erfassungen aus Vorgängen vom Typ Plan nicht löschen
                                    // (werden weiterhin für Prüfpunkte am Raum benötigt)
                                    if (issueIsRemovedCompletely && issue.Type !== Enums.IssueType.Scheduling) {
                                        return window.Database.DeleteFromStorageByIndex(
                                            Enums.DatabaseStorage.Recorditems,
                                            'IDX_IssueID',
                                            [issue.ID]);
                                    }
                                })
                                .then(function() {
                                    // Anhängende Dateien entfernen
                                    if (issueIsRemovedCompletely &&
                                        issue.Files && issue.Files.length) {
                                        const filenames = issue.Files.map((x: Model.Issues.File) => x.Filename);
                                        return Utils.DeleteFiles(filenames);
                                    }
                                })
                                .then(function() {
                                    // ScanCodes entfernen
                                    if (issueIsRemovedCompletely) {
                                        return DAL.ScancodeInfos.DeleteFromDatabaseByIssueID(issue.ID);
                                    }
                                })
                                .always(next);
                        } else if (!!issue.PrecedingOID) {
                            window.Database.GetSingleByKey(Enums.DatabaseStorage.Issues, issue.PrecedingOID)
                                .then((precedingIssue: Model.Issues.Issue) => {
                                    if (precedingIssue) {
                                        return this.removeIssueRevisionFromDatabase(precedingIssue);
                                    }
                                })
                                .always(next);
                        } else {
                            // alle folgenden Revisionen sollen ignoriert werden, damit der archived/deleted Status nicht verloren geht
                            ignoreIssuesFromID[issue.ID] = true;

                            setTimeout(() => next(), 5);
                        }
                    };

                    // Prüfen welche Vorgänge SyncEntities Test benötigen
                    for (const issue of selectedIssues) {
                        if (issue.HasNotBeenSynced) {
                            checkSyncEntitiesFor[issue.OID] = true;
                        }
                    }

                    const issuesToTest = Object.keys(checkSyncEntitiesFor);
                    if (issuesToTest.length) {
                        // betroffene Sync Entities auf Vorhandensein prüfen
                        window.Database.GetManyByKeys(Enums.DatabaseStorage.SyncEntities, issuesToTest)
                            .then((entities: Model.Synchronisation.IEntityDescription[]) => {
                                for (const item of entities) {
                                    ignoreIssuesFromOID[item.OID] = true;
                                }

                                next();
                            });
                    } else {
                        next();
                    }
                });

            return deferred.promise();
        }

        protected removeIssueRevisionFromDatabase(issue: Model.Issues.Issue): Deferred {
            if (!issue) {
                return $.Deferred().resolve().promise();
            }

            _logger.LogData('Download', 'Deleting issue', issue);
            return window.Database.DeleteFromStorage(Enums.DatabaseStorage.Issues, issue.OID)
                .then(() => window.Database.DeleteFromStorage(Enums.DatabaseStorage.ReducedIssues, issue.OID))
                .then(() => DAL.ScancodeInfos.DeleteFromDatabaseByIssueOID(issue.OID))
                .then(() => DAL.TreeCache.Global.removeIssue(issue))
                .then(() => {
                    if (!!issue.PrecedingOID) {
                        return window.Database.GetSingleByKey(Enums.DatabaseStorage.Issues, issue.PrecedingOID)
                            .then((issue: Model.Issues.Issue) => {
                                return this.removeIssueRevisionFromDatabase(issue);
                            });
                    }
                });
        }

        public GetResponseInformation(): Model.Synchronisation.ResponseInformation {
            if (this.response == null) {
                return null;
            }

            const responseDate = Utils.Http.GetResponseHeader(this.response, 'Date');
            const modifiedDate = Utils.Http.GetResponseHeader(this.response, 'Last-Modified');

            const responseInfo = this.syncInfo;
            // check if sync is repeating
            if (responseInfo.LastModifiedDate && modifiedDate) {
                responseInfo.IsRepeating = responseInfo.LastModifiedDate.getTime() == new Date(modifiedDate).getTime();
            }

            delete responseInfo.LastModifiedDateUntil;

            responseInfo.LastModifiedDate = modifiedDate ? new Date(modifiedDate) : null;
            responseInfo.ResponseDate = responseDate ? new Date(responseDate) : null;
            responseInfo.Skip = 0;
            responseInfo.ActivesToSkip = null;

            return responseInfo;
        }

        protected getDownloadUri(): string {
            const extraParams = { 'withrecorditems': 'true' };

            if (Session.LastKnownAPIVersion >= 11) {
                extraParams['take'] = this.take;
                extraParams['skip'] = this.skip;
            }

            if (!(Session.SynchronisationInformation &&
                Session.SynchronisationInformation.GetModifiedSinceParameter &&
                Session.SynchronisationInformation.GetModifiedSinceParameter(Enums.EntityType.ISSUES))) {
                // check restservice version for correct behaviour
                if (Session.LastKnownAPIVersion >= 11) {
                    extraParams['witharchived'] = false;
                    extraParams['modifiedsince'] = 'Sun, 31 Jan 1999 00:00:00 GMT';
                } else {
                    // legacy method for old restservice
                    extraParams['withrelatives'] = true;
                }
            } else if (!this.syncInfo.InitialSyncFinished && Session.LastKnownAPIVersion >= 11) {
                extraParams['witharchived'] = false;
            }

            // gelöschte Vorgänge in Batches laden
            extraParams['withpaging'] = true;

            // Anzahl aktiver Vorgänge, die bei Synchronisation gelöschter Vorgänge berücksichtigt werden sollen
            if (this.activesToSkip) {
                extraParams['activesToSkip'] = this.activesToSkip;
            }

            // Vorgänge nur bis zu einem bestimmten Zeitraum laden. Bei den Vorgängen ist es der Zeitraum vom
            // Lastmodified der Vorgänge bis zum Start der Synchronisation der Vorgänge.
            // Nach dem ersten Request vom Server wird "lastModifiedUntil" vom Response genommen und gesetzt.
            // Das ist die Zeit vom Datenbank-Server, dadurch werden Änderungen nur von einem Zeitraum abgefragt.
            if (this.lastModifiedUntil && Utils.DateTime.IsValid(new Date(this.lastModifiedUntil))) {
                extraParams['modifiedsinceuntil'] = this.lastModifiedUntil;
            }

            return this.buildUri(this.path, extraParams);
        }

        public GetRecorditems(): Array<Model.Recorditem> {
            // get recorditems from issues
            const recorditems = [];
            for (let iCnt = 0, iLen = this.resultData.length; iCnt < iLen; iCnt++) {
                const issue: Model.Issues.RawIssue = this.resultData[iCnt];

                if ((issue.Recorditems || []).length) {
                    for (let rCnt = 0, rLen = issue.Recorditems.length; rCnt < rLen; rCnt++) {
                        const recorditem: Model.Recorditem = issue.Recorditems[rCnt];
                        recorditems.push(recorditem);
                    }
                }
            }
            return recorditems;
        }
    }

    class HistoricalElementRevisionsDownloader extends Downloader<Model.Elements.Element> {
        private elementRevisionOIDs: Array<string>;
        private failedElementRevisions: Array<string>;
        protected customTimeout: number = Utils.Http.TIMEOUT_MS_MEDIUM;

        constructor(saveData: boolean, logger?: Model.ILogger) {
            super(Enums.EntityType.ELEMENTS, Enums.DatabaseStorage.HistoricalElements, saveData, true, logger);
        }

        public Start(): Deferred {
            return this.getRequiredElementRevisionOIDs()
                .then((requiredRevisionOIDs: Array<string>) => {
                    if (!requiredRevisionOIDs || !requiredRevisionOIDs.length) {
                        return $.Deferred().resolve();
                    }

                    this.elementRevisionOIDs = requiredRevisionOIDs;

                    return super.Start();
                });
        }

        protected logError(revisionOID, err, state, response): void {
            // log download data
            this.logger.LogData('Download', `Failed download element revision ${revisionOID}`);
            this.failedElementRevisions.push(revisionOID);
        }

        protected getDownloadRevisionUri(revisionOID: string): string {
            return `${this.path}?revisionoid=${revisionOID}`;
        }

        protected getBulkDownloadUri(): string {
            return `${this.path}/bulk`;
        }

        protected cacheResponseItem(data, state, response): Deferred {
            if ($.isArray(data)) {
                this.resultData = this.resultData.concat(data);
            } else if ($.isPlainObject(data)) {
                this.resultData.push(data);
            }

            return $.Deferred().resolve(data, state, response);
        }

        protected saveToDatabase(data: Array<Model.Elements.Element>): Deferred {
            if (!this.saveData) {
                return $.Deferred().resolve();
            }

            if (!window.Database) {
                // TODO replace logging with unified logger
                return $.Deferred().resolve();
            }

            if (data == null ||
                (data instanceof Array && !data.length)) {
                return $.Deferred().resolve();
            }

            return window.Database.SetInStorageNoChecks(this.storageType, data)
                .then(() => {
                    // log saved data
                    this.logger.LogData('Save Download', `Saved downloaded ${this.path} data`, data);

                    // delete just download revisions from required list
                    const oidsToRemove = $.map(data, (element: Model.Elements.Element) => element.RevisionOID);
                    return this.deleteElementOIDs(oidsToRemove);
                });
        }

        protected downloadElementRevision(revisionOID: string): Deferred {
            return Utils.Http.Get(this.getDownloadRevisionUri(revisionOID))
                .then($.proxy(this.cacheResponseItem, this),
                    $.proxy(this.logError, this, revisionOID))
                .fail(function(_response, _state, _error) {
                    throw new Model.Errors.HttpError(_error, _response);
                });
        }

        protected downloadElementsInBulk(revisionOIDs: Array<string>): Deferred {
            return Utils.Http.Post(this.getBulkDownloadUri(), revisionOIDs, null, undefined, undefined, this.customTimeout)
                .then($.proxy(this.cacheResponseItem, this),
                    $.proxy(this.logError, this, revisionOIDs))
                .fail(function(_response, _state, _error) {
                    throw new Model.Errors.HttpError(_error, _response);
                });
        }

        protected downloadFromServer(): Deferred {
            let downloadQueue = $.Deferred().resolve();
            this.resultData = [];
            this.failedElementRevisions = [];

            if (Session.LastKnownAPIVersion < 11) {
                for (let i = 0; i < this.elementRevisionOIDs.length; i++) {
                    const revisionOID = this.elementRevisionOIDs[i];
                    downloadQueue = downloadQueue
                        .then(() => this.downloadElementRevision(revisionOID));
                }
            } else {
                // download in bulk
                const BULK_COUNT = 100;
                for (let i = 0; i < this.elementRevisionOIDs.length; i += BULK_COUNT) {
                    const revisionOIDs = this.elementRevisionOIDs.slice(i, i + BULK_COUNT);
                    downloadQueue = downloadQueue
                        .then(() => this.downloadElementsInBulk(revisionOIDs));
                }
            }

            return downloadQueue.then(() => {
                return $.Deferred().resolve(this.resultData, null, null);
            });
        }

        protected getRequiredElementRevisionOIDs(): Deferred {
            // check available required elements
            return window.Database.GetAllFromStorage(Enums.DatabaseStorage.RequiredElementOIDs)
                .then((elementRevisions: Array<Dictionary<string>>) => {
                    // identify available elements
                    const oidsToDelete = {};
                    const oidsToSync = {};
                    let checkDeferredQueue = $.Deferred().resolve();

                    for (let i = 0; i < elementRevisions.length; i++) {
                        const revision = elementRevisions[i];
                        if (DAL.Elements.GetByRevisionOID(revision.OID) ||
                            !Utils.IsValidGuid(revision.OID)) {
                            oidsToDelete[revision.OID] = true;
                        } else {
                            oidsToSync[revision.OID] = true;

                            // check in historical elements, add to queue
                            (function(revision) {
                                checkDeferredQueue = checkDeferredQueue
                                    .then(() => window.Database.GetSingleByKey(Enums.DatabaseStorage.HistoricalElements, revision.OID))
                                    .then(function(element: Model.Elements.Element) {
                                        if (element) {
                                            delete oidsToSync[element.RevisionOID];
                                            oidsToDelete[revision.OID] = true;
                                        }
                                    });
                            })(revision);
                        }
                    }

                    // delete elements to delete
                    return checkDeferredQueue
                        .then(() => this.deleteElementOIDs(Object.keys(oidsToDelete)))
                        .then(() => Object.keys(oidsToSync))
                });
        }

        protected deleteElementOIDs(oidsToDelete: Array<string>): Deferred {
            if (!oidsToDelete || !oidsToDelete.length) {
                return $.Deferred().resolve();
            }

            // limit to 50 items per call
            const deleteNowOids = oidsToDelete.slice(0, 50)
            const remainingOids = oidsToDelete.slice(50)
            return window.Database.DeleteManyFromStorage(Enums.DatabaseStorage.RequiredElementOIDs, deleteNowOids)
                .then(() => this.deleteElementOIDs(remainingOids));  // delete remaining OIDs
        }
    }

    class IndividualDataDownloader extends Downloader<any> {
        private schemaUri: string;
        public identifier: number;
        protected customTimeout: number = Utils.Http.TIMEOUT_MS_LONG;

        constructor(schemaUri: string, schemaType: string, saveData: boolean, logger?: Model.ILogger, identifier: number = -1) {
            super(schemaType, Enums.DatabaseStorage.IndividualData, saveData, true, logger);
            this.schemaUri = schemaUri;
            this.identifier = identifier;
            this.setOnPrepareSave(this.PrepareEntityForSave);
        }

        public GetData(): Array<any> {
            return this.resultData;
        }

        public GetType(): string {
            return this.path;
        }

        protected getDownloadUri(): string {
            return this.buildUri(this.schemaUri);
        }

        protected getLastModifiedDate(path: Enums.EntityType | string): string {
            return super.getLastModifiedDate('schema-' + this.GetType());
        }

        public GetResponseInformation(alternateEntityName?: string): Model.Synchronisation.ResponseInformation {
            return super.GetResponseInformation('schema-' + this.GetType());
        }

        protected PrepareEntityForSave(entity) {
            entity.Type = this.GetType();
            entity.__key = `${entity.Type}-${entity.ID}`;

            return entity;
        }
    }

    class DownloaderListenerDelegate<T> implements DownloaderListener<T>{
        private callbackMethod: (data: T[]) => void;
        constructor(callback: (data: T[]) => void) {
            this.callbackMethod = callback;
        }

        public onCacheData(data: T[]): void {
            if (this.callbackMethod) {
                this.callbackMethod(data);
            }
        }
    }

    class RecorditemsCollector {
        private issuesDownloadListener: DownloaderListenerDelegate<Model.Issues.RawIssue>;
        private recorditemsDownloadListener: DownloaderListenerDelegate<Model.Recorditem>;

        protected recorditems: Array<Model.Recorditem> = [];
        protected recorditemLookup = new Utils.HashSet();

        constructor(recorditemsLoader: RecorditemsDownloader, issuesLoader: IssuesDownloader) {
            if (recorditemsLoader) {
                this.recorditemsDownloadListener = new DownloaderListenerDelegate<Model.Recorditem>((data) => {
                    this.onCacheRecorditems(data);
                });
                recorditemsLoader.setListener(this.recorditemsDownloadListener);
            }

            if (issuesLoader) {
                this.issuesDownloadListener = new DownloaderListenerDelegate<Model.Issues.RawIssue>((data) => {
                    this.onCacheIssues(data);
                });
                issuesLoader.setListener(this.issuesDownloadListener);
            }
        }

        public GetRecorditems(): Model.Recorditem[] {
            for (let i = 0; i < this.recorditems.length; i++) {
                const rec = this.recorditems[i];
                rec.IsHistorical = true;
            }
            return this.recorditems;
        }

        protected onCacheRecorditems(syncedRecorditems: Model.Recorditem[]) {
            // set recorditems as  historical
            for (let rCnt = 0, rLen = syncedRecorditems.length; rCnt < rLen; rCnt++) {
                const recorditem = syncedRecorditems[rCnt];

                //recorditem.IsHistorical = true;

                this.recorditems.push(recorditem);
                this.recorditemLookup.put(recorditem.OID);
            }
        }

        protected onCacheIssues(issues: Model.Issues.RawIssue[]) {
            /*
            * set new synced recorditems as historical
            */

            // update historical recorditems on issues
            for (let iCnt = 0, iLen = issues.length; iCnt < iLen; iCnt++) {
                const issue: Model.Issues.RawIssue = issues[iCnt];

                if (!(issue.Recorditems || []).length) {
                    continue;
                }

                for (let rCnt = 0, rLen = issue.Recorditems.length; rCnt < rLen; rCnt++) {
                    const recorditem: Model.Recorditem = issue.Recorditems[rCnt];

                    if (this.recorditemLookup.has(recorditem.OID)) {
                        continue;
                    }

                    //recorditem.IsHistorical = true;

                    this.recorditems.push(recorditem);
                    this.recorditemLookup.put(recorditem.OID);
                }
            }
        }
    }

    class FilenamesCollector {
        private issuesDownloadListener: DownloaderListenerDelegate<Model.Issues.RawIssue>;
        private recorditemsDownloadListener: DownloaderListenerDelegate<Model.Recorditem>;
        private elementsDownloadListener: DownloaderListenerDelegate<Model.Elements.Element>;
        private filesDownloadListener: DownloaderListenerDelegate<Model.Files.RawFile>;
        private menuitemsDownloadListener: DownloaderListenerDelegate<Model.Menu.IMenuItemConfig>;
        private usersDownloadListener: DownloaderListenerDelegate<Model.Users.RawUser>;
        private teamsDownloadListener: DownloaderListenerDelegate<Model.Teams.RawTeam>;
        // IndividualData wird extern behandelt

        public static RecorditemFilenameRegex = /[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}\.\w/ig;
        public static PdfRegex = /([\dA-F]{8}-[\dA-F]{4}-[\dA-F]{4}-[\dA-F]{4}-[\dA-F]{12})\.pdf/i;
        public static LayoutImageRegex = /href="([\dA-F]{8}-[\dA-F]{4}-[\dA-F]{4}-[\dA-F]{4}-[\dA-F]{12}\.\w+)"/i;

        private previousSaveAttempt: Deferred = $.Deferred().resolve();

        protected filenames: Model.Files.MissingFile[] = [];
        protected filenamesLookup: Dictionary<Model.Files.MissingFile> = {};

        constructor(recorditemsLoader: RecorditemsDownloader, issuesLoader: IssuesDownloader, elementsLoader: ElementsDownloader, filesLoader: FilesDownloader, menuitemsLoader: MenuItemsDownloader, usersLoader: UsersDownloader, teamsLoader: TeamsDownloader) {
            if (recorditemsLoader) {
                this.recorditemsDownloadListener = new DownloaderListenerDelegate<Model.Recorditem>((data) => {
                    this.onCacheRecorditems(data);
                });
                recorditemsLoader.setListener(this.recorditemsDownloadListener);
            }

            if (issuesLoader) {
                this.issuesDownloadListener = new DownloaderListenerDelegate<Model.Issues.RawIssue>((data) => {
                    this.onCacheIssues(data);
                });
                issuesLoader.setListener(this.issuesDownloadListener);
            }

            if (elementsLoader) {
                this.elementsDownloadListener = new DownloaderListenerDelegate<Model.Elements.Element>((data) => {
                    this.onCacheElements(data);
                });
                elementsLoader.setListener(this.elementsDownloadListener);
            }

            if (filesLoader) {
                this.filesDownloadListener = new DownloaderListenerDelegate<Model.Files.RawFile>((data) => {
                    this.onCacheFiles(data);
                });
                filesLoader.setListener(this.filesDownloadListener);
            }

            if (menuitemsLoader) {
                this.menuitemsDownloadListener = new DownloaderListenerDelegate<Model.Menu.IMenuItemConfig>((data) => {
                    this.onCacheMenuitems(data);
                });
                menuitemsLoader.setListener(this.menuitemsDownloadListener);
            }

            if (usersLoader) {
                this.usersDownloadListener = new DownloaderListenerDelegate<Model.Users.RawUser>((data) => {
                    this.onCacheUsersAndTeams(data);
                });
                usersLoader.setListener(this.usersDownloadListener);
            }

            if (teamsLoader) {
                this.teamsDownloadListener = new DownloaderListenerDelegate<Model.Teams.RawTeam>((data) => {
                    this.onCacheUsersAndTeams(data);
                });
                teamsLoader.setListener(this.teamsDownloadListener);
            }
        }

        public Add(file: Model.Files.File, source: FileSource, usage: FileUsage, elementOID?: string): void
        public Add(filename: string, source: FileSource): void
        public Add(filename: string, source: FileSource, usage: FileUsage, elementOID?: string): void
        public Add(filename_file: string | Model.Files.File, source: FileSource, usage?: FileUsage, elementOID?: string): void {
            // prüfen auf File Objekt oder Datei-String
            if (typeof filename_file !== 'string') {
                if (filename_file && filename_file.IsAvailableOffline) {
                    // Daten aus File Objekt übernehmen
                    filename_file = filename_file.Filename;
                    typeof source == 'undefined' || source === null ? FileSource.FileCatalog : source
                } else {
                    // kein gültiges File-Objekt/keine Offline Verfügbarkeit
                    return;
                }
            }

            let fileItem: Model.Files.MissingFile = this.filenamesLookup[filename_file];

            if (!fileItem) {
                fileItem = {
                    Filename: filename_file,
                    Source: source
                };

                this.filenames.push(fileItem);
                this.filenamesLookup[filename_file] = fileItem;
            } else {
                delete fileItem.SkipFormCheck;
            }

            if (!usage && source == FileSource.FileCatalog) {
                usage = FileUsage.Generic;
            }

            if (!fileItem.Usages || !fileItem.Usages.length) {
                fileItem.Usages = [usage];
            } else if (!fileItem.Usages.includes(usage)) {
                fileItem.Usages.push(usage);
            }

            if (elementOID) {
                if (!fileItem.Elements || !fileItem.Elements.length) {
                    fileItem.Elements = [elementOID];
                } else if (!fileItem.Elements.includes(elementOID)) {
                    fileItem.Elements.push(elementOID);
                }
            }
        }

        public GetFilenames(): Model.Files.MissingFile[] {
            return this.filenames;
        }

        public Flush(): Deferred {
            if (!this.filenames || !this.filenames.length) {
                return $.Deferred().resolve();
            }

            const preservedFilenames = this.filenames;

            // filenames für Folgeaktionen schon zurücksetzen
            this.filenames = [];
            this.filenamesLookup = {};

            const filesMap: Dictionary<Model.Files.MissingFile> = preservedFilenames.reduce((acc, file) => (acc[file.Filename] = file, acc), {});

            // previousSaveAttempt Deferred verwendet um paralleles schreiben zu verhindern
            this.previousSaveAttempt = this.previousSaveAttempt
                .then(null, () => $.Deferred().resolve())
                .then(() => window.Database.GetManyByKeys(Enums.DatabaseStorage.MissingFiles, Object.keys(filesMap)))
                .then((existingEntries: Model.Files.MissingFile[]) => {
                    // vorhandene Einträge aktualisieren, Anzahl gemachter Versuche erhalten
                    for (let i = 0; i < existingEntries.length; i++) {
                        const dbEntry = existingEntries[i];
                        const newEntry = filesMap[dbEntry.Filename];

                        // Properties erweitern
                        for (const key in dbEntry) {
                            if (!dbEntry.hasOwnProperty(key)) {
                                continue;
                            }

                            // fehlende Properties ergänzen
                            if (!newEntry.hasOwnProperty(key)) {
                                newEntry[key] = dbEntry[key];
                                continue;
                            }

                            // Properties zusammenfügen: Elements & Usage
                            switch (key) {
                                case 'Elements':
                                    const elements = new Utils.HashSet(dbEntry.Elements);
                                    elements.putRange(newEntry.Elements);
                                    newEntry.Elements = elements.toArray();
                                    break;
                                case 'Usages':
                                    const usage = new Utils.HashSet(dbEntry.Usages);
                                    usage.putRange(newEntry.Usages);
                                    newEntry.Usages = usage.toIntArray();
                                    break;
                            }
                        }
                    }

                    return window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.MissingFiles, preservedFilenames);
                })
                .fail(() => {
                    // Speichern fehlgeschlagen, Namen wieder der liste hinzufügen
                    this.filenames = this.filenames.concat(preservedFilenames);
                    for (const fileItem of preservedFilenames) {
                        this.filenamesLookup[fileItem.Filename] = fileItem;
                    }
                })

            return this.previousSaveAttempt;
        }

        protected onCacheUsersAndTeams(usersOrTeams: (Model.Users.RawUser | Model.Teams.RawTeam)[]) {
            // Dateinamen aus Benutzerdaten extrahieren
            for (let miCnt = 0, eLen = usersOrTeams.length; miCnt < eLen; miCnt++) {
                const userOrTeam = usersOrTeams[miCnt];

                // Icons nur erfassen, wenn nicht gelöscht
                if (userOrTeam.Deleted || !userOrTeam.ImageOID) {
                    continue;
                }

                // Icons für User/Teams immer offline behalten
                const file = DAL.Files.GetByOID(userOrTeam.ImageOID);
                if (file) {
                    this.Add(file.Filename, FileSource.FileCatalog, FileUsage.UsersAndTeams);
                }
            }

            this.Flush();
        }

        protected onCacheMenuitems(menuitems: Model.Menu.IMenuItemConfig[]) {
            // Dateinamen aus Menu Items extrahieren
            for (let miCnt = 0, eLen = menuitems.length; miCnt < eLen; miCnt++) {
                const mItem = menuitems[miCnt];

                // Icons nur erfassen, wenn für App freigegeben (evtl. Api prüfen)
                if (mItem.Deleted || !mItem.IsAvailableInRecordingApp || !mItem.IconOID) {
                    continue;
                }

                // Icons für Menu immer offline behalten
                const file = DAL.Files.GetByOID(mItem.IconOID);
                if (file) {
                    this.Add(file.Filename, FileSource.FileCatalog, FileUsage.Menu);
                }
            }

            this.Flush();
        }

        protected onCacheRecorditems(syncedRecorditems: Model.Recorditem[], skipFlush = false) {
            // Dateinamen aus erfassten Prüfpunkten
            for (let rCnt = 0, rLen = syncedRecorditems.length; rCnt < rLen; rCnt++) {
                const recorditem: Model.RawRecorditem = syncedRecorditems[rCnt];

                // gelöschte Recorditems ignorieren?
                if (recorditem.IsDeleted) {
                    continue;
                }

                FilenamesCollector.RecorditemFilenameRegex.lastIndex = 0;

                if (!!recorditem.Value && typeof recorditem.Value === 'string' &&
                    FilenamesCollector.RecorditemFilenameRegex.test(recorditem.Value)) {
                    this.Add(recorditem.Value, FileSource.Recorditem);
                }

                if (recorditem.ElementType === Enums.ElementType.Files &&
                    recorditem.Value instanceof Array) {
                    for (const file of <Model.IFilesRecorditemValue[]>recorditem.Value) {
                        FilenamesCollector.RecorditemFilenameRegex.lastIndex = 0;

                        if (FilenamesCollector.RecorditemFilenameRegex.test(file.Filename)) {
                            this.Add(file.Filename, FileSource.Recorditem);
                        }
                    }
                }

                if (!(recorditem.AdditionalFiles || []).length) {
                    continue;
                }

                for (let fCnt = 0, fLen = recorditem.AdditionalFiles.length; fCnt < fLen; fCnt++) {
                    this.Add(recorditem.AdditionalFiles[fCnt].Filename, FileSource.Recorditem);
                }
            }

            if (!skipFlush) {
                this.Flush();
            }
        }

        protected onCacheIssues(issues: Model.Issues.RawIssue[]) {
            // update historical recorditems on issues
            for (let iCnt = 0, iLen = issues.length; iCnt < iLen; iCnt++) {
                const issue: Model.Issues.RawIssue = issues[iCnt];

                // abgeschlossene/archivierte Vorgänge ignorieren
                if (issue.IsArchived || issue.IsDeleted) {
                    continue;
                }

                if (issue.Recorditems) {
                    this.onCacheRecorditems(issue.Recorditems, true);
                }

                if (!(issue.Files || []).length) {
                    continue;
                }

                for (let fCnt = 0, fLen = issue.Files.length; fCnt < fLen; fCnt++) {
                    let issueFile: any = issue.Files[fCnt];

                    this.Add(issueFile.Filename, FileSource.Issue);

                    // TODO check this property
                    if (!!issueFile.AlternativeFilename) {
                        this.Add(issueFile.AlternativeFilename, FileSource.Issue);
                    }

                }
            }

            this.Flush();
        }

        protected onCacheElements(elements: Model.Elements.Element[]) {
            // Dateinamen aus Grundrissen extrahieren
            for (let eCnt = 0, eLen = elements.length; eCnt < eLen; eCnt++) {
                let element = elements[eCnt];
                let filename: RegExpExecArray | string = FilenamesCollector.LayoutImageRegex.exec(element.Layout);

                // gelöschte/inaktive Elemente ignorieren
                if (element.Deleted || !element.Enabled) {
                    continue;
                }

                // verknüpfte Dateien ermitteln und vormerken
                if (element.Files && element.Files.length) {
                    for (let i = 0; i < element.Files.length; i++) {
                        const file = element.Files[i];

                        const dalFile = DAL.Files.GetByOID(file.OID);
                        this.Add(dalFile, FileSource.FileCatalog, FileUsage.Element, element.OID);
                    }
                }

                // im Grundriss verwendete Dateien/Bilder extrahieren
                if (!!element.Layout &&
                    (filename || []).length) {

                    if (FilenamesCollector.PdfRegex.test(filename[1])) {
                        // PDF werden als PNG bereitgestellt
                        filename[1] = filename[1].replace(FilenamesCollector.PdfRegex, '$1.png');
                        FilenamesCollector.PdfRegex.lastIndex = 0;
                    }

                    // Grundriss Bilder immer offline bereithalten
                    this.Add(filename[1], FileSource.FileCatalog, FileUsage.ElementLayout, element.OID);
                }

                // in Auswahl-/Mehrfachauswahl-PP eingesetzte Bilder ermitteln und vormerken
                if ((element.Type == Enums.ElementType.ListBox || element.Type == Enums.ElementType.MultiListBox) &&
                    element.AdditionalSettings && element.AdditionalSettings.MapStructureToImages && element.Structure) {
                    for (const key in element.Structure) {
                        if (element.Structure.hasOwnProperty(key)) {
                            const imageOID = element.Structure[key];
                            const dalFile = DAL.Files.GetByOID(imageOID);
                            this.Add(dalFile, FileSource.FileCatalog, FileUsage.Element, element.OID);
                        }
                    }
                }
            }

            this.Flush();
        }

        protected onCacheFiles(files: Model.Files.RawFile[]) {
            for (let fCnt = 0, fLen = files.length; fCnt < fLen; fCnt++) {
                let newFile: Model.Files.RawFile = files[fCnt];
                let existingFile = DAL.Files.GetByOID(newFile.OID);

                // Änderungen am Status online -> offline oder Änderungen erfassen!
                // Andere Dateien werden nach Bedarf erfasst.
                if (existingFile &&
                    !newFile.Deleted &&
                    newFile.IsAvailableOffline &&
                    newFile.RevisionOID != existingFile.RevisionOID) {
                    this.Add(newFile.Filename, FileSource.FileCatalog, FileUsage.Element);
                }
            }
        }
    }

    class IndividualDataFilenamesCollectorHelper {
        private FilenamesCollector: FilenamesCollector;
        private imageProps = [];

        private constructor(schema: Model.IndividualData.Schema, dataLoader: IndividualDataDownloader, filenamesCollector: FilenamesCollector) {
            this.FilenamesCollector = filenamesCollector;

            // Bild-Eigenschaften aus IndividualDaten sammeln
            for (let i = 0; i < schema.Properties.length; i++) {
                const prop = schema.Properties[i];
                if (prop.Type == Enums.IndividualDataType.Image) {
                    this.imageProps.push(prop);
                }
            }

            // DownloadListener hinzufügen, wenn IndividualDaten Bilder enthalten
            if (this.imageProps.length) {
                const individualDataDownloadListener = new DownloaderListenerDelegate<any[]>((data: any[]) => {
                    this.onCacheIndividualdataImages(data);
                });
                dataLoader.setListener(individualDataDownloadListener);
            }
        }

        public static Collect(schema: Model.IndividualData.Schema, dataLoader: IndividualDataDownloader, filenamesCollector: FilenamesCollector) {
            new IndividualDataFilenamesCollectorHelper(schema, dataLoader, filenamesCollector);
        }

        protected onCacheIndividualdataImages(data: any[]) {
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                for (let pi = 0; pi < this.imageProps.length; pi++) {
                    const prop = this.imageProps[pi];
                    const oid = item[prop.Name];
                    const file = DAL.Files.GetByOID(oid);
                    this.FilenamesCollector.Add(file, FileSource.FileCatalog, FileUsage.IndividualData);
                }
            }

            // fehlende Dateien speichern
            this.FilenamesCollector.Flush();
        }
    }

    class ScanCodesIssuesCollector {
        private issuesDownloadListener: DownloaderListenerDelegate<Model.Issues.RawIssue>;
        protected issuesDict: Dictionary<{ ID: number, IsArchived?: boolean, IsDeleted?: boolean }> = {};

        constructor(issuesLoader: IssuesDownloader) {
            if (issuesLoader) {
                this.issuesDownloadListener = new DownloaderListenerDelegate<Model.Issues.RawIssue>((data) => {
                    this.onCacheIssues(data);
                });
                issuesLoader.setListener(this.issuesDownloadListener);
            }
        }

        public GetIssuesInfo(): Dictionary<{ ID: number, IsArchived?: boolean, IsDeleted?: boolean }> {
            return this.issuesDict;
        }

        protected onCacheIssues(issues: Model.Issues.RawIssue[]) {
            for (let iCnt = 0, iLen = issues.length; iCnt < iLen; iCnt++) {
                const issue: Model.Issues.RawIssue = issues[iCnt];
                this.issuesDict[issue.ID] = {
                    ID: issue.ID
                };

                if (issue.IsArchived) {
                    this.issuesDict[issue.ID].IsArchived = issue.IsArchived;
                }

                if (issue.IsDeleted) {
                    this.issuesDict[issue.ID].IsDeleted = issue.IsDeleted;
                }
            }
        }
    }

    class DownloadManager {
        public SyncEntitiesChecker: SyncEntitiesChecker;
        public ElementsLoader: ElementsDownloader;
        public FilesLoader: FilesDownloader;
        public UsersLoader: UsersDownloader;
        public TeamsLoader: TeamsDownloader;
        public RolesLoader: RolesDownloader;
        public PropertiesLoader: PropertiesDownloader;
        public SchedulingsLoader: SchedulingsDownloader;
        public ContactsLoader: ContactsDownloader;
        public ContactGroupsLoader: ContactGroupsDownloader;
        public SchemasLoader: SchemasDownloader;
        public RecorditemsLoader: RecorditemsDownloader;
        public MenuItemsLoader: MenuItemsDownloader;
        public PreviousRecorditemsLoader: PreviousRecorditemsDownloader;
        public IssuesLoader: IssuesDownloader;

        // TODO run historicalElementLoader in background
        public HistoricalElementLoader: HistoricalElementRevisionsDownloader;

        public RecorditemsCollector: RecorditemsCollector;
        public FilenamesCollector: FilenamesCollector;
        public ScanCodesIssuesCollector: ScanCodesIssuesCollector;

        protected isSmartDevice: boolean;
        protected resultDeferred: Deferred;

        constructor(isSmartDevice: boolean, _logger: Model.ILogger) {
            this.isSmartDevice = isSmartDevice;

            this.RolesLoader = new RolesDownloader(isSmartDevice, isSmartDevice, _logger);
            this.TeamsLoader = new TeamsDownloader(isSmartDevice, isSmartDevice, _logger);
            this.UsersLoader = new UsersDownloader(isSmartDevice, isSmartDevice, _logger);
            this.ElementsLoader = new ElementsDownloader(isSmartDevice, isSmartDevice, _logger);
            this.PropertiesLoader = new PropertiesDownloader(isSmartDevice, isSmartDevice, _logger);
            this.SchedulingsLoader = new SchedulingsDownloader(isSmartDevice, isSmartDevice, _logger);
            this.ContactsLoader = new ContactsDownloader(isSmartDevice, isSmartDevice, _logger);
            this.ContactGroupsLoader = new ContactGroupsDownloader(isSmartDevice, isSmartDevice, _logger);
            this.SchemasLoader = new SchemasDownloader(isSmartDevice, isSmartDevice, _logger);
            this.FilesLoader = new FilesDownloader(isSmartDevice, isSmartDevice, _logger);
            this.MenuItemsLoader = new MenuItemsDownloader(isSmartDevice, isSmartDevice, _logger);

            if (isSmartDevice) {
                this.SyncEntitiesChecker = new SyncEntitiesChecker();
                this.RecorditemsLoader = new RecorditemsDownloader(isSmartDevice, this.SyncEntitiesChecker, isSmartDevice, _logger);
                this.PreviousRecorditemsLoader = new PreviousRecorditemsDownloader(this.ElementsLoader, isSmartDevice, _logger);
                this.IssuesLoader = new IssuesDownloader(isSmartDevice, this.SyncEntitiesChecker, _logger);
                this.HistoricalElementLoader = new HistoricalElementRevisionsDownloader(isSmartDevice, _logger);
                this.FilenamesCollector = new FilenamesCollector(
                    this.RecorditemsLoader, this.IssuesLoader, this.ElementsLoader,
                    this.FilesLoader, this.MenuItemsLoader, this.UsersLoader, this.TeamsLoader
                );
                this.ScanCodesIssuesCollector = new ScanCodesIssuesCollector(this.IssuesLoader);
            }

            this.RecorditemsCollector = new RecorditemsCollector(this.RecorditemsLoader, this.IssuesLoader);
        }

        public StartDownload(): Deferred {
            this.resultDeferred = $.Deferred();
            const totalDownloads = this.isSmartDevice ? 15 : 11;

            let downloadQueue = GetAccountByCredentials()
                .then(() => GetUserSettings())
                .then(() => New.Analytics.ToggleGoogleAnalytics(Session.Settings.UseGoogleAnalytics));

            if (this.SyncEntitiesChecker) {
                downloadQueue = downloadQueue.then(() =>
                    this.SyncEntitiesChecker.Init()
                );
            }

            let currentProgress = 1;

            downloadQueue = downloadQueue
                .then(this.RolesLoader.GetStarter()).then(() => this.onProgress(currentProgress, totalDownloads, 2))
                .then(this.FilesLoader.GetStarter()).then(() => this.onProgress(currentProgress++, totalDownloads, 2))
                .then(this.TeamsLoader.GetStarter()).then(() => this.onProgress(currentProgress++, totalDownloads, 2))
                .then(this.UsersLoader.GetStarter()).then(() => this.onProgress(currentProgress++, totalDownloads, 2))
                .then(this.ElementsLoader.GetStarter()).then(() => this.onProgress(currentProgress++, totalDownloads, 2))
                .then(this.PropertiesLoader.GetStarter()).then(() => this.onProgress(currentProgress++, totalDownloads, 2))
                .then(this.SchedulingsLoader.GetStarter()).then(() => this.onProgress(currentProgress++, totalDownloads, 2))
                .then(this.ContactsLoader.GetStarter()).then(() => this.onProgress(currentProgress++, totalDownloads, 2))
                .then(this.SchemasLoader.GetStarter()).then(() => this.onProgress(currentProgress++, totalDownloads, 2));

            if (Session.LastKnownAPIVersion >= 8) {
                downloadQueue = downloadQueue.then(this.MenuItemsLoader.GetStarter()).then(() => this.onProgress(currentProgress++, totalDownloads, 2));
            }

            if (Session.LastKnownAPIVersion >= 14) {
                downloadQueue = downloadQueue.then(this.ContactGroupsLoader.GetStarter()).then(() => this.onProgress(currentProgress++, totalDownloads, 2))
            }

            if (this.isSmartDevice) {
                downloadQueue = downloadQueue
                    .then(this.RecorditemsLoader.GetStarter()).then(() => this.onProgress(currentProgress++, totalDownloads, 2))
                    .then(this.PreviousRecorditemsLoader.GetStarter()).then(() => this.onProgress(currentProgress++, totalDownloads, 2))
                    .then(this.IssuesLoader.GetStarter()).then(() => this.onProgress(currentProgress++, totalDownloads, 2))
                    .then(this.HistoricalElementLoader.GetStarter()).then(() => this.onProgress(currentProgress++, totalDownloads, 2));
            }

            downloadQueue
                .then(() => this.downloadIndividualData()).then(() => this.onProgress(currentProgress++, totalDownloads, 2))
                .then(this.resultDeferred.resolve, this.resultDeferred.reject);

            return this.resultDeferred;
        }

        protected onProgress(current: number, total: number, dividePercentBy: number, percent: number = 0): void {
            if (this.resultDeferred) {
                const range = 100 / total;
                const partProgress = range * current;
                const totalProgress = partProgress + (range * percent / 100);
                this.resultDeferred.notify(dividePercentBy > 0 ? totalProgress / dividePercentBy : totalProgress);
            }
        }

        protected downloadIndividualData(): Deferred {
            const schemas = this.SchemasLoader.GetData();
            if (schemas == null) {
                return $.Deferred().resolve([]);
            }

            let result = [];
            let deferredQueue = $.Deferred().resolve();
            const resultDeferred = $.Deferred();

            for (let i = 0, len = schemas.length; i < len; i++) {
                const currentSchema = schemas[i];
                const dataLoader = new IndividualDataDownloader(currentSchema.URI, currentSchema.Type, this.isSmartDevice, _logger, i + 1);

                // Bild-Eigenschaften aus IndividualDaten sammeln
                if (Session.IsSmartDeviceApplication) {
                    IndividualDataFilenamesCollectorHelper.Collect(currentSchema, dataLoader, this.FilenamesCollector);
                }

                deferredQueue = deferredQueue
                    .then(dataLoader.GetStarter())
                    .then((dataLoader: IndividualDataDownloader) => {
                        // cache data
                        const data = dataLoader.GetData();
                        if ((data || []).length) {
                            const schemaType = dataLoader.GetType();

                            for (let i = 0, len = data.length; i < len; i++) {
                                // add schema type to data
                                data[i].Type = schemaType;
                            }

                            result = result.concat(data);
                        }

                        resultDeferred.notify(dataLoader.identifier / schemas.length * 100);
                    });
            }

            deferredQueue
                .then(() => {
                    // save data to DAL(?)
                    DAL.IndividualData.Store(result);
                    // return downloaded data
                    return result;
                })
                .then(resultDeferred.resolve, resultDeferred.reject);

            return resultDeferred.promise();
        }
    }

    class FilesRequirementChecker {
        private readonly sourceFiles: OrderedDictionary<Model.Files.MissingFile>;
        private readonly filesToUpdate: Model.Files.MissingFile[] = [];
        private readonly elementsCache = new OrderedDictionary<Model.Elements.Element>(element => element.OID);
        private readonly requiredElements = new Utils.HashSet();

        private constructor(files: OrderedDictionary<Model.Files.MissingFile>) {
            this.sourceFiles = files;
        }

        public static RunFilesCheckAndUpdate(indexedFiles: OrderedDictionary<Model.Files.MissingFile>): Deferred {
            if (!indexedFiles || !indexedFiles.length) {
                return $.Deferred().resolve(null);
            }

            const userCanSeeAllForms = Utils.UserHasRight(Session.User.OID, Enums.Rights.SeeAllForms);
            if (userCanSeeAllForms) {
                return $.Deferred().resolve(indexedFiles);
            }

            return new FilesRequirementChecker(indexedFiles).run();
        }

        private run(): Deferred {
            const isFileCheckRequired = this.analyseFileUsageByFormElements();
            if (!isFileCheckRequired) {
                return $.Deferred().resolve(this.sourceFiles);
            }

            // Fehlende Elemente laden
            return this.loadRequiredElements()
                .then(() => {
                    // Sichtbare Formulare ermitteln
                    const visibleForms = this.determineUserVisibleForms();
                    const unnecessaryMissingFiles = this.findUnnecessaryFiles(visibleForms);

                    // Änderungen an Dateien speichern / unnötige Dateien rauslöschen
                    if (!unnecessaryMissingFiles.isEmpty()) {
                        return window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.MissingFiles, this.filesToUpdate)
                            .then(() => window.Database.DeleteManyFromStorage(Enums.DatabaseStorage.MissingFiles, unnecessaryMissingFiles.keysToArray()));
                    } else if (this.filesToUpdate.length) {
                        return window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.MissingFiles, this.filesToUpdate);
                    }
                })
                .then(() => this.sourceFiles);
        }

        private analyseFileUsageByFormElements(): boolean {
            let needFilesCheck = false;

            for (const fileInfo of this.sourceFiles.toArray()) {
                if (fileInfo.SkipFormCheck) {
                    continue;
                }

                // Prüfen ob Bild zum Formular gehören könnte
                if (fileInfo.Elements &&
                    fileInfo.Usages && fileInfo.Usages.length == 1 &&
                    fileInfo.Usages[0] == FileUsage.Element) {
                    needFilesCheck = true;

                    for (const elementOID of fileInfo.Elements) {
                        let element = DAL.Elements.GetByOID(elementOID);
                        if (!element) {
                            this.requiredElements.put(elementOID);
                            continue;
                        }

                        do {
                            this.elementsCache.pushUnique(element);

                            // Bei Prüfgruppen/Prüfpunkten auch die Elternelemente laden
                            if (element.Type > 90) {
                                element = DAL.Elements.GetByOID(element.ParentOID);
                                if (!element) {
                                    this.requiredElements.put(elementOID);
                                    break;
                                }
                            } else {
                                element = null;
                            }
                        } while (element && element.Type !== Enums.ElementType.Form);
                    }
                }
            }

            return needFilesCheck;
        }

        private loadRequiredElements(): Deferred {
            // Fehlende Elemente laden
            const elementOIDsRemaining = this.requiredElements.toArray();
            let loadChain = $.Deferred().resolve();

            while (elementOIDsRemaining.length) {
                // es dürfen max. 999 Parameter an SQLite übergeben werden
                const parameters = elementOIDsRemaining.splice(0, 950);
                loadChain = loadChain.then(() => {
                    // Elemente über 3 Ebenen in der Hierarchie laden
                    return window.Database.GetWithChainSelect(Enums.DatabaseStorage.Elements, {
                        'id': parameters,
                    }, [{
                        sourceColumn: 'IDX_ParentOID',
                        targetColumn: 'id'
                    }, {
                        sourceColumn: 'IDX_ParentOID',
                        targetColumn: 'id'
                    }]).then((elements: Model.Elements.Element[]) => {
                        if (elements && elements.length) {
                            this.elementsCache.putRangeUnique(elements, undefined, false);
                        }
                    })
                });
            }

            return loadChain;
        }

        private determineUserVisibleForms(): Utils.HashSet {
            const visibleForms = new Utils.HashSet();
            const userElements = Session.User.ElementRights.map(e => e.ElementOID);
            const elementsChecked: Dictionary<true> = {};

            for (const userElementOID of userElements) {
                const curUserElement = DAL.Elements.GetByOID(userElementOID);

                if (!curUserElement) {
                    continue;
                }

                // Einstiegsebene mit aktuellem Benutzerelement festlegen
                const traverseStack = [{
                    childIdx: 0,
                    item: curUserElement
                }];

                do {
                    const lastStackItem = traverseStack[traverseStack.length - 1];

                    // Element Typ muss einer OE entsprechen
                    if (!lastStackItem.item ||
                        !(lastStackItem.item.Type === Enums.ElementType.Root ||
                            lastStackItem.item.Type === Enums.ElementType.Location)) {
                        traverseStack.pop();
                        continue;
                    }

                    // OE Zweige, die bereits geprüft wurden, können ignoriert werden
                    if (elementsChecked[lastStackItem.item.OID]) {
                        traverseStack.pop();
                        continue;
                    }

                    if (lastStackItem.item.Children &&
                        lastStackItem.item.Children.length > lastStackItem.childIdx) {
                        // Kind-Element für nächste Prüfung im Stack anfügen
                        traverseStack.push({
                            childIdx: 0,
                            item: lastStackItem.item.Children[lastStackItem.childIdx]
                        });

                        lastStackItem.childIdx++;
                    } else {
                        if (lastStackItem.item.Forms) {
                            visibleForms.putRange(lastStackItem.item.Forms);
                        }

                        traverseStack.pop();
                        elementsChecked[lastStackItem.item.OID] = true;
                    }
                } while (traverseStack.length);
            }

            return visibleForms;
        }

        private findUnnecessaryFiles(visibleForms: HashSet): OrderedDictionary<Model.Files.MissingFile> {
            const unnecessaryMissingFiles = new OrderedDictionary<Model.Files.MissingFile>(file => file.Filename);

            // Prüfen ob Dateien zu sichtbaren Formularen gehören
            for (const fileInfo of this.sourceFiles.toArray()) {
                // Prüfung nur durchführen, wenn Datei-Verwendung lediglich für Elemente existiert
                // bei Verwendung in mehreren bereichen, muss die Datei immer geladen werden
                if (fileInfo.SkipFormCheck ||
                    !fileInfo.Elements ||
                    !fileInfo.Usages || fileInfo.Usages.length != 1 ||
                    fileInfo.Usages[0] != FileUsage.Element) {
                    continue;
                }

                let fileRequired = false;
                // prüfen ob Formular für Benutzer sichtbar ist
                for (let elementOID of fileInfo.Elements) {
                    let targetElement: Model.Elements.Element;
                    // zugehöriges Formular finden
                    do {
                        targetElement = this.elementsCache.getByKey(elementOID);

                        if (!targetElement) {
                            targetElement = DAL.Elements.GetByOID(elementOID);
                        }

                        elementOID = targetElement ? targetElement.ParentOID : null;
                    } while (elementOID && targetElement && targetElement.Type != Enums.ElementType.Form);

                    if (!targetElement || targetElement.Type != Enums.ElementType.Form) {
                        // Datei gehört vermutlich zur sichtbaren OE oder OE-Prüfpunkt
                        fileRequired = true;
                        break;
                    }

                    if (visibleForms.has(targetElement.OID)) {
                        fileRequired = true;
                        break;
                    }
                }

                if (!fileRequired) {
                    unnecessaryMissingFiles.pushUnique(fileInfo);
                    this.sourceFiles.removeByKey(fileInfo.Filename);
                } else {
                    fileInfo.SkipFormCheck = true;
                    this.filesToUpdate.push(fileInfo);
                }
            }

            return unnecessaryMissingFiles;
        }
    }
}
