//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../app/app.session.ts"  />
/// <reference path="./utils.message.ts"  />
//imports-end

module Utils.Http {
    export let ReAuthDeferred = [];

    interface RequestInformation {
        AdditionalHeaders?: Dictionary<string>;
        DataType: Enums.HttpDataType;
        ContentType: string;
        HttpMethod: string;
        URI: string;
        Extra: any;
        CsrfRequired: boolean;
        Timeout?: number;
    }

    // (Standard-) Datentyp für advanced-http-plugin
    const DEFAULT_DATA_TYPE: Enums.HttpDataType = Enums.HttpDataType.JSON;
    // Äquivalenztypen für jQuery $.ajax
    const CONTENT_TYPES: { [key: string]: string } = {};
    CONTENT_TYPES[Enums.HttpDataType.JSON] = 'application/json; charset=utf-8';
    CONTENT_TYPES[Enums.HttpDataType.TEXT] = 'text/plain; charset=utf-8';
    CONTENT_TYPES[Enums.HttpDataType.RAW] = 'application/octet-stream';
    CONTENT_TYPES[Enums.HttpDataType.URL_ENCODED] = 'application/x-www-form-urlencoded';

    export const TIMEOUT_MS_LONG = 10 * 60 * 1000;  // Langes Timeout in Millisekunden    => 10 Minuten
    export const TIMEOUT_MS_MEDIUM = 5 * 60 * 1000; // Mittleres Timeout in Millisekunden => 5 Minute
    export const TIMEOUT_MS_SHORT = 60 * 1000;      // Kurzes Timeout in Millisekunden   => 1 Minute
    export const TIMEOUT_MS_DEFAULT = TIMEOUT_MS_SHORT;      // Default Timeout => Kurzes Timeout

    export function ShowErrorMessage(method: string, uri: string, statusCode: number, errorText, additionalInfo?: string): void {
        if (errorText && errorText.message) {
            errorText = errorText.message;
        }

        Utils.Message.Show(i18next.t('RequestError.MessageHeader'),
            i18next.t('RequestError.MessageBody',
                {
                    Method: method,
                    URI: uri,
                    StatusCode: statusCode,
                    ErrorText: errorText,
                    AdditionalInfo: additionalInfo
                }),
            {
                Close: true
            }, 'error', 9999);
    }

    function buildRequestInfo(httpMethod: Enums.HttpMethod, serviceFunction: string,
        additionalRequestInformation?: Dictionary<any>, dataType?: Enums.HttpDataType, timeout?: number,
        csrfOverride?: boolean, additionalHeaders?: Dictionary<string>): RequestInformation {
        // Datentyp muss gesetzt werden
        dataType = dataType || DEFAULT_DATA_TYPE;

        const requestInformation: RequestInformation = {
            AdditionalHeaders: additionalHeaders,
            HttpMethod: httpMethod,
            URI: encodeURI(Session.BaseURI + serviceFunction),
            DataType: dataType,
            ContentType: CONTENT_TYPES[dataType],
            Extra: null,
            CsrfRequired: csrfOverride == null ? httpMethod !== Enums.HttpMethod.Get : csrfOverride,
            Timeout: timeout
        };

        if (Utils.HasProperties(additionalRequestInformation)) {
            requestInformation.Extra = {};
            for (let attr in additionalRequestInformation) {
                requestInformation.Extra[attr] = additionalRequestInformation[attr];
            }
        }

        return requestInformation;
    }

    function createAjaxRequest(requestInfo: RequestInformation, data?, suppressCredentialChange?: boolean): Deferred {
        return Session.IsSmartDeviceApplication ?
            createAppRequest(requestInfo, data, suppressCredentialChange) :
            createWebRequest(requestInfo, data, suppressCredentialChange);
    }

    function createAppRequest(requestInfo: RequestInformation, data?, suppressCredentialChange?: boolean, skipSSLFix: boolean = false): Deferred {
        const commonTimeout = (requestInfo.Timeout || TIMEOUT_MS_DEFAULT) / 1000;
        const requestOptions: any = {
            method: requestInfo.HttpMethod,
            readTimeout: commonTimeout,
            connectTimeout: commonTimeout,
            timeout: commonTimeout,
            headers: requestInfo.AdditionalHeaders || null
        };

        let allowToCreateANewSession = requestInfo.URI.indexOf('/login') === -1;

        function fixResponse(response) {
            requestInfo['type'] = requestInfo.HttpMethod;
            response.type = requestInfo.HttpMethod;

            if (response.error && !response.responseText) {
                response.responseText = response.error;
            }
        }

        const requestDeferred = $.Deferred();

        function doSafeRequest(): void {
            // daten Raw ausgeben lassen als Uint8Array
            requestOptions.responseType = 'arraybuffer';

            cordova.plugin.http.sendRequest(
                requestInfo.URI,
                requestOptions,
                function(response) {
                    fixResponse(response);

                    // versuche zu Text & JSON zu konvertieren
                    try {
                        const data = !!response.data ? JSON.parse(new TextDecoder().decode(response.data as ArrayBuffer)) : null;
                        delete response.data;
                        response.data = data;

                        requestDeferred.resolveWith(requestInfo, [data, null, response]);
                    } catch (err) {
                        // arraybuffer in Datei speichern
                        const targetLogFile = `${new Date().getTime()}.bin`;
                        WriteLogFile(targetLogFile, response.data)
                            .always(() => {
                                response.logFile = targetLogFile;
                                requestDeferred.rejectWith(requestInfo, [response, "error", response.error]);
                            });
                    }
                },
                function(response) {
                    /*
                     Nur ausführen, wenn nicht explizit für den Requests deaktiviert (bsp. Login / Zugangsdaten ändern),
                     es sich um die Webanwendung handelt und bereits ein Benutzer angemeldet ist
                    */
                    if (!suppressCredentialChange && Session.User) {
                        if (Utils.IsSet(response) && response.status === Enums.HttpStatusCode.Unauthorized) {
                            changeUserCredentials(requestInfo, data, response)
                                .then(() => {
                                    // neuversuch starten
                                    createAppRequest(requestInfo, data, suppressCredentialChange, skipSSLFix)
                                        .then(requestDeferred.resolve, requestDeferred.reject);
                                }, () => {
                                    requestDeferred.rejectWith(requestInfo, [response, "error", response.error]);
                                });
                            return;
                        }
                    } else if (Session.LastKnownAPIVersion >= 21 || !Session.IsSmartDeviceApplication) {
                        if (Utils.IsSet(response) && response.status === Enums.HttpStatusCode.Unauthorized) {
                            if (allowToCreateANewSession) {
                                allowToCreateANewSession = false;

                                startUserSession()
                                    .then(doSafeRequest, (sessionResponse) => {
                                        return requestDeferred.reject(sessionResponse || response, null, { type: requestInfo.HttpMethod })
                                    });

                                return;
                            }
                        }
                    }

                    fixResponse(response);

                    requestDeferred.rejectWith(requestInfo, [response, "error", response.error]);
                }
            );
        }

        function doRequest(): void {
            cordova.plugin.http.sendRequest(
                requestInfo.URI,
                requestOptions,
                function(response) {
                    const data = !!response.data ? JSON.parse(response.data) : null;

                    delete response.data;
                    response.data = data;

                    fixResponse(response);

                    requestDeferred.resolveWith(requestInfo, [data, null, response]);
                },
                function(response) {
                    /*
                     Nur ausführen, wenn nicht explizit für den Requests deaktiviert (bsp. Login / Zugangsdaten ändern),
                     es sich um die Webanwendung handelt und bereits ein Benutzer angemeldet ist
                    */
                    if (!suppressCredentialChange && Session.User) {
                        if (Utils.IsSet(response) && response.status === Enums.HttpStatusCode.Unauthorized) {
                            changeUserCredentials(requestInfo, data, response)
                                .then(() => {
                                    // neuversuch starten
                                    createAppRequest(requestInfo, data, suppressCredentialChange, skipSSLFix)
                                        .then(requestDeferred.resolve, requestDeferred.reject);
                                }, () => {
                                    requestDeferred.rejectWith(requestInfo, [response, "error", response.error]);
                                });
                            return;
                        }
                    } else if (Session.LastKnownAPIVersion >= 21 || !Session.IsSmartDeviceApplication) {
                        if (Utils.IsSet(response) && response.status === Enums.HttpStatusCode.Unauthorized) {
                            if (allowToCreateANewSession) {
                                allowToCreateANewSession = false;

                                startUserSession()
                                    .then(doRequest, (sessionResponse) => {
                                        return requestDeferred.reject(sessionResponse || response, null, { type: requestInfo.HttpMethod })
                                    });

                                return;
                            }
                        }
                    }

                    // erfolgreicher Response, mit ungültigem Content behandeln
                    if (response.status == 200 ||
                        (response.error && response.error.indexOf('invalid or unknown charset encoding') >= 0)) {
                        // neuen Request mit anderen Optionen ausführen
                        doSafeRequest();
                        return;
                    }

                    fixResponse(response);

                    requestDeferred.rejectWith(requestInfo, [response, "error", response.error]);
                }
            );
        }

        const resultDeferred = Session.IsRunningOnAndroid && !skipSSLFix ?
            // SSL Fix für Android <= 7.0 durch mitgeliefertes SSL Zertifikat
            androidTLSHandlerFix(requestDeferred, requestInfo, data, suppressCredentialChange) :
            requestDeferred;

        if (data) {
            if (requestInfo.DataType === Enums.HttpDataType.JSON) {
                if (typeof data === 'string') {
                    try {
                        requestOptions.data = JSON.parse(data);
                    } catch (e) {
                        requestOptions.data = null;
                    }
                } else {
                    requestOptions.data = data;
                }
            } else {
                requestOptions.serializer = requestInfo.DataType;
                requestOptions.data = data;
            }
        }

        if (Session.IsSmartDeviceApplication && Session.LastKnownAPIVersion < 21) {
            requestOptions.headers = {
                Authorization: `Basic ${Session.AuthHash}`
            };

            doRequest();

            return resultDeferred.promise();
        }

        // --- Session Cookie Bereich ab hier ------------

        function setCsrfToken() {
            if (requestInfo.CsrfRequired) {
                requestOptions.headers = {
                    'X-Csrf-Token': Utils.Cookies.GetCSRFToken()
                };
            }
        }

        refreshSessionIfNeeded(requestInfo.CsrfRequired)
            .then(setCsrfToken)
            .then(doRequest, resultDeferred.reject);

        return resultDeferred.promise();
    }

    function androidTLSHandlerFix(deferred: Deferred, requestInfo: RequestInformation, data?: any, suppressCredentialChange?: boolean): Deferred {
        if (!deferred) {
            return $.Deferred().reject();
        }

        if (!Session.IsRunningOnAndroid) {
            return deferred;
        }

        const result = $.Deferred();

        deferred.then(null, function(...response1: any[]) {
            const firstTryError = response1[0].error;

            if (firstTryError &&
                (firstTryError.indexOf('SSLHandshakeException') >= 0 || firstTryError.indexOf('TLS') >= 0)) {
                const trustModeDeferred = $.Deferred();

                // mitgeliefertes ISRG Zertifikat verwenden
                cordova.plugin.http.setServerTrustMode(
                    'pinned',
                    trustModeDeferred.resolve,
                    trustModeDeferred.reject
                );

                return trustModeDeferred
                    .then(() => {
                        // Anfrage wiederholen
                        return createAppRequest(requestInfo, data, suppressCredentialChange, true);
                    }, () => $.Deferred().rejectWith(this, response1))
                    .then(null, (...response2: any[]) => {
                        const retryError = response2[0].error;

                        if (retryError &&
                            (retryError.indexOf('SSLHandshakeException') >= 0 || retryError.indexOf('TLS') >= 0)) {
                            const trustModeDeferred = $.Deferred();

                            // wenn keine Besserung, auf den vorherigen Modus umstellen
                            cordova.plugin.http.setServerTrustMode(
                                'default',
                                trustModeDeferred.resolve,
                                trustModeDeferred.resolve
                            );

                            return trustModeDeferred.then(() => $.Deferred().rejectWith(this, response1));
                        }

                        return $.Deferred().rejectWith(this, response2);
                    });
            }

            return $.Deferred().rejectWith(this, response1);
        }).then(function(...args: any[]) {
            result.resolveWith(this, args);
        }, function(...args: any[]) {
            result.rejectWith(this, args);
        });

        return result;
    }

    function startUserSession(): Deferred {
        if (Session.IsSmartDeviceApplication && (Session.LastKnownAPIVersion < 21 || !Session.AuthHash)) {
            return $.Deferred().reject().promise();
        }

        const credentials = Session.AuthHash.fromBase64().split(':');

        const loginData: Model.LoginData = {
            Username: credentials[0],
            Password: credentials[1],
            Remember: true,
            Application: 'App'
        };

        return Utils.Http.Post('login', loginData, null, true, null, TIMEOUT_MS_SHORT, false)
            .then((result, status, xhr) => {
                Session.SetCookieConfig(xhr);
            }, (xhr, status) => {
                return $.Deferred().reject(xhr, status, { type: Enums.HttpMethod.Post });
            });
    }

    function createWebRequest(requestInfo: RequestInformation, data?, suppressCredentialChange?: boolean): Deferred {
        const deferred = $.Deferred();

        function doRequest() {
            $.ajax({
                type: requestInfo.HttpMethod,
                url: requestInfo.URI,
                contentType: requestInfo.ContentType,
                dataType: requestInfo.DataType,
                async: true,
                timeout: requestInfo.Timeout || TIMEOUT_MS_DEFAULT,
                headers: createWebHeader(requestInfo),
                beforeSend: function(xhr) {
                    xhr.RequestInformation = requestInfo.Extra;
                },
                xhrFields: {
                    withCredentials: true
                },
                data: (data && typeof data !== 'string') ? JSON.stringify(data) : data || null
            }).then(deferred.resolve, function(xhr) {
                /*
                 Nur ausführen, wenn nicht explizit für den Requests deaktiviert (bsp. Login / Zugangsdaten ändern),
                 es sich um die Webanwendung handelt und bereits ein Benutzer angemeldet ist
                */
                if (!suppressCredentialChange && Session.User) {
                    if (Utils.IsSet(xhr) && xhr.status === Enums.HttpStatusCode.Unauthorized) {
                        return changeUserCredentials(requestInfo, data, xhr.responseJSON);
                    }
                }

                return deferred.rejectWith(this, [xhr, "error", xhr.statusText]);
            });
        }

        refreshSessionIfNeeded(requestInfo.CsrfRequired)
            .then(doRequest, deferred.reject);

        return deferred.promise();
    }

    function refreshSessionIfNeeded(csrfRequired: boolean): Deferred {
        if (Session.LastKnownAPIVersion < 21) {
            return $.Deferred().resolve().promise();
        }

        if (!csrfRequired || Utils.Cookies.IsCsrfTokenValid()) {
            return $.Deferred().resolve().promise();
        }

        return Utils.Http.Get('heartbeat')
            .then((result: string, status: string, xhr: any) => {
                Session.SetCookieConfig(xhr);
            });
    }

    function createWebHeader(requestInfo: RequestInformation): Dictionary<string> {
        if (requestInfo.AdditionalHeaders) {
            if (!requestInfo.CsrfRequired) {
                return requestInfo.AdditionalHeaders;
            }

            const tmpHeaders = Utils.Clone(requestInfo.AdditionalHeaders);
            tmpHeaders['X-Csrf-Token'] = Utils.Cookies.GetCSRFToken();

            return tmpHeaders;
        }

        return requestInfo.CsrfRequired
            ? { 'X-Csrf-Token': Utils.Cookies.GetCSRFToken() }
            : {};
    }

    /**
     * Fordert den Benutzer auf die aktualisierten Zugangsdaten zu hinterlegen und führt den Request erneut aus
     * @param requestInfo Die RequestInfos des eigentlichen Requests
     * @param data Optionale Daten die mitgeschickt werden sollen
     * @param response Der Response des Unauthorized Requests als JSON
     * @private
     */
    function changeUserCredentials(requestInfo: RequestInformation, data, response): Deferred {
        const deferred = $.Deferred();
        let resultDeferred = deferred;

        if (requestInfo) {
            resultDeferred = deferred.then(() => createAjaxRequest(requestInfo, data));
        }

        // Es wird bereits der Dialog angezeigt
        if (ReAuthDeferred.length) {
            ReAuthDeferred.push(deferred);

            return resultDeferred.promise();
        }

        //Inhalt des Dialogs abhängig von der Response anzeigen
        let title: string;

        if (!!response) {
            switch (response.UnauthorizedType) {
                case 0:
                    title = i18next.t('Misc.Unauthorized.LoginInvalid');
                    break;
                case 1:
                    title = i18next.t('Misc.Unauthorized.CsrfTokenInvalid');
                    break;
                case 2:
                    title = i18next.t('Misc.Unauthorized.SessionUnknown');
                    break;
                case 3:
                    title = i18next.t('Misc.Unauthorized.DeserializeError');
                    break;
                case 4:
                    title = i18next.t('Misc.Unauthorized.CookieFormatError');
                    break;
                case 5:
                    title = i18next.t('Misc.Unauthorized.ValidationError');
                    break;
                default:
                    title = i18next.t('Misc.Unauthorized.TextDefault');
                    break;
            }

            title += i18next.t('Misc.Unauthorized.newLogin');

        } else {
            title = i18next.t('Misc.Unauthorized.newLogin');
        }

        //Dialog anzeigen
        Utils.CredentialEditor.Show(title, true, () => {
            const tmpDeferreds = [].concat(ReAuthDeferred);
            ReAuthDeferred = [];

            // Alle gespeicherten requests ausführen
            for (let tmpDeferred of tmpDeferreds) {
                tmpDeferred.resolve();
            }
        });

        ReAuthDeferred.push(deferred);

        return resultDeferred.promise();
    }

    export function GetAuthHeaders(): Dictionary<string> {
        if (Session.LastKnownAPIVersion >= 21 || !Session.IsSmartDeviceApplication) {
            // session supported authentication
            return {
                'X-Csrf-Token': Utils.Cookies.GetCSRFToken()
            };
        }

        // (legacy) basic authentication
        return {
            Authorization: `Basic ${Session.AuthHash}`
        };
    }

    /**
     * Führt ein HTTP GET auf [BaseURI + serviceFunction]
     * @param serviceFunction Relativer Pfad auf die Service Funktion
     * @param [data] Das zu sendende Datenobjekt, wird bei Bedarf zu JSON serialisiert
     * @param [additionalRequestInformation] Zusätzliche Informationen zum Request
     * @param [suppressCredentialChange] Unterdrückt den Zugangsdaten Anforderung-Dialog wenn gesetzt.
     * @param [dataType] Format des 'content-type', standardmäßig JSON
     * @param [timeout] Zeit nach der der Request ohne Antwort abgebrochen werden soll. Standard: TIMEOUT_MS_DEFAULT
     * @param [csrfOverride] Legt fest ob ein CSRF benötigt wird. Standard: false
     * @return Ein Ajax Deferred
     */
    export function Get(serviceFunction: string,
        additionalRequestInformation?: Dictionary<any>,
        suppressCredentialChange?: boolean,
        dataType?: Enums.HttpDataType,
        timeout?: number,
        additionalHeaders?: Dictionary<string>): Deferred {
        const requestInfo = buildRequestInfo(
            Enums.HttpMethod.Get,
            serviceFunction,
            additionalRequestInformation,
            dataType,
            timeout,
            false,
            additionalHeaders);

        return createAjaxRequest(requestInfo, null, suppressCredentialChange);
    }

    /**
     * Führt ein HTTP PUT auf [BaseURI + serviceFunction]
     * @param serviceFunction Relativer Pfad auf die Service Funktion
     * @param [data] Das zu sendende Datenobjekt, wird bei Bedarf zu JSON serialisiert
     * @param [additionalRequestInformation] Zusätzliche Informationen zum Request
     * @param [dataType] Format des 'content-type', standardmäßig JSON
     * @param [timeout] Zeit nach der der Request ohne Antwort abgebrochen werden soll. Standard: TIMEOUT_MS_DEFAULT
     * @param [csrfOverride] Legt fest ob ein CSRF benötigt wird. Standard: false
     * @return Ein Ajax Deferred
     */
    export function Put(serviceFunction: string,
        data: any,
        additionalRequestInformation?: Dictionary<any>,
        dataType?: Enums.HttpDataType,
        timeout?: number,
        csrfOverride: boolean = null): Deferred {
        const requestInfo = buildRequestInfo(
            Enums.HttpMethod.Put,
            serviceFunction,
            additionalRequestInformation,
            dataType,
            timeout,
            csrfOverride);

        return createAjaxRequest(requestInfo, data);
    }

    /**
     * Führt ein HTTP POST auf [BaseURI + serviceFunction]
     * @param serviceFunction Relativer Pfad auf die Service Funktion
     * @param [data] Das zu sendende Datenobjekt, wird bei Bedarf zu JSON serialisiert
     * @param [additionalRequestInformation] Zusätzliche Informationen zum Request
     * @param [suppressCredentialChange] Unterdrückt den Zugangsdaten Anforderung-Dialog wenn gesetzt.
     * @param [dataType] Format des 'content-type', standardmäßig JSON
     * @param [timeout] Zeit nach der der Request ohne Antwort abgebrochen werden soll. Standard: TIMEOUT_MS_DEFAULT
     * @param [csrfOverride] Legt fest ob ein CSRF benötigt wird. Standard: false
     * @return Ein Ajax Deferred
     */
    export function Post(serviceFunction: string,
        data: any,
        additionalRequestInformation?: Dictionary<any>,
        suppressCredentialChange?: boolean,
        dataType?: Enums.HttpDataType,
        timeout?: number,
        csrfOverride: boolean = null): Deferred {
        const requestInfo = buildRequestInfo(
            Enums.HttpMethod.Post,
            serviceFunction,
            additionalRequestInformation,
            dataType,
            timeout,
            csrfOverride);

        return createAjaxRequest(requestInfo, data, suppressCredentialChange);
    }

    /**
     * Führt ein HTTP DELETE auf [BaseURI + serviceFunction]
     * @param serviceFunction Relativer Pfad auf die Service Funktion
     * @param [data] Das zu sendende Datenobjekt, wird bei Bedarf zu JSON serialisiert
     * @param [additionalRequestInformation] Zusätzliche Informationen zum Request
     * @param [dataType] Format des 'content-type', standardmäßig JSON
     * @param [timeout] Zeit nach der der Request ohne Antwort abgebrochen werden soll. Standard: TIMEOUT_MS_DEFAULT
     * @param [csrfOverride] Legt fest ob ein CSRF benötigt wird. Standard: false
     * @return Ein Ajax Deferred
     */
    export function Delete(serviceFunction: string,
        data?: any,
        additionalRequestInformation?: Dictionary<any>,
        dataType?: Enums.HttpDataType,
        timeout?: number,
        csrfOverride: boolean = null): Deferred {
        const requestInfo = buildRequestInfo(
            Enums.HttpMethod.Delete,
            serviceFunction,
            additionalRequestInformation,
            dataType,
            timeout,
            csrfOverride);

        return createAjaxRequest(requestInfo, data);
    }

    export function UploadFile(serviceFunction: string, fullFilePath: string, filename?: string): Deferred {
        if (!serviceFunction || !fullFilePath) {
            return $.Deferred().resolve();
        }

        if (!Session.IsSmartDeviceApplication) {
            throw new Error('Bad use of UploadFile: SmartDeviceApplication only!');
        }

        return refreshSessionIfNeeded(true)
            .then(() => {
                const resultDeferred: Deferred = $.Deferred();
                const uploadHeaders = Utils.Http.GetAuthHeaders();
                const timeoutBackup = cordova.plugin.http.getRequestTimeout();

                filename = filename || getFilenameFromPath(fullFilePath);

                // Timeout für Dateiupload global erhöhen und anschließend wieder zurücksetzen
                // (Timeout kann nicht anderweitig an uploadFile übergeben werden)
                cordova.plugin.http.setRequestTimeout(Utils.Http.TIMEOUT_MS_MEDIUM / 1000);

                // Datei Upload initieren
                cordova.plugin.http.uploadFile(serviceFunction, {
                    fileName: filename
                }, uploadHeaders, fullFilePath, filename, (response) => {
                    resultDeferred.resolve(response);
                }, (response) => {
                    // response um zusätzliche Parameter ergänzen
                    response.url = serviceFunction;
                    response.file = filename;
                    response.type = Enums.HttpMethod.Post;

                    resultDeferred.reject(response);
                });

                // Timeout nach Request (direkt) zurücksetzen
                cordova.plugin.http.setRequestTimeout(timeoutBackup);

                return resultDeferred;
            });
    }

    export function DownloadFile(filename: string, downloadOriginalSize?: boolean): Deferred {
        if (!Session.IsSmartDeviceApplication) {
            throw new Error('Bad use of DownloadFile: SmartDeviceApplication only!');
        }

        const serviceURI = /\.(jpg|jpeg|png|gif|tiff|svg)$/.test(filename)
            ? (downloadOriginalSize ? 'images/' : 'images/l/')
            : 'files/';

        const remoteEndpoint = Session.BaseURI + serviceURI + filename;
        const localFilePath = Utils.GetResourcesPath() + filename;
        const downloadHeaders = Utils.Http.GetAuthHeaders();
        const deferred = $.Deferred();

        cordova.plugin.http.downloadFile(
            remoteEndpoint,
            null,
            downloadHeaders,
            localFilePath,
            () => {
                const fileDefer = window.Database.DeleteFromStorage(
                    Enums.DatabaseStorage.MissingFiles,
                    filename
                );

                fileDefer.always(() => deferred.resolve(filename));
            },
            (response: { error: string, status: number, type?: string }) => {
                // Type ergänzen, wird nicht bereitgestellt
                response.type = 'GET';
                deferred.reject(filename, response);
            }
        );

        return deferred.promise();
    }

    export function LoadImage(url: string): Deferred {
        const deferred = $.Deferred();

        if (!url || !Session.IsSmartDeviceApplication) {
            return deferred.resolve().promise();
        }

        const commonTimeout = TIMEOUT_MS_MEDIUM / 1000;
        const requestOption = {
            method: 'GET',
            responseType: 'blob',
            headers: {
                'X-Csrf-Token': Utils.Cookies.GetCSRFToken()
            },
            readTimeout: commonTimeout,
            connectTimeout: commonTimeout,
            timeout: commonTimeout
        };

        cordova.plugin.http.sendRequest(url, requestOption, (response) => {
            onAfterGotImageBlobFromServer(response.data)
                .then((base64: string) => {
                    deferred.resolve(base64);
                });
        }, deferred.reject);

        return deferred.promise();
    }

    function getFilenameFromPath(path: string): string {
        const splitted = path.split('/');

        return splitted[splitted.length - 1];
    }

    function onAfterGotImageBlobFromServer(data: Blob): Deferred {
        const deferred = $.Deferred();

        if (!data) {
            return deferred.resolve().promise();
        }

        const reader = new FileReader();

        reader.onloadend = () => {
            deferred.resolve(reader.result);
        };

        reader.onerror = deferred.reject;

        reader.readAsDataURL(data);

        return deferred.promise();
    }

    export function GetResponseHeader(httpResponse, headerKey: string, defaultValue?: string): string {
        if (!httpResponse) {
            return defaultValue;
        }

        if (!(httpResponse.getResponseHeader instanceof Function)) {
            if (!httpResponse.headers) {
                return defaultValue;
            }

            const header = httpResponse.headers[headerKey] || httpResponse.headers[headerKey.toLowerCase()];

            return header || defaultValue;
        }

        return httpResponse.getResponseHeader(headerKey) || defaultValue;
    }

    /**
     * Unauthorized kann beim Logout ignoriert werden
     * @param param
     * @constructor
     */
    export function HandleLogoutError(...param: any[]) {
        const deferred = $.Deferred();
        const xhr = param[0];

        if (Utils.IsSet(xhr) && xhr.status === Enums.HttpStatusCode.Unauthorized) {
            return $.Deferred().resolve().promise();
        }

        // Erweitere Fehlermeldung um diesen request
        param.push(this);
        return deferred.rejectWith(this, param);
    }
}
