//imports-start
/// <reference path="../definitions.d.ts" />
//imports-end

module Utils {
    export function GetActiveUserRights(userOID: string, locationDependant?: boolean, location?: Model.Elements.Element | string): Dictionary<any> {
        if (locationDependant) {
            location = (location || Session.CurrentLocation);

            if (typeof location === 'string' && !DAL.Elements.Exists(location)) {
                return null;
            } else if (!location) {
                return null;
            }
        } else {
            location = null;
        }

        // try get rights from cache
        let rightsDict = DAL.Cache.Rights.GetUserRights(userOID, location);
        if (!rightsDict) {
            // no cache yet, create one
            if (locationDependant) {
                if (typeof location === 'string') {
                    location = DAL.Elements.GetByOID(location);
                }

                if (location && location.Type != Enums.ElementType.Root &&
                    location.Type != Enums.ElementType.Location) {
                    const currentIssue = IssueView.GetCurrentIssue();
                    if (currentIssue) {
                        // check for changed location
                        console.log('Changing location for right validation!');
                        return GetActiveUserRights(userOID, locationDependant, DAL.Elements.GetByOID(currentIssue.AssignedElementOID));
                    }
                }
            } else {
                location = null;
            }

            // create dictionary from array
            const rights = DAL.Roles.GetRightsByUserOID(userOID, <Model.Elements.Element>location);
            if (rights) {
                rightsDict = {};
                for (let i = 0; i < rights.length; i++) {
                    const left = rights[i];
                    rightsDict[left.ID] = left.Value;
                }
            }
            // put rights in cache
            DAL.Cache.Rights.SetUserRights(userOID, <any>location, rightsDict);
        }

        return rightsDict;
    }

    export function UserHasRight(userOID: string, value: Enums.Rights, locationDependant?: boolean, location?: Model.Elements.Element | string): boolean {
        if (!!locationDependant && !Utils.IsSet(location)) {
            location = Session.CurrentLocation;
        }

        const rights = GetActiveUserRights(userOID, locationDependant, location);
        if (!rights) {
            return false;
        }

        // warning if 'UserHasIssueRight' should have been called instead
        if (value.indexOf('TMFT-') >= 0) {
            console.warn(`Wrong method used for user right '${value}' check!`);
        }

        return rights[value] ? true : false;
    }

    export function CanUserSeeLocation(userOID: string, location: Model.Elements.Element | string): boolean {
        return GetActiveUserRights(userOID, true, location) != null;
    }

    export function GetUserRoles(locationOID: string): Array<string> {
        if (!(locationOID || Session.CurrentLocation)) {
            return;
        }

        let location = DAL.Elements.GetByOID(locationOID || Session.CurrentLocation.OID);
        const roles: string[] = [];

        function iterateTeams(locationTeam: Model.Teams.Team) {
            const team = DAL.Teams.GetByOID(locationTeam.OID);
            if (!team || !(team.Users || []).length) {
                return;
            }

            const teamMember = Utils.Where(team.Users, 'OID', '===', Session.User.OID);
            if (!teamMember || !teamMember.Roles || !teamMember.Roles.length) {
                return;
            }

            teamMember.Roles.forEach(function(roleIdentifier: string) {
                if (!Utils.InArray(roles, roleIdentifier)) {
                    roles.push(roleIdentifier);
                }
            });
        }

        while (location) {
            if (location.Teams) {
                location.Teams.forEach(iterateTeams);
            }

            location = location.Parent;
        }

        return roles;
    }

    export function GetAllUserRoles(): Array<string> {
        const roles = [];

        if (!Session.User || !(Session.User.Teams || []).length) {
            return;
        }

        Session.User.Teams.forEach(function(teamIdent: string) {
            let team = DAL.Teams.GetByOID(teamIdent);

            if (!team || !(team.Users || []).length) {
                return;
            }

            let teamMember = Utils.Where(team.Users, 'OID', '===', Session.User.OID);
            if (!teamMember || !(teamMember.Roles || []).length) {
                return;
            }

            teamMember.Roles.forEach(function(roleIdentifier) {
                if (!Utils.InArray(roles, roleIdentifier)) {
                    roles.push(roleIdentifier);
                }
            });
        });

        return roles.length ? roles : null;
    }

    export function IsLicenseAvailable(license: string, isNullable: boolean = true): boolean {
        if (!license ||
            !Session.Client ||
            !Session.Client.Licenses) {
            return false;
        }

        const value = Session.Client.Licenses[license];

        if (typeof value === 'undefined' || value === null) {
            return isNullable;
        }

        return value > 0 || value === true;
    }

    function getRightForIssueType(issueType: Enums.IssueType): Enums.Rights {
        switch (issueType) {
            case Enums.IssueType.Task:
                return Enums.Rights.Issues_CreateOrModifyTasks;
            case Enums.IssueType.Scheduling:
                return Enums.Rights.Issues_CreateOrModifySchedulings;
            case Enums.IssueType.Form:
            case Enums.IssueType.Survey:
                return Enums.Rights.Issues_CreateOrModifyForms;
            case Enums.IssueType.Note:
                return Enums.Rights.Issues_CreateOrModifyNotes;
            case Enums.IssueType.Disturbance:
                return Enums.Rights.Issues_CreateOrModifyDisturbances;
            case Enums.IssueType.Inspection:
                return Enums.Rights.Issues_CreateOrModifyInspections;
            case Enums.IssueType.Investigation:
                return Enums.Rights.Issues_CreateOrModifyInvestigations;
            default:
                return null;
        }
    }

    export function CanUserCreateIssueType(issueType: Enums.IssueType, location: Model.Elements.Element | string, rightsCache?: Dictionary<any>): boolean {
        if (!rightsCache) {
            if (View.CurrentView === Enums.View.Scheduling) {
                if (typeof location === 'string') {
                    location = ParameterList.GetElementRevisionByOID(location);
                } else if (location) {
                    location = ParameterList.GetElementRevisionByRevisionOID(location.RevisionOID);
                }
            }

            if (!location) {
                return false;
            }

            rightsCache = GetActiveUserRights(Session.User.OID, true, location);
        }

        if (!rightsCache) {
            return false;
        }

        const canCreateOrModify = rightsCache[Enums.Rights.Issues_CreateOrModifyIssues];
        if (!canCreateOrModify) {
            return false;
        }

        // no further rights required, before api v4
        if (Session.LastKnownAPIVersion < 4) {
            return true;
        }

        const right = getRightForIssueType(issueType);
        if (!right) {
            return false;
        }

        // check all variants of the issue rights
        return rightsCache[right] || rightsCache[right + '-SC'] || rightsCache[right + '-A'];
    }

    export function CanUserModifyIssue(issue: Model.Issues.Issue, rightsCache?: Dictionary<any>): boolean {
        const right = getRightForIssueType(issue.Type);
        return UserHasIssueRight(issue, right, rightsCache);
    }

    export function UserHasIssueRight(issue: Model.Issues.Issue | Model.Issues.RawIssue, right: Enums.Rights, rightsCache?: Dictionary<any>): boolean
    export function UserHasIssueRight(locationOID: string, right: Enums.Rights, rightsCache?: Dictionary<any>): boolean
    export function UserHasIssueRight(issue_location: Model.Issues.Issue | Model.Issues.RawIssue | string, right: Enums.Rights, rightsCache?: Dictionary<any>): boolean {
        if (!issue_location) {
            return false;
        }

        const locationOID = typeof issue_location == 'string' ? issue_location : issue_location.AssignedElementOID;
        const issue = typeof issue_location != 'string' ? issue_location : null;

        if (!rightsCache) {
            let location: Model.Elements.Element;
            if (View.CurrentView === Enums.View.Scheduling) {
                location = ParameterList.GetElementRevisionByOID(locationOID);
            } else {
                location = DAL.Elements.GetByOID(locationOID);
            }

            if (!location) {
                return false;
            }

            rightsCache = GetActiveUserRights(Session.User.OID, true, location);
        }

        if (!rightsCache) {
            return false;
        }

        const canCreateOrModify = rightsCache[Enums.Rights.Issues_CreateOrModifyIssues];
        if (!canCreateOrModify || right === Enums.Rights.Issues_CreateOrModifyIssues) {
            return canCreateOrModify;
        }

        // no further rights required, before api v4
        if (Session.LastKnownAPIVersion < 4) {
            return true;
        }

        // TODO check with new created issues and limited rights

        // check if issue is 'self-created' or 'assigned'
        if (!right) {
            return false;
        } else if (rightsCache[right]) {
            return true;
        } else if (!issue && (rightsCache[right + '-SC'] || rightsCache[right + '-A'])) {
            // allow create issues
            return true;
        } else if (issue && typeof (Session) != 'undefined' && Session.User) {
            // check api version to skip unnecessary rights checking
            if (issue.CreatorOID == Session.User.OID && rightsCache[right + '-SC']) {
                // self created issues
                return true;
            } else if (rightsCache[right + '-A']) {
                // assigned issues
                const userIsAssignedToIssue =
                    Utils.InArray(issue.Users, Session.User.OID) ||
                    Utils.HasIntersection(issue.Teams, Session.User.Teams);

                if (userIsAssignedToIssue) {
                    return true;
                }
            }
        }

        return false;
    }

    export function IsUserAbleToSetInitialState(locationOID: string, stateOID: string, userRoles?: Array<string>): boolean {
        if (!location || !stateOID) {
            return false;
        }

        userRoles = userRoles || Utils.GetUserRoles(locationOID);

        return DAL.Properties.IsUserAllowedToSetStateByRoles(userRoles, stateOID, true, true);
    }
}
