//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../model/roles/role.ts"  />
/// <reference path="../utils/utils.ordered-dictionary.ts"  />
//imports-end

module DAL.Roles {
    let _roles: Dictionary<Model.Roles.Role>;

    function prepareRole(role: Model.Roles.Role): Model.Roles.Role {
        role.Title = Utils.EscapeHTMLEntities(role.Title);

        if (!!role.Description) {
            role.Description = Utils.EscapeHTMLEntities(role.Description);
        }

        return role;
    }

    export function Store(syncedRoles: Array<Model.Roles.Role>): void {
        if (!_roles) {
            _roles = {};
        }

        if (!(syncedRoles || []).length) {
            return;
        }

        for (let rCnt = 0, rLen = syncedRoles.length; rCnt < rLen; rCnt++) {
            const role = prepareRole(syncedRoles[rCnt]);

            if (!role.Deleted) {
                _roles[role.OID] = role;
            } else {
                delete _roles[role.OID];
            }
        }
    }

    export function Exists(oid: string): boolean {
        return _roles && !!oid && _roles.hasOwnProperty(oid);
    }

    export function GetByOID(oid: string): Model.Roles.Role | null {
        if (!Exists(oid)) {
            return null;
        }

        return _roles[oid];
    }

    export function GetRightsByUserOID(userIdentifier: string, location?: Model.Elements.Element): Model.Roles.Right[] | null {
        let teams = DAL.Teams.GetByUserOID(userIdentifier);

        if (location) {
            teams = filterTeamsByLocation(teams, location);
        }

        if (!(teams || []).length) {
            return null;
        }

        const rights = getRightsFromTeams(teams, userIdentifier);

        return rights && rights.length ? rights : null;
    }

    function filterTeamsByLocation(teams: Model.Teams.Team[], location: Model.Elements.Element): Model.Teams.Team[] | null {
        if (!(teams || []).length || !location) {
            return;
        }

        const tmpTeams = [];

        while (location) {
            (location.Teams || []).forEach((team: Model.Teams.Team) => {
                if (Utils.InArray(tmpTeams, team.OID)) {
                    return;
                }

                tmpTeams.push(team.OID);
            });

            location = location.Parent;
        }

        if (!tmpTeams.length) {
            return;
        }

        for (let tCnt = teams.length - 1; tCnt >= 0; tCnt--) {
            if (!Utils.InArray(tmpTeams, teams[tCnt].OID)) {
                teams.splice(tCnt, 1);
            }
        }

        return teams.length ? teams : null;
    }

    function getRightsFromTeams(teams: Model.Teams.Team[], userIdentifier: string): Array<Model.Roles.Right> | null {
        if (!(teams || []).length ||
            !userIdentifier) {
            return null;
        }

        const indexedRights = new Utils.OrderedDictionary<Model.Roles.Right>(right => right.ID);

        for (const team of teams) {
            if (!(team.Users || []).length) {
                continue;
            }

            for (const teamMember of team.Users) {
                if (teamMember.OID !== userIdentifier ||
                    !(teamMember.Roles || []).length) {
                    continue;
                }

                for (const identifier of teamMember.Roles) {
                    const role = GetByOID(identifier);

                    if (!role || !(role.Rights || []).length) {
                        continue;
                    }

                    indexedRights.putRangeUnique(role.Rights, undefined, false);
                }
            }
        }

        return indexedRights.toArray();
    }

    export function Clear(): void {
        _roles = null;
    }
}
