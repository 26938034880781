//imports-start
/// <reference path="../definitions.d.ts" />
/// <reference path="../app/app.code-scanner.ts" />
/// <reference path="./utils.cloning.ts" />
/// <reference path="./utils.filtering.ts" />
/// <reference path="./utils.rights-n-roles.ts" />
/// <reference path="./utils.sorting.ts" />
/// <reference path="./utils.filtering.ts" />
/// <reference path="../enums.ts" />
/// <reference path="./utils.file-helper.ts" />
//imports-end

module Utils {
    export function IsSet(obj): boolean {
        return typeof obj !== 'undefined' && obj !== null;
    }

    export function IsFunction(obj): boolean {
        return Utils.IsSet(obj) && (obj instanceof Function);
    }

    export function IsArray(obj): boolean {
        return Utils.IsSet(obj) && obj instanceof Array;
    }

    export function ActionToString(action): string {
        let strAction;

        switch (action.Type) {
            case Enums.CheckpointWorkflowType.Notification:
                strAction = [];
                if ((action.Users || []).length) {
                    strAction.push(`<u>${i18next.t('CorrectiveActions.MailTo')}</u>`);
                    strAction.push(i18next.t('Misc.User_plural') + ': ' + $.map(action.Users, function(oid) {
                        if (DAL.Users.Exists(oid)) {
                            return DAL.Users.GetByOID(oid).Title;
                        }
                    }).sort(Utils.SortByString).join(', '));
                }

                if ((action.Teams || []).length) {
                    strAction.push(i18next.t('Misc.Team_plural') + ': ' + $.map(action.Teams, function(oid) {
                        if (DAL.Teams.Exists(oid)) {
                            return DAL.Teams.GetByOID(oid).Title;
                        }
                    }).sort(Utils.SortByString).join(', '));
                }

                if ((action.Contacts || []).length) {
                    strAction.push(i18next.t('Misc.Contact_plural') + ': ' + $.map(action.Contacts, function(oid) {
                        if (DAL.Contacts.Exists(oid)) {
                            return DAL.Contacts.GetByOID(oid).Title;
                        }
                    }).sort(Utils.SortByString).join(', '));
                }

                if ((action.ContactGroups || []).length) {
                    strAction.push(i18next.t('Misc.ContactGroup_plural') + ': ' + $.map(action.ContactGroups, function(oid) {
                        if (DAL.ContactGroups.Exists(oid)) {
                            return DAL.ContactGroups.GetByOID(oid).Title;
                        }
                    }).sort(Utils.SortByString).join(', '));
                }

                strAction = strAction.join('<br />');

                break;
            case Enums.CheckpointWorkflowType.Form:
                strAction = i18next.t('Misc.IssueType.Form') + ': ';

                if (!action.FormOID) {
                    strAction += i18next.t('CorrectiveActions.NoFormSelected');
                    return strAction;
                }

                strAction = i18next.t('Misc.IssueType.Form') + ': ';
                strAction += DAL.Elements.Exists(action.FormOID) ?
                    DAL.Elements.GetByOID(action.FormOID).Title :
                    i18next.t('Misc.Unknown');

                break;
            case Enums.CheckpointWorkflowType.CorrectiveAction:
            case Enums.CheckpointWorkflowType.Comment:
                strAction = action.Text || i18next.t('CorrectiveActions.NoText');
                break;
            case Enums.CheckpointWorkflowType.Photo:
                strAction = i18next.t('CorrectiveActions.TakePhoto');
                break;
        }

        return strAction;
    }

    export function ChunkArray<U>(array: Array<U>, chunkSize: number): Array<Array<U>>;
    export function ChunkArray<U>(array: Array<U>, chunkSize: number, start: number): Array<U>;
    export function ChunkArray<U>(array: Array<U>, chunkSize: number, start?: number): Array<Array<U>> | Array<U> {
        let result = [];
        let arrSlice = Array.prototype.slice;

        if (!(array || []).length || !chunkSize) {
            return [];
        }

        if (+start) {
            return arrSlice.call(array, start, start + chunkSize);
        } else {
            for (let aCnt = 0, aLen = array.length; aCnt < aLen; aCnt += chunkSize) {
                result.push(arrSlice.call(array, aCnt, aCnt + chunkSize));
            }

            return result;
        }
    }

    export function RepositionModalWindow($modalWindow): void {
        let maxHeight = $(window).height() - 20;
        let contentHeight, headerHeight, footerHeight, maxBodyHeight;
        let $dialog;

        if (!$modalWindow.hasClass('in')) {
            $modalWindow.show();
        }

        if (!$modalWindow.find('.modal-body').get(0)) {
            return;
        }

        $dialog = $modalWindow.find('.modal-dialog');
        contentHeight = $modalWindow.find('.modal-body').get(0).scrollHeight;
        headerHeight = +$modalWindow.find('.modal-header').outerHeight();
        footerHeight = +$modalWindow.find('.modal-footer').outerHeight();
        maxBodyHeight = $(window).height() - (headerHeight + footerHeight) - 60;

        if ($modalWindow.hasClass('fullscreen')) {
            maxBodyHeight += 40;
        }

        if (maxBodyHeight + headerHeight + footerHeight >= maxHeight) {
            maxBodyHeight = maxHeight - headerHeight - footerHeight - 60;

            if ($modalWindow.hasClass('fullscreen')) {
                maxBodyHeight += 70;
            }
        }

        $modalWindow.find('.modal-body').css('max-height', maxBodyHeight);

        $dialog.css({
            'margin-top': -($dialog.innerHeight() / 2),
            'margin-left': -($dialog.innerWidth() / 2)
        });

        if (!$modalWindow.hasClass('in')) {
            $modalWindow.hide();
        }
    }

    export function RepositionNewModalWindow($modalWindow): void {
        let $content = $modalWindow.find('.main-content:not(.hidden)');
        let $footer = $modalWindow.find('.footer');
        let maxHeight = $(window).height() - 20;
        let maxWidth = $(window).width() - 60;
        let headerHeight, footerHeight, maxBodyHeight, windowHeight;
        let customZIndex = $modalWindow.css('z-index');

        $modalWindow.removeAttr('style');
        $content.removeAttr('style');

        $modalWindow.css({
            top: 0,
            left: 0
        });

        if (maxWidth < 400) {
            $modalWindow.css('min-width', maxWidth);
        }

        $modalWindow.css('max-width', maxWidth);

        headerHeight = +$modalWindow.find('.header').outerHeight();
        footerHeight = +$footer.outerHeight();

        if (isNaN(footerHeight)) {
            footerHeight = 0;
        }

        maxBodyHeight = $(window).height() - (headerHeight + footerHeight) - 60;

        if (maxBodyHeight + headerHeight + footerHeight >= maxHeight) {
            maxBodyHeight = maxHeight - headerHeight - footerHeight - 60;
        }

        $content.css('max-height', maxBodyHeight);

        windowHeight = headerHeight + $content.outerHeight() + footerHeight;

        $modalWindow.css('height', windowHeight);

        $footer.css('top', headerHeight + $content.outerHeight());

        $modalWindow.css({
            'margin-top': -($modalWindow.innerHeight() / 2),
            'margin-left': -($modalWindow.innerWidth() / 2)
        });

        $modalWindow.css({
            top: '50%',
            left: '50%'
        });

        if (customZIndex) {
            $modalWindow.css('z-index', customZIndex);
        }
    }

    export function EscapeRegExPattern(str: string): string {
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    }

    export function EscapeHTMLEntities(str: string): string {
        if (typeof str !== 'string') {
            return str;
        }

        return (str || '')
            .replace(/</gm, '&lt;')
            .replace(/>/gm, '&gt;')
            .replace(/"/gm, '&quot;')
            .replace(/'/gm, '&#039;')
            .replace(/\r?\n|\r/g, '<br />')
            .replace(/&lt;br\s?\/&gt;/g, '<br />');
    }

    export function UnescapeHTMLEntities(str: string): string {
        if (typeof str !== 'string') {
            return str;
        }

        return (str || '')
            .replace(/&lt;/gm, '<')
            .replace(/&gt;/gm, '>')
            .replace(/&quot;/gm, '"')
            .replace(/&#039;/gm, '\'')
            .replace(/<br \/>/ig, '\n');
    }

    export function GetElementHierarchy(element: Model.Elements.Element, withoutSelectedElement?: boolean, reverse?: boolean, hierarchyDepth?: number): string {
        const hierarchy = [];
        let parent = element;

        if (!element) {
            return '';
        }

        if (withoutSelectedElement) {
            if (!parent.Parent) {
                return parent.Title;
            }

            parent = parent.Parent;
        }

        while (parent && (!hierarchyDepth || hierarchy.length <= hierarchyDepth)) {
            if (reverse) {
                hierarchy.push(parent.Title);
            } else {
                hierarchy.unshift(parent.Title);
            }

            parent = parent.Parent;
        }

        return hierarchy.join(reverse ? ' &lsaquo; ' : ' &rsaquo; ');
    }

    export function CreateColorIndicator(color: string) {
        if (!color) {
            return '';
        }

        return $(`<span class="color" style="background-color: ${color};"></span>`);
    }

    export function SetMode(mode: Enums.Mode): void {
        const evt = new CustomEvent('modechange', {
            detail: {
                PreviousMode: Session.Mode,
                NewMode: mode
            }
        });

        if (Session.Mode !== mode) {
            Session.Mode = mode;
            document.dispatchEvent(evt);
        }

        if (Utils.InArray([Enums.Mode.Menu, Enums.Mode.Calendar, Enums.Mode.IssueReport, Enums.Mode.TaskReport, Enums.Mode.NoteReport, Enums.Mode.DisturbanceReport], mode)) {
            View.StartRefreshTimeout();
        } else {
            View.StopRefreshTimeout();
        }
    }

    export function SetApiVersion(newApiVersion: string | number): void {
        if (typeof newApiVersion === 'string') {
            newApiVersion = parseInt(newApiVersion, 10);

            if (isNaN(newApiVersion)) {
                return;
            }
        }

        if (Session.LastKnownAPIVersion != newApiVersion) {
            const evt = new CustomEvent('apiVersionChange', {
                detail: {
                    PreviousApiVersion: Session.LastKnownAPIVersion || 1,
                    NewApiVersion: newApiVersion
                }
            });

            Session.LastKnownAPIVersion = newApiVersion;

            document.dispatchEvent(evt);
        }
    }

    export function GetIssueAbbreviation(type: Enums.IssueType): string {
        let key = 'Misc.IssueType.Abbreviation.';

        switch (type) {
            case Enums.IssueType.Task:
                key += 'Task';
                break;
            case Enums.IssueType.Scheduling:
                key += 'Resubmission';
                break;
            case Enums.IssueType.Form:
                key += 'Form';
                break;
            case Enums.IssueType.Note:
                key += 'Note';
                break;
            case Enums.IssueType.Disturbance:
                key += 'Disturbance';
                break;
            case Enums.IssueType.Inspection:
                key += 'Inspection';
                break;
            case Enums.IssueType.Investigation:
                key += 'Investigation';
                break;
            case Enums.IssueType.Survey:
                key += 'Survey';
                break;
            default:
                key += 'Unknown';
        }

        return i18next.t(key);
    }

    export function RenderProperty(property: Model.Properties.Property) {
        const $html = $('<p></p>');

        if (!property) {
            return i18next.t('Misc.Unknown');
        }

        if (property.Color) {
            $html.append(Utils.CreateColorIndicator(property.Color));
        }

        $html.append(` ${property.Title}`);

        return $html.html();
    }

    export function PrepareRecorditem(recorditem) {
        if (!recorditem) {
            return;
        }

        recorditem.CreationTimestamp = new Date(recorditem.CreationTimestamp);
        recorditem.ModificationTimestamp = new Date(recorditem.ModificationTimestamp);
        if (recorditem.DeadlineTimestamp) {
            recorditem.DeadlineTimestamp = new Date(recorditem.DeadlineTimestamp);
        }

        if (!!recorditem.AdditionalText) {
            recorditem.AdditionalText = Utils.EscapeHTMLEntities(recorditem.AdditionalText);
            recorditem.AdditionalText = recorditem.AdditionalText.replace(/\n/g, '<br />');

            recorditem.Comment = {
                AssignmentID: recorditem.ID,
                AssignmentOID: recorditem.OID,
                CreatorOID: (Session.User || { OID: null }).OID || recorditem.EditorOID,
                OID: uuid(),
                Text: Utils.EscapeHTMLEntities(recorditem.AdditionalText),
                Timestamp: Utils.DateTime.ToGMTString(recorditem.ModificationTimestamp),
                Type: 2
            };
        }

        if ((recorditem.Comments || []).length) {
            for (let cCnt = 0, cLen = recorditem.Comments.length; cCnt < cLen; cCnt++) {
                const comment = recorditem.Comments[cCnt];

                comment.CreationTimestamp = new Date(comment.CreationTimestamp);
                comment.ModificationTimestamp = new Date(comment.ModificationTimestamp);
                comment.Text = comment.Text || '';

                if (comment.Type === Enums.CommentType.RecorditemComment) {
                    recorditem.Comment = comment;
                }
            }

            recorditem.Comments.sort(function(a, b) {
                return a.ModificationTimestamp.getTime() - b.ModificationTimestamp.getTime();
            });
        }

        if ((recorditem.AdditionalFiles || []).length) {
            recorditem.AdditionalFiles.sort(Utils.SortByPosition);
            recorditem.Files = [];
            recorditem.Images = [];
            recorditem.ImageFilenames = [];

            for (let i = 0; i < recorditem.AdditionalFiles.length; i++) {
                const file = recorditem.AdditionalFiles[i];

                if (Utils.IsImage(file.MimeType)) {
                    file.IsImage = true;
                    recorditem.Images.push(file);
                    recorditem.ImageFilenames.push(file.Filename);
                } else {
                    recorditem.Files.push(file);
                }
            }
        }

        if ((recorditem.CorrectiveActions || []).length) {
            for (let cCnt = 0, cLen = recorditem.CorrectiveActions.length; cCnt < cLen; cCnt++) {
                const action = recorditem.CorrectiveActions[cCnt];

                action.Title = Utils.EscapeHTMLEntities(action.Title || '');
            }
        }

        return recorditem;
    }

    export function PrepareRecorditems(recorditems: Array<any>) {
        if ((recorditems || []).length) {
            for (let rCnt = 0, rLen = recorditems.length; rCnt < rLen; rCnt++) {
                recorditems[rCnt] = Utils.PrepareRecorditem(recorditems[rCnt]);
            }
        }

        return recorditems;
    }

    export function PrepareIssues(issues: Array<Model.Issues.RawIssue> | Array<Model.Issues.Issue>): Array<Model.Issues.Issue> {
        const preparedIssues: Array<Model.Issues.Issue> = [];

        if ((issues || []).length) {
            for (let iCnt = 0, iLen = issues.length; iCnt < iLen; iCnt++) {
                let issue = issues[iCnt];
                if (!issue) {
                    continue;
                }

                if (issue instanceof Model.Issues.RawIssue) {
                    issue = DAL.Issues.PrepareRawIssue(issue);
                }

                preparedIssues.push(DAL.Issues.PrepareIssue(issue));
            }
        }

        return preparedIssues;
    }

    export function IsValidGuid(str: string): boolean {
        if (typeof str !== 'string' ||
            !str || str.length != 36) {
            return false;
        }

        return (/^[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}$/ig).test(str);
    }

    export function GetElementTypeString(type: Enums.ElementType): string {
        let textNamespace = 'Misc.ParameterTypes.';

        if (type < Enums.ElementType.Checkbox) {
            textNamespace = 'Misc.GroupTypes.';
        }

        switch (type) {
            case Enums.ElementType.SingletonFormRow:
                textNamespace += 'SingletonFormRow';
                break;
            case Enums.ElementType.MasterdataGroup:
                textNamespace += 'MasterdataGroup';
                break;
            case Enums.ElementType.FormHeader:
                textNamespace += 'FormHeader';
                break;
            case Enums.ElementType.FormFooter:
                textNamespace += 'FormFooter';
                break;
            case Enums.ElementType.FormRow:
                textNamespace += 'FormRow';
                break;
            case Enums.ElementType.Parametergroup:
                textNamespace += 'Parametergroup';
                break;
            case Enums.ElementType.Checkbox:
                textNamespace += 'Checkbox';
                break;
            case Enums.ElementType.Number:
                textNamespace += 'Number';
                break;
            case Enums.ElementType.Line:
                textNamespace += 'Line';
                break;
            case Enums.ElementType.Memo:
                textNamespace += 'Memo';
                break;
            case Enums.ElementType.Date:
                textNamespace += 'Date';
                break;
            case Enums.ElementType.Time:
                textNamespace += 'Time';
                break;
            case Enums.ElementType.Photo:
                textNamespace += 'Photo';
                break;
            case Enums.ElementType.Scancode:
                textNamespace += 'Scancode';
                break;
            case Enums.ElementType.LocationCode:
                textNamespace += 'LocationCode';
                break;
            case Enums.ElementType.ListBox:
                textNamespace += 'ListBox';
                break;
            case Enums.ElementType.MultiListBox:
                textNamespace += 'MultiListBox';
                break;
            case Enums.ElementType.Info:
                textNamespace += 'Info';
                break;
            case Enums.ElementType.Signature:
                textNamespace += 'Signature';
                break;
            case Enums.ElementType.Users:
                textNamespace += 'Users';
                break;
            case Enums.ElementType.IndividualData:
                textNamespace += 'IndividualData';
                break;
            case Enums.ElementType.TelephoneNumber:
                textNamespace += 'TelephoneNumber';
                break;
            case Enums.ElementType.EMailAddresses:
                textNamespace += 'EMailAddresses';
                break;
            default:
                textNamespace = 'Misc.Unknown';
        }

        return i18next.t(textNamespace);
    }

    export function GetOffset(evt): { x: number, y: number } {
        evt = evt || window.event;

        const target = evt.target || evt.srcElement;
        const rect = target.getBoundingClientRect();
        const offsetX = evt.clientX - rect.left;
        const offsetY = evt.clientY - rect.top;

        return {
            x: offsetX,
            y: offsetY
        };
    }

    export function FixSVGSizeForView(svg: string): string {
        if (!svg || !svg.length) {
            return '';
        }

        const replacementValue = '<svg width="100%" height="100%" viewBox="0 0 $1 $2" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">';
        const dimensionsInDigitsRegex = /<svg[^>]*width="(\d+)"[^>]*height="(\d+)"[^>]*>/ig;

        return svg.replace(dimensionsInDigitsRegex, replacementValue);
    }

    export interface MarkSettings {
        Filename?: string,
        File?: any,
        Styles?: Dictionary<string>,
        WithoutStyles?: boolean;
        Width?: number | string,
        Height?: number | string,
        Classes?: string[],
        IsBase64?: boolean,
        FileContent?: any,
        FilenameContainsPath?: boolean,
        ImageSize?: string,
        Selectable?: {
            Classname: string,
            InputClassname: string,
            Filename: string,
            ParentOID: string,
            IsSelectable?: boolean
        }
    }

    export function GetImageWithMarks(settings: MarkSettings) {
        let image: any;
        let isPositionSet = false;
        let $image = $('<div></div>');

        if (!!settings.Filename) {
            if (!(image = DAL.Files.GetByFilename(settings.Filename))) {
                image = { Filename: settings.Filename };
            }
        } else if (settings.File) {
            image = settings.File;
        }

        if (settings.Styles) {
            for (let prop in settings.Styles) {

                $image.css(prop, settings.Styles[prop]);

                if (prop === 'position') {
                    isPositionSet = true;
                }
            }
        }

        if (!settings.WithoutStyles) {
            if (!isPositionSet) {
                $image.css({
                    'position': 'relative'
                });
            }

            if (settings.Width) {
                $image.css('width', settings.Width);
            }

            if (settings.Height) {
                $image.css('height', settings.Height);
            }
        }

        if ((settings.Classes || []).length) {
            $image.addClass(settings.Classes.join(' '));
        }

        if (settings.IsBase64) {
            const fileName = Utils.FixIOSFilepath(settings.FileContent);
            $image.append(`<img src="${fileName}" />`);
        } else if (image && !!image.HistoricalFilePath && settings.File && !!settings.File.Filename) {
            const histFilePath = Utils.FixIOSFilepath(image.HistoricalFilePath);
            $image.append([
                `<img`,
                Session.IsSmartDeviceApplication ? '' : ` src="${histFilePath}"`,
                ` data-filename="${settings.File.Filename}" crossorigin="use-credentials"/>`
            ].join(''));
        } else if (!!image.Filename) {
            if (Session.IsSmartDeviceApplication) {
                let filename = image.Filename;

                if (filename.contains('cdv_photo_')) {
                    filename += '?' + new Date().getTime();
                }
                const fileName = Utils.FixIOSFilepath((!settings.FilenameContainsPath ? Utils.GetResourcesPath() : '') + filename);
                $image.append(`<img src="${fileName}" data-filename="${image.Filename}" crossOrigin="anonymous" />`);
            } else {
                const url = `${Session.BaseURI}images/${settings.ImageSize}/${image.Filename}`;
                $image.append(`<img src="${url}" data-filename="${image.Filename}" crossOrigin="use-credentials" />`);
            }
        }

        if (image && !!image.Marks) {
            $image.append(`<div class="marks" style="position: absolute; top: 0; left: 0; bottom: 0; right: 0;"></div>`);
            $image.find('div').append(FixSVGSizeForView(image.Marks));
        }

        if (settings.Selectable) {
            $image.prepend(`<div class="${settings.Selectable.Classname}"><input class="${settings.Selectable.InputClassname}" type="checkbox" data-identifier="${settings.Selectable.Filename}" data-parentidentifier="${settings.Selectable.ParentOID}" checked></div>`);
        }

        return $image;
    }

    export function GetImagesFromFiles(files: Array<Model.Files.File> | null): Array<Model.Files.File> {
        return (files || [])
            .map(file => DAL.Files.GetByOID(file.OID))
            .filter(file => file && file.IsImage);
    }

    /**
     * Es gibt zwar Browser-Funktionen, um an die Orientierung zu kommen,
     * diese sind aber entweder nicht mehr in aktuellen Versionen unterstützt,
     * oder zu neu, dass alte Versionen diese noch nicht unterstützen.
     * Deshalb gibt es diese unabhängige Funktion.
     * Stand: Juli 2023
     */
    export function GetOrientation(): 'portrait' | 'landscape' {
        return window.innerWidth / window.innerHeight <= 1
            ? 'portrait'
            : 'landscape';
    }

    export function GetIndividualSchemaTitle(entityType: string): string {
        const schema = DAL.Schemas.GetByType(entityType);

        if (schema) {
            return schema.NamePlural;
        }

        return i18next.t('Misc.Unknown');
    }

    export function GetPreparedParameterValue(elementOID: string, revisionOID: string, value: any, isHistorical: boolean, forceUsageOfCurrentElementRevision?: boolean, showAllAvailableOptions: boolean = false, element: Model.Elements.Element = null, recorditem?: Model.Recorditem): string {
        if (!element) {
            element = Utils.InArray([Enums.View.Form, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView) && !forceUsageOfCurrentElementRevision ?
                ParameterList.GetElementRevisionByRevisionOID(revisionOID) :
                DAL.Elements.GetByOID(elementOID);

            if (!element) {
                return null;
            }
        }

        const emptyPlaceholder = '<span class="placeholder">-/-</span>';

        if ((element.Type !== Enums.ElementType.ListBox &&
            element.Type !== Enums.ElementType.MultiListBox) ||
            !showAllAvailableOptions) {
            if (typeof value === 'undefined' || value === null) {
                return emptyPlaceholder;
            }
        }

        let strValue: any = null,
            tmp: any;

        switch (element.Type) {
            case Enums.ElementType.Checkbox:
                if (/true/i.test(value)) {
                    strValue = '<img src="./img/checked.svg" width="50" />';
                } else if (/false/i.test(value)) {
                    strValue = '<img src="./img/crosschecked.svg" width="50" />';
                } else {
                    strValue = '<img src="./img/unchecked.svg" width="50" />';
                }
                break;
            case Enums.ElementType.Number:
                strValue = value;

                const scientificNotationRegex =
                    /(^[<>][+-]?\d+(\.\d+)?$)|(^[<>]?[+-]?\d+(\.\d+)?e[+-]?\d+(\.\d+)?$)|(^n{2}$)|(^ni$)|(^n\.(n\.|i\.)$)/;

                if (!scientificNotationRegex.test(strValue)) {
                    if (element.Decimals) {
                        strValue = parseFloat(strValue).toFixed(element.Decimals);
                    }

                    strValue = strValue.toString().replace('.', ',');
                } else if (isNaN(value)) {
                    return '-/-';
                } else {
                    strValue = strValue.toString().replace('.', ',');
                }

                tmp = element.Unit || DAL.Properties.GetByOID(element.UnitOID);

                if (tmp) {
                    strValue += ` ${tmp.Description || tmp.Title}`;
                }
                break;
            case Enums.ElementType.Date:
                strValue = Utils.DateTime.DateToString(new Date(value));
                break;
            case Enums.ElementType.Time:
                strValue = Utils.DateTime.TimeToString(new Date(value));
                break;
            case Enums.ElementType.Photo:
            case Enums.ElementType.Signature:
                {
                    let srcPath: string;

                    if (Session.IsSmartDeviceApplication) {
                        let filePath = `${Utils.GetResourcesPath()}${value}`;

                        if (Session.IsRunningOnIOS) {
                            filePath = Utils.FixIOSFilepath(filePath);
                        }

                        srcPath = isHistorical ? '' : ` src="${filePath}"`;
                    } else {
                        srcPath = `src="${Session.BaseURI}images/s/${value}${Utils.GetAuthQueryParameter('?')}"`;
                    }

                    const template = [
                        '<div class="file image thumbnail" data-type="image">',
                        `<img ${srcPath} data-filename="${value}" crossorigin="${Session.IsSmartDeviceApplication ? 'anonymous' : 'use-credentials'}" />`
                    ]

                    // Bildmarkierungen anfügen
                    if (recorditem && recorditem.AdditionalValueInfo &&
                        recorditem.AdditionalValueInfo.Marks) {
                        template.push(FixSVGSizeForView(recorditem.AdditionalValueInfo.Marks));
                    }

                    template.push('</div>');

                    strValue = template.join('');
                }
                break;
            case Enums.ElementType.LocationCode:
                tmp = DAL.Elements.GetByOID(value);
                if (Utils.IsValidGuid(value) && tmp) {
                    strValue = tmp.Title;

                    if (tmp.Parent) {
                        strValue += ' @ ' + tmp.Parent.Title;
                    }
                }
                break;
            case Enums.ElementType.ListBox:
            case Enums.ElementType.MultiListBox:
                if (!(Utils.HasProperties(element.Structure))) {
                    return emptyPlaceholder;
                }

                const markup = [];
                const keys = Object.keys(element.Structure);

                keys.sort((a: string, b: string) => {
                    return parseInt(a, 10) - parseInt(b, 10);
                });

                if (Utils.IsSet(value)) {
                    value = !(value instanceof Array) ? [value.toString()] : value.map((v) => v.toString());
                }

                const mapToImages = element.hasOwnProperty('AdditionalSettings') && element.AdditionalSettings.MapStructureToImages;

                for (let idx = 0, len = keys.length; idx < len; idx++) {
                    const key = keys[idx];

                    if (!element.Structure.hasOwnProperty(key)) {
                        continue;
                    }

                    const structValue = element.Structure[key];

                    if (!structValue) {
                        continue;
                    }

                    const isSelected = Utils.InArray(value, key);

                    if (!isSelected && !showAllAvailableOptions) {
                        continue;
                    }

                    const valueMarkup = ['<div'];
                    const cls = ['list-item-element'];

                    if (showAllAvailableOptions) {
                        cls.push('clickable');
                    }

                    if (mapToImages) {
                        cls.push('is-image-selection');
                    }

                    if (showAllAvailableOptions) {
                        if (isSelected) {
                            cls.push('selected');
                        }
                    } else {
                        cls.push('without-indentation');
                    }

                    if (cls.length) {
                        valueMarkup.push(` class="${cls.join(' ')}"`);
                    }

                    valueMarkup.push(`data-value="${key}">`);

                    if (mapToImages) {
                        tmp = DAL.Files.GetByOID(structValue);

                        if (tmp) {
                            tmp = Session.IsSmartDeviceApplication ?
                                `<img src="${FixIOSFilepath(GetResourcesPath() + tmp.Filename)}" />` :
                                `<img src="${Session.BaseURI}images/s/${tmp.Filename}${Utils.GetAuthQueryParameter('?')}" crossorigin="use-credentials" />`;
                        }
                    } else {
                        tmp = Utils.EscapeHTMLEntities(structValue);
                    }

                    valueMarkup.push(tmp, '</div>');
                    markup.push(valueMarkup.join(''));
                }


                strValue = markup.join('');
                break;
            case Enums.ElementType.Users:
                const raw = JSON.parse(value);
                if (!raw) {
                    return emptyPlaceholder;
                }

                strValue = [];

                if (raw.Users && raw.Users.length) {
                    const users = $.map(raw.Users, function(oid: string) {
                        const tmp = DAL.Users.GetByOID(oid);
                        return tmp ? tmp.Title : null;
                    });

                    if (users.length) {
                        strValue.push(i18next.t('Misc.User_plural') + ': ');

                        if (users.length > 1) {
                            strValue.push(users.slice(0, -1).join(', '));
                            strValue.push(` ${i18next.t('Misc.And')} `);
                        }

                        strValue.push(users[users.length - 1]);
                    }
                }

                if (raw.Teams && raw.Teams.length) {
                    const teams = $.map(raw.Teams, function(oid: string) {
                        const tmp = DAL.Teams.GetByOID(oid);
                        return tmp ? tmp.Title : null;
                    });

                    if (teams.length) {
                        // Trennzeichen zwischen User und Team
                        if (strValue.length) {
                            strValue.push('\n\n');
                        }

                        strValue.push(i18next.t('Misc.Team_plural') + ': ');

                        if (teams.length > 1) {
                            strValue.push(teams.slice(0, -1).join(', '));
                            strValue.push(` ${i18next.t('Misc.And')} `);
                        }

                        strValue.push(teams[teams.length - 1]);
                    }
                }

                if (!strValue.length) {
                    return emptyPlaceholder;
                }

                strValue = strValue.join('');

                break;
            case Enums.ElementType.IndividualData:
                strValue = [];

                if (value) {
                    for (let key in value) {
                        tmp = DAL.Schemas.GetByType(key);

                        if (!tmp) {
                            continue;
                        }

                        strValue.push($.map(value[key], function(v) {
                            const title = DAL.IndividualData.GetEntityTitle(tmp.Type, v);
                            return title ? title.replace(/<br>|<br \/>/g, '\r\n') : null;
                        }).join(tmp.Display.contains('\n') ? '\r\n\r\n' : ', '));
                    }
                }

                if (!strValue.length) {
                    return emptyPlaceholder;
                }

                strValue = strValue.join('\n\n');

                if (!strValue) {
                    return emptyPlaceholder;
                }

                break;
            case Enums.ElementType.TelephoneNumber:
                value = Utils.EscapeHTMLEntities(value);
                return `<a href="tel:${Utils.FormatPhoneNumber(value)}">${value}</a>`;
            case Enums.ElementType.EMailAddresses:
                try {
                    if (typeof value === 'string') {
                        value = JSON.parse(value);
                    } else if (!(value instanceof Array)) {
                        value = null;
                    }
                }
                catch {
                    value = null;
                }

                return value instanceof Array && value.length ? value.join(', ') : '-/-';
            case Enums.ElementType.Files:
                strValue = value instanceof Array && value.length ? value
                    .sort(Utils.SortByTitle)
                    .map(function (file: Model.IFilesRecorditemValue) {
                        const markup = [
                            '<div class="file-recorditem-row" data-filename="', file.Filename, '" data-mimetype="', file.MimeType, '">',
                            '<div class="file-recorditem-file-title">', DOMPurify.sanitize(file.Title), '</div>',
                        ];

                        if (!!file.Description) {
                            markup.push(
                                '<div class="file-recorditem-file-description">',
                                    DOMPurify.sanitize(Utils.EscapeHTMLEntities(file.Description)),
                                '</div>'
                            );
                        }

                        markup.push('</div>');

                        return markup.join('');
                    }).join('') : null;
                break;
            default:
                if (typeof value !== 'string' || !$.trim(value || '').length) {
                    return emptyPlaceholder;
                }

                strValue = Utils.EscapeHTMLEntities(value.toString());
        }

        if (!!strValue) {
            strValue = strValue.replace(/\n/g, '<br>');
        }

        return strValue;
    }

    export function CheckIfDeviceIsOnline(): Deferred {
        const deferred = $.Deferred();

        if (!Session.IsSmartDeviceApplication || navigator.connection.type !== "none") {
            deferred.resolve(true);
        } else if (Session.IsSmartDeviceApplication) {
            if (navigator.connection.type == 'unknown') {
                // iOS stellt die Information initial evtl. 1sec später bereit
                // dieses über events hier abfangen
                const id = new Date().getTime();
                $(document).on(`online.check${id}`, function() {
                    deferred.resolve(true);
                    $(document).off(`online.check${id}`);
                    $(document).off(`offline.check${id}`);
                });
                $(document).on(`offline.check${id}`, function() {
                    deferred.reject(false);
                    $(document).off(`online.check${id}`);
                    $(document).off(`offline.check${id}`);
                });
            } else if (navigator.onLine) {
                deferred.resolve(true);
            } else {
                deferred.reject(false);
            }
        }

        return deferred.promise();
    }

    export function CheckIfDeviceIsAllowedToAutoSync(): boolean {
        if (!Session.IsSmartDeviceApplication) {
            return false;
        }

        const type = navigator.connection.type;

        if (type !== "none") {
            return Session.Settings.AllowAutoSyncOverCell || type === "wifi";
        } else {
            return false;
        }
    }

    export function CheckIfNfcIsAvailable(): Deferred {
        const deferred = $.Deferred();

        if (!window.nfc || !Session.IsSmartDeviceApplication) {
            return deferred.reject(false).promise();
        }

        window.nfc.enabled(function() {
            deferred.resolve();
        }, function() {
            deferred.reject(false);
        });

        return deferred.promise();
    }

    export function HasElementInformation(element: Model.Elements.Element): boolean {
        if (!element) {
            return false;
        }

        if (element.Description ||
            element.InfoText ||
            (element.Files || []).length ||
            (element.Images || []).length ||
            (element.Properties || []).length) {
            return true;
        }

        if (element.Type === Enums.ElementType.Users || element.Type === Enums.ElementType.IndividualData) {
            if (!!element.AdditionalSettings) {
                if (element.AdditionalSettings.MaximumSelectionCount) {
                    return true;
                }

                if (element.Type === Enums.ElementType.Users) {
                    const filters = element.AdditionalSettings.Filters;
                    if (!!filters && ((filters.Roles || []).length || (filters.Teams || []).length)) {
                        return true;
                    }
                }
            }
        }

        if (element.Type >= 100 &&
            (element.Unit || element.Actions ||
                (element.Evaluation && Session.Settings.ShowEvaluationInElementInformation))) {
            return true;
        }

        return false;
    }

    export function ResetResponseTimestamps(withoutAsking: boolean = false, entityTypes: Enums.EntityType[] = null): Deferred {
        const deferred = $.Deferred();

        if (!Session.IsSmartDeviceApplication) {
            return deferred.resolve();
        }

        function clearSpecificEntityInfos(): Deferred {
            return window.Database.DeleteManyFromStorage(Enums.DatabaseStorage.SynchronisationInformation, entityTypes)
                .then(function() {
                    entityTypes.forEach((entityType) => {
                        Session.SynchronisationInformation.Remove(entityType);
                    });

                    deferred.resolve();
                });
        }

        function clear(): Deferred {
            return window.Database.ClearStorage(Enums.DatabaseStorage.SynchronisationInformation)
                .then(function() {
                    Session.SynchronisationInformation.Clear();

                    deferred.resolve();
                });
        }

        if (!withoutAsking) {
            Utils.Message.Show(i18next.t('Settings.ResponseTimestamps.QuestionHeader'),
                i18next.t('Settings.ResponseTimestamps.QuestionBody'),
                {
                    Yes: {
                        Caption: i18next.t('Misc.Yes'),
                        Classes: ['btn', 'flat', 'btn-danger', 'btn-yes'],
                        Fn: (entityTypes || []).length ? clearSpecificEntityInfos : clear
                    },
                    No: {
                        Classes: ['btn', 'flat', 'btn-success', 'btn-no']
                    }
                });
        } else {
            if ((entityTypes || []).length) {
                clearSpecificEntityInfos();
            } else {
                clear();
            }
        }

        return deferred.promise();
    }

    export function WipeClientData(): void {
        if (!Session.IsSmartDeviceApplication) {
            return;
        }

        DAL.Sync.GetSyncEntitiesCount()
            .then((count: Number) => {
                // Meldung anhand vorhandene SyncEntities anzeigen
                const messageHeader = count > 0 ?
                    i18next.t('Settings.DeleteData.QuestionHeaderWarn') :
                    i18next.t('Settings.DeleteData.QuestionHeader');

                const messageBody = count > 0 ?
                    Templates.WarnMessage({
                        Image: 'alert.svg',
                        Message: new Handlebars.SafeString(i18next.t('Settings.DeleteData.QuestionBodyWarn'))
                    })
                    : i18next.t('Settings.DeleteData.QuestionBody');

                const buttonOptions: Message.OptionButtons = {
                    Yes: {
                        Caption: i18next.t('Settings.DeleteData.Buttons.Confirm'),
                        Classes: ['btn', 'flat', 'btn-danger', 'btn-yes'],
                        Fn: () => dropClientData(),
                        OneLine: count > 0,
                        Timeout: count > 0 ? 5 : 0
                    },
                    Abort: {
                        Caption: i18next.t('Settings.DeleteData.Buttons.Abort'),
                        Classes: ['btn', 'flat', 'btn-success', 'btn-no'],
                        OneLine: count > 0,
                    }
                };

                if (count > 0) {
                    buttonOptions.Save = {
                        Caption: i18next.t('Settings.DeleteData.Buttons.Synchronize'),
                        Classes: ['btn', 'flat', 'btn-success', 'btn-no'],
                        OneLine: count > 0,
                        Fn: () => {
                            // Synchronisation durchführen, bevor Daten gelöscht werden
                            DAL.Sync.UploadUnsyncedData()
                                .then(() => {
                                    // Daten löschen, bei erfolgreicher Synchronisation
                                    dropClientData();
                                });

                        }
                    };
                }

                Utils.Message.Show(messageHeader,
                    messageBody,
                    buttonOptions
                );
            });
    }

    function dropClientData(): Deferred {
        let preAction: Deferred;
        if (Utils.PushNotifications) {
            // wait for unregister to finish, or the auth data is gone
            preAction = Utils.PushNotifications.Unregister(false)
                .then(null, function() {
                    // negate any bad result for the logout to proceed
                    return $.Deferred().resolve();
                });
        }

        if (!preAction) {
            preAction = $.Deferred().resolve();
        }

        return preAction
            .then(() => App.Logout({ ClientDataHasBeenWiped: true }))
            .then(function() {
                return window.Database.ClearAllStorages();
            })
            .then(function() {
                Session.SynchronisationInformation.Clear();
                Session.LastKnownAPIVersion = 0;
                Session.Client = null;
                Session.IsAppUpdateRequired = false;

                cordova.plugin.http.clearCookies();

                DAL.Clear();

                Utils.UpdateAppBadge(0);
            })
            .then(function() {
                // TODO (maybe) show 'deleting files' message
                const filesDeferred = $.Deferred();
                const fsReader = Session.ResourcesFolder.createReader();

                fsReader.readEntries(function(entries: any[]) {
                    if (!entries || !entries.length) {
                        filesDeferred.reject();
                        return;
                    }

                    const files: string[] = entries.map((file) => {
                        return file.isDirectory ? null : file.name;
                    }).filter((filename: string) => {
                        return !!filename;
                    });

                    filesDeferred.resolve(files);
                }, function(err) {
                    console.log(err);
                    filesDeferred.reject(err);
                });

                return filesDeferred.promise()
                    .then(Utils.DeleteFiles)
                    .then(null, () => {
                        // ignore any file delete errors here to proceed with logout
                        return $.Deferred().resolve();
                    });
            })
            .then(App.Init.enableClearApp)
            .then(App.Init.hideIcons)
            .then(function() {
                return window.Database.CloseDatabase();
            })
            .then(() => {
                // restart app
                if (location && location.reload) {
                    location.reload();
                }
            })
            .fail(console.log);
    }

    export function GetIssueTypeIcon(issueType: Enums.IssueType): string {
        switch (issueType) {
            case Enums.IssueType.Task:
                return 'task.svg';
            case Enums.IssueType.Scheduling:
                return 'checklist.svg';
            case Enums.IssueType.Form:
                return 'form.svg';
            case Enums.IssueType.Note:
                return 'notes.svg';
            case Enums.IssueType.Disturbance:
                return 'disturbance_reported.svg';
            case Enums.IssueType.Inspection:
                return 'jobsite.svg';
            case Enums.IssueType.Investigation:
                return 'form.svg';
            case Enums.IssueType.Survey:
                return 'form.svg';
        }
    }

    export function CheckIfRecorditemIsNewOrUpdated(currentRecorditem: Model.Recorditem, recorditemOfComparison: Model.Recorditem): boolean {
        let currentRecorditemModificationTimestamp: Date;
        let comparisonModificationTimestamp: Date;

        if (!currentRecorditem ||
            currentRecorditem.OID === recorditemOfComparison.OID) {
            return true;
        }

        currentRecorditemModificationTimestamp = currentRecorditem.ModificationTimestamp;
        comparisonModificationTimestamp = recorditemOfComparison.ModificationTimestamp;

        if (typeof currentRecorditemModificationTimestamp === 'string') {
            currentRecorditemModificationTimestamp = new Date(currentRecorditemModificationTimestamp);
        }

        if (typeof comparisonModificationTimestamp === 'string') {
            comparisonModificationTimestamp = new Date(comparisonModificationTimestamp);
        }

        if (currentRecorditemModificationTimestamp instanceof Date &&
            comparisonModificationTimestamp instanceof Date) {
            return currentRecorditemModificationTimestamp.getTime() < comparisonModificationTimestamp.getTime();
        }

        return false;
    }

    export function PrepareRecorditemForSync(recorditem: Model.Recorditem): Model.Recorditem {
        let propertiesToDelete = [
            'CorrectiveActions',
            'IsUpdatable',
            'Comment',
            'Comments',
            'Images',
            'Files',
            'IsHistorical',
            'IsUnsynced',
            'NewFiles',
            'Element',
            'Creator',
            'Editor'
        ];

        if (!recorditem) {
            return;
        }

        recorditem = Utils.CloneObject(recorditem, propertiesToDelete);

        recorditem.CreationTimestamp = Utils.DateTime.ToGMTString(recorditem.CreationTimestamp);
        recorditem.ModificationTimestamp = Utils.DateTime.ToGMTString(recorditem.ModificationTimestamp);

        return recorditem;
    }

    export function ResizeModalWindow($modalWindow, doNotRepositionWindow?: boolean): void {
        if (!$modalWindow) {
            return;
        }

        let $client = $(window);
        let $modalBody = $('.modal-body', $modalWindow);

        $modalBody.css('max-height', '');

        if ($modalWindow.hasClass('fullscreen')) {
            $('.modal-content').css('max-height', $client.height());
        } else {
            $('.modal-content').css('max-height', $client.height() - 60);
        }

        if (!doNotRepositionWindow) {
            Utils.RepositionModalWindow($modalWindow);
        }

        if ($modalWindow.hasClass('fullscreen')) {
            $modalBody.css('height', $modalBody.css('max-height'));
            $modalWindow.find('.modal-dialog').css({
                'max-height': '',
                'margin-top': 0,
                'margin-left': 0,
                width: 'auto'
            });
        }
    }

    export function GetCameraOptions() {
        return {
            quality: Session.Settings.PhotoQuality,
            destinationType: Camera.DestinationType.FILE_URI,
            sourceType: Camera.PictureSourceType.CAMERA,
            allowEdit: false,
            mediaType: Camera.MediaType.PICTURE,
            encodingType: Camera.EncodingType.JPEG,
            correctOrientation: true,
            saveToPhotoAlbum: true,
            targetHeight: 1440,
            targetWidth: 1080
        };
    }

    export function PadLeft(value: string | number, n: number, str?: string) {
        return new Array(n - value.toString().length + 1).join(str || '0') + value;
    }

    export function GetContentContainer() {
        return $('#content').find('> .content');
    }

    export function GetDefaultContentHeader(location: Model.Elements.Element) {
        return $(Templates.Menus.DefaultContentHeader(location));
    }

    export function GetInspectionContentHeader(location: Model.Elements.Element) {
        const $contentHeader = $(Templates.Menus.InspectionContentHeader({
            Location: location,
            Issue: IssueView.GetCurrentIssue()
        }));

        return $contentHeader;
    }

    export function RequestCamera(): Deferred {
        return Utils.IsPermissionGranted(cordova.plugins.permissions.CAMERA)
            .then(null, () => Utils.RequestPermission(cordova.plugins.permissions.CAMERA))
            .then(() => Utils.IsCameraPresent()
                .then(function(isPresent: boolean) {
                    if (!isPresent) {
                        Utils.Message.Show(
                            i18next.t('Camera.IsNotPresent.MessageHeader'),
                            i18next.t('Camera.IsNotPresent.MessageBody'),
                            { Close: true }
                        );

                        return $.Deferred().reject('camera is not present');
                    }

                    const deferred: Deferred = $.Deferred();

                    navigator.camera.getPicture(
                        (imageUri: string) => { deferred.resolve(imageUri); },
                        (message: string) => { deferred.reject(message); },
                        Utils.GetCameraOptions()
                    );

                    return deferred;
                }, function(error: string) {
                    Utils.Message.Show(
                        i18next.t('Camera.ErrorDetectingCamera.MessageHeader'),
                        i18next.t('Camera.ErrorDetectingCamera.MessageBody', { Error: error }),
                        { Close: true }
                    );

                    return $.Deferred().reject(error);
                })
            )
            .fail(Utils.Spinner.Hide);
    }

    export function GetAvailableContentMenuItemsByRoles(sectionIdent: Enums.MenuSection, locationOID?: string): Array<any> {
        let roles = !!locationOID ? Utils.GetUserRoles(locationOID) : Utils.GetAllUserRoles();
        let uiItems = [];

        function walk(uiItem, parent?) {
            if (uiItem.IsEnabled) {
                if (!Utils.InArray(uiItems, uiItem.ID)) {
                    uiItems.push(parent.ID + '_' + uiItem.ID);
                }
            }

            (uiItem.Children || []).forEach(function(child) {
                walk(child, uiItem);
            });
        }

        (roles || []).forEach(function(identifier) {
            let role = DAL.Roles.GetByOID(identifier);

            if (!role) {
                return;
            }

            (role.RecordingUIRights || []).forEach(function(section) {
                if (!!sectionIdent && section.ID !== sectionIdent) {
                    return;
                }

                walk(section);
            });
        });

        return uiItems.length ? uiItems : null;
    }

    export function GetAvailableContentMenuItems(sectionIdent: string, locationOID?: string): { [id: string]: { ID, Position?} } {
        const roles = !!locationOID ? Utils.GetUserRoles(locationOID) : Utils.GetAllUserRoles();
        const uiItems: { [id: string]: { ID, Position?} } = {};

        function walk(uiItem: Model.Roles.RecordingUiRight, parent?: Model.Roles.RecordingUiRight) {
            if (uiItem.IsEnabled) {
                uiItems[uiItem.ID] = uiItem;
            }

            (uiItem.Children || []).forEach(function(child) {
                walk(child, uiItem);
            });
        }

        (roles || []).forEach(function(identifier) {
            const role = DAL.Roles.GetByOID(identifier);

            if (!role) {
                return;
            }

            (role.RecordingUIRights || []).forEach(function(section: Model.Roles.RecordingUiRight) {
                if (!!sectionIdent && section.ID !== sectionIdent) {
                    return;
                }

                walk(section);
            });
        });

        return uiItems || null;
    }

    export function IsMenuItemEnabled(id: Enums.MenuItemID | string, locationOID?: string): boolean {
        const availableMenuItems = Utils.GetAvailableContentMenuItemsByRoles(
            Enums.MenuSection.Content, locationOID || Session.CurrentLocation.OID);

        return Utils.InArray(availableMenuItems, id);
    }

    export function HasAnyMenuItemOfActionType(actionType: Enums.Menu.Action, locationOID?: string): boolean {
        const enabledMenuItems = GetAvailableContentMenuItemsByRoles(Enums.MenuSection.Content,
            locationOID || Session.CurrentLocation.OID);

        if (!enabledMenuItems) {
            return false;
        }

        for (let i = 0; i < enabledMenuItems.length; i++) {
            const mItemID = enabledMenuItems[i].substr(Enums.MenuSection.Content.length + 1);
            const mItemConf = Menu.GetMenuItemConfig(mItemID);

            if (!mItemConf || mItemConf.ActionType != actionType) {
                continue;
            }

            return true;
        }

        return false;
    }

    export function IsIssueLocked(issue: Model.Issues.Issue): boolean {
        if (!issue || !issue.StateOID) {
            return false;
        }

        const state = DAL.Properties.GetByOID(issue.StateOID);

        return state ? state.IsLockedState : false;
    }

    export function IsPermissionGranted(permission: string): Deferred {
        const deferred = $.Deferred();

        if (!(cordova.plugins.permissions.checkPermission instanceof Function)) {
            deferred.resolve(permission);

            return deferred.promise();
        }

        cordova.plugins.permissions.checkPermission(permission, function(status: { hasPermission: boolean }) {
            if (status.hasPermission) {
                deferred.resolve(permission);
            } else {
                deferred.reject(permission);
            }
        }, function() { deferred.reject(permission); });

        return deferred.promise();
    }

    export function RequestPermission(permission: string): Deferred {
        const deferred = $.Deferred();

        if (!(cordova.plugins.permissions.checkPermission instanceof Function)) {
            deferred.resolve(permission);
        } else {
            cordova.plugins.permissions.requestPermission(permission, function(status: { hasPermission: boolean }) {
                if (status.hasPermission) {
                    deferred.resolve(permission);
                } else {
                    deferred.reject(permission);
                }
            }, () => deferred.reject(permission));
        }

        return deferred.promise();
    }

    export function FormatPhoneNumber(str: string | number): string {
        if (!str) {
            return '-/-';
        }

        if (typeof str === 'number') {
            return String(str);
        }

        return str.replace(/[-\/\s]|[(\[]0[)\]]/ig, '');
    }

    export function UpdateAppBadge(counter: number | string): void {
        if (!Session.IsSmartDeviceApplication || typeof counter !== 'number') {
            return;
        }

        const cnt = parseInt(<any>counter, 10);
        cordova.plugins.notification.badge.hasPermission(function(granted: boolean) {
            if (granted) {
                cordova.plugins.notification.badge.set(cnt);
            }
        });
    }

    export function GetPrioritizedFile(element: Model.Elements.Element) {
        if (Session.LastKnownAPIVersion < 2 ||
            !element ||
            !(element.Files || []).length) {
            return;
        }

        let prioritizedFile: Model.Files.File;

        element.Files.forEach(function(f) {
            if (f.Prioritize) {
                prioritizedFile = DAL.Files.GetByOID(f.OID);
                return false;
            }
        });

        return prioritizedFile;
    }

    export function SortFilesByPrioritization(files: Array<Model.Files.File>) {
        // sortiert in-place
        files.sort((fileA: Model.Files.File, fileB: Model.Files.File) =>
            Number(fileA.Prioritize || false) - Number(fileB.Prioritize || false)
        );
    }

    export function ShowAppIsReadOnlyDuringSynchronisationMessage() {
        if (!Session.IsSmartDeviceApplication) {
            return;
        }

        Utils.Message.Show(i18next.t('Misc.ReadOnlyDuringSynchronisation.MessageHeader'),
            i18next.t('Misc.ReadOnlyDuringSynchronisation.MessageBody'),
            {
                Close: true
            },
            null,
            10000);
    }

    export function IsBluetoothEnabled(forceEnable?: boolean): Deferred {
        let deferred = $.Deferred();

        if (!window.ble) {
            deferred.reject();

            return deferred.promise();
        }

        if (Session.IsRunningOnAndroid && forceEnable) {
            window.ble.enable(deferred.resolve, deferred.reject);
        } else {
            window.ble.isEnabled(deferred.resolve, deferred.reject);
        }

        return deferred.promise();
    }

    export function GetIssueTypeByMode(mode: Enums.Mode): Enums.IssueType {
        if (!mode || mode === Enums.Mode.TaskReport) {
            return Enums.IssueType.Task;
        }

        return mode === Enums.Mode.NoteReport ?
            Enums.IssueType.Note :
            Enums.IssueType.Disturbance;
    }

    export function IsIssueEditableDuringSync(issue: Model.Issues.Issue): boolean {
        // just go with it
        return true;
    }

    export function HasProperties(obj: any): boolean {
        if (!obj) {
            return false;
        }

        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                return true;
            }
        }

        return false;
    }

    export function CheckWhetherActionsAreAvailableForRecorditem(categoryIdentifier: string, element: Model.Elements.Element): boolean {
        if (!categoryIdentifier ||
            !element ||
            !(element.Actions || [])) {
            return false;
        }

        return element.Actions.some(function(action) {
            return action.CategoryOID === categoryIdentifier;
        });
    }

    export function ObjectToArray(obj: any): Array<any> {
        if (obj == null) {
            return [];
        }

        let arr = [];

        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                arr.push(obj[key]);
            }
        }

        return arr;
    }

    export function IsValidVersionString(str: string): boolean {
        return /\d+\.\d+\.\d+(\.\d+)?/.test(str);
    }

    export function ZeroFillStringArray(a: Array<string>, bLength: number): Array<string> {
        let remaining = bLength - a.length;

        while (remaining > 0) {
            a.push("0");
            remaining--;
        }

        return a;
    }

    export function CompareVersion(versionA: string, versionB: string): number {
        if (!Utils.IsSet(versionA) && !Utils.IsSet(versionB)) {
            return 0;
        }

        if (!Utils.IsSet(versionA)) {
            return -1;
        }

        if (!Utils.IsSet(versionB)) {
            return 1;
        }

        if (!Utils.IsValidVersionString(versionA) || !Utils.IsValidVersionString(versionB)) {
            return 0;
        }

        const versionAStringParts = versionA.split('.');
        const versionBStringParts = versionB.split('.');
        const versionAParts = Utils.ZeroFillStringArray(versionAStringParts, versionBStringParts.length)
            .map(s => parseInt(s, 10));
        const versionBParts = Utils.ZeroFillStringArray(versionBStringParts, versionAStringParts.length)
            .map(s => parseInt(s, 10));
        const versionAPartsLength = versionAParts.length;
        const versionBPartsLength = versionBParts.length;

        for (let i = 0; i < versionAPartsLength; i++) {
            if (versionBPartsLength == i) {
                return 1;
            }

            if (versionAParts[i] == versionBParts[i]) {
                continue;
            }

            return versionAParts[i] > versionBParts[i] ? 1 : -1;
        }

        if (versionAPartsLength != versionBPartsLength) {
            return -1;
        }

        return 0;
    }

    export function OnImageNotFound(): void {
        $(this).attr({
            src: './img/file_not_found.svg',
            width: 75
        });
    }

    export function OnIframeLoaded(onHeightChanged: Function): void {
        const $iframe = $(this);

        if (!Utils.IsSet(onHeightChanged) || !(onHeightChanged instanceof Function)) {
            onHeightChanged = $.noop;
        }

        if (!$iframe.attr('src').startsWith('blob:')) {
            return;
        }

        $iframe.contents().ready(() => {
            // Links anpassen, damit diese in einem neuen Fenster geöffnet werden
            this.contentWindow.document.querySelectorAll('a').forEach((a: HTMLAnchorElement) => {
                if (!Session.IsSmartDeviceApplication) {
                    a.target = '_blank';
                    return;
                }

                a.setAttribute('data-href', a.href);
                a.href = '#'

                if (navigator.app && navigator.app.loadUrl instanceof Function) {
                    a.onclick = () => {
                        navigator.app.loadUrl(a.getAttribute('data-href'), { openExternal: true });
                        return false;
                    };
                } else if (window.cordova && window.cordova.InAppBrowser) {
                    a.onclick = () => {
                        window.cordova.InAppBrowser.open(a.getAttribute('data-href'), '_system');
                        return false;
                    };
                } else {
                    a.onclick = () => {
                        window.open(a.getAttribute('data-href'), '_system');
                        return false;
                    };
                }
            });

            //Höhe des Iframes abhängig vom Inhalt setzen
            this.contentWindow.document.body.style.display = 'inline-block';
            this.style.height = (this.contentWindow.document.body.offsetHeight + 20) + 'px';
            onHeightChanged();
        });
    }

    /**
     * @see https://patrickhlauke.github.io/touch/touchscreen-detection/
     * @returns {boolean}
     */
    export function DeviceAllowsTouchEvents(): boolean {
        if (window.hasOwnProperty('PointerEvent') && ('maxTouchPoints' in navigator)) {
            return navigator.maxTouchPoints > 0;
        }

        if (window.matchMedia && window.matchMedia("(any-pointer:coarse)").matches) {
            return true;
        }

        return window.hasOwnProperty('TouchEvent') || ('ontouchstart' in window);
    }

    export function GetFileTitle(title: string, alternativeTitle: string): string {
        title = (title || '').trim();

        if (!(title || '').length) {
            title = (alternativeTitle || '').trim();
        }

        if (title.length == 0) {
            return null;
        } else if (title.length > 50) {
            return title.substr(0, 50).trim();
        }

        return title;
    }

    export function UpdateFileTitleAndDescription(fileProperties: { Title: string, Description?: string, Filename?: string }, title: string, description: string) {
        fileProperties.Title = Utils.GetFileTitle(title, fileProperties.Filename);

        description = (description || '').trim();

        if (description.length) {
            fileProperties.Description = description;
        } else {
            delete fileProperties.Description;
        }
    }

    export function ConcatManyContactsToString(contacts: Array<string>): string {
        if (!(contacts || []).length) {
            return i18next.t('IssueViewer.Content.NoContactsSelected');
        }

        let titles = [];

        for (let cCnt = 0, cLen = contacts.length; cCnt < cLen; cCnt++) {
            const contact = DAL.Contacts.GetByOID(contacts[cCnt]);
            let title: string = null;

            if (contact) {
                if (!!contact.Title) {
                    title = contact.Title;
                }

                if (!!contact.Company) {
                    if (!!title) {
                        title += ' (';
                    } else {
                        title = '';
                    }

                    title += contact.Company;

                    if (!!contact.Title) {
                        title += ')';
                    }
                }

                titles.push(title);
            }
        }

        if (titles.length) {
            titles.sort(Utils.SortByString);

            return titles.join(', ');
        }

        return i18next.t('IssueViewer.Content.NoContactsSelected');
    }

    export function ConcatManyContactGroupsToString(contactGroups: Array<string>): string {
        if (!(contactGroups || []).length) {
            return i18next.t('IssueViewer.Content.NoContactGroupsSelected');
        }

        const titles = [];

        for (let cCnt = 0, cLen = contactGroups.length; cCnt < cLen; cCnt++) {
            const contactGroup = DAL.ContactGroups.GetByOID(contactGroups[cCnt]);

            if (contactGroup && (contactGroup.Title || '').length) {
                titles.push(contactGroup.Title);
            }
        }

        if (titles.length) {
            titles.sort(Utils.SortByString);

            return titles.join(', ');
        }

        return i18next.t('IssueViewer.Content.NoContactGroupsSelected');
    }

    export function CheckResponsibilityAssignmentModifications(firstRevision: Model.Issues.ResponsibilityAssignments, secondRevision: Model.Issues.ResponsibilityAssignments): boolean {
        if (!firstRevision) {
            return true;
        }

        firstRevision.Users = firstRevision.Users || {};
        firstRevision.Teams = firstRevision.Teams || {};
        firstRevision.Contacts = firstRevision.Contacts || {};
        firstRevision.ContactGroups = firstRevision.ContactGroups || {};

        for (let type in firstRevision) {
            const modifications = firstRevision[type];
            const modificationBackup = secondRevision[type];

            if (!modifications) {
                continue;
            }

            if (!modificationBackup) {
                return true;
            }

            if (Utils.HasProperties(modifications) && !Utils.HasProperties(modificationBackup)) {
                return true;
            }

            if (!Utils.HasProperties(modifications) && Utils.HasProperties(modificationBackup)) {
                return true;
            }

            for (let identifier in modifications) {
                if (!modificationBackup[identifier]) {
                    return true;
                }

                const raciModification = modifications[identifier];
                const raciModificationBackup = modificationBackup[identifier];

                if (!!raciModification.IsResponsible !== !!raciModificationBackup.IsResponsible ||
                    !!raciModification.IsAccountable !== !!raciModificationBackup.IsAccountable ||
                    !!raciModification.IsConsulted !== !!raciModificationBackup.IsConsulted ||
                    !!raciModification.IsInformed !== !!raciModificationBackup.IsInformed) {
                    return true;
                }
            }

            for (let identifier in modificationBackup) {
                if (!modifications[identifier]) {
                    return true;
                }

                const raciModification = modifications[identifier];
                const raciModificationBackup = modificationBackup[identifier];

                if (!!raciModification.IsResponsible !== !!raciModificationBackup.IsResponsible ||
                    !!raciModification.IsAccountable !== !!raciModificationBackup.IsAccountable ||
                    !!raciModification.IsConsulted !== !!raciModificationBackup.IsConsulted ||
                    !!raciModification.IsInformed !== !!raciModificationBackup.IsInformed) {
                    return true;
                }
            }
        }

        return false;
    }

    export function ParseXmlString(xml: string, arrayTags: Array<string> = null): any {
        if (!xml) {
            return null;
        }

        const dom = (new DOMParser()).parseFromString(xml, 'text/xml');

        function parseNode(xmlNode: Element, result: any): void {
            if (xmlNode.nodeName == '#text') {
                const v = xmlNode.nodeValue;

                if (v.trim()) {
                    result['#text'] = v;
                }

                return;
            }

            const jsonNode = {};
            const existing = result[xmlNode.nodeName];

            if (existing) {
                if (!Utils.IsArray(existing)) {
                    result[xmlNode.nodeName] = [existing, jsonNode];
                } else {
                    result[xmlNode.nodeName].push(jsonNode);
                }
            } else {
                if (arrayTags && arrayTags.indexOf(xmlNode.nodeName) != -1) {
                    result[xmlNode.nodeName] = [jsonNode];
                } else {
                    result[xmlNode.nodeName] = jsonNode;
                }
            }

            if (xmlNode.attributes) {
                const length = xmlNode.attributes.length;

                for (let i = 0; i < length; i++) {
                    const attribute = xmlNode.attributes[i];
                    jsonNode[attribute.nodeName] = attribute.nodeValue;
                }
            }

            const length = xmlNode.childNodes.length;
            for (let i = 0; i < length; i++) {
                parseNode(xmlNode.childNodes[i] as Element, jsonNode);
            }
        }

        const result = {};
        for (let i = 0; i < dom.childNodes.length; i++) {
            parseNode(dom.childNodes[i] as Element, result);
        }

        return result;
    }

    export function OnLoginError(xhr?: XMLHttpRequest, closeFn?: Function): void {
        if (!xhr || !closeFn || typeof closeFn !== 'function') {
            return;
        }

        let title: string;
        let message: string;

        // define message content
        switch (xhr.status) {
            case Enums.HttpStatusCode.Unauthorized:
                title = i18next.t('Login.LoginFailed.MessageHeader');
                message = i18next.t('Login.LoginFailed.UnauthorizedMessageBody');
                break;

            case Enums.HttpStatusCode.Not_Found:
                title = i18next.t('Login.LoginFailed.MessageHeader');
                message = i18next.t('Login.LoginFailed.ServerNotFoundMessageBody');
                break;
            case Enums.HttpStatusCode.Forbidden:
                // Bei der Lizenzart 'Sichtrecht'
                title = i18next.t('Login.LoginFailed.MessageHeader');
                message = i18next.t('Login.LoginFailed.LoginNotAllowed');
                break;
            case 0:
                title = i18next.t('Login.LoginFailed.MessageHeader');
                message = i18next.t('Login.LoginFailed.NoResponseFromServerMessageBody');
                break;
            default:
                title = i18next.t('Login.LoginFailed.MessageHeader');
                message = i18next.t('Login.LoginFailed.GeneralErrorMessageBody');
        }

        if (Utils.Message.IsVisible()) {
            Utils.Message.Hide();
        }

        // show error message
        Utils.Message.Show(title, message, {
            Close: () => {
                closeFn();
            }
        });
    }

    export function DataTransferListToFileArray(transferList: DataTransferItemList, types?: string[]): Array<File> {
        // DataTransferItemList wird bei asynchronen Methoden geleert, daher werden die Liste umgehend in ein Datei-Array umgewandelt
        const array = [];

        types = types || [];

        for (let cnt = 0; cnt < (transferList || []).length; cnt++) {
            const item = transferList[cnt];

            if (types.length) {
                if (types[cnt] !== 'Files') {
                    continue;
                }
            } else {
                if (item.kind !== 'file') {
                    continue;
                }
            }

            const file = transferList[cnt].getAsFile();

            if (file) {
                array.push(file);
            }
        }

        return array;
    }

    export function LoopDeferredActions<T>(array: ArrayLike<T>, action: (item: T, index: number) => Deferred | null): Deferred {
        if (!(array || []).length || action == null) {
            return $.Deferred().resolve().promise();
        }

        const deferred = $.Deferred();

        const results = [];
        const len = array.length;
        let cnt = -1;

        (function loop() {
            cnt++;

            if (cnt >= len) {
                deferred.resolve(results);
                return;
            }

            const item = array[cnt];

            if (item == null) {
                loop();
                return;
            }

            (action(item, cnt) || $.Deferred().resolve().promise())
                .then((result: any) => {
                    results.push(result);
                    loop();
                }, deferred.reject);
        })()

        return deferred.promise();
    }

    export function IsEMailCpEnabled(): boolean {
        return Session.Client && Session.Client.Licenses &&
            Session.Client.Licenses.Contacts > 0 &&
            Session.Client.Licenses.EnableCpTypeEMailAddresses;
    }

    export function IsValidMailAddress(value: string): boolean {
        if (typeof value !== 'string') {
            return false;
        }

        value = value.trim();

        if (value.length < 0 || value.length > 254) {
            return false;
        }

        const regexp = /^((?:[^<>()[\]\.,;:\s@"']+(?:\.[^<>()[\]\.,;:\s@"']+)*)|(?:".+"))@((?!-)(?:[^<>()[\],\.;:_\s@"']+\.)*?(?:[^<>()[\]\.,;:\s@"](?!-)){2,})$/i;
        const result = regexp.exec(value);

        if (result == null || result.length !== 3) {
            return false;
        }

        const local = result[1];
        return !local.includes('..') && !local.includes('@');
    }

    export function GetAuthQueryParameter(prefix: string = '') {
        return Session && !!Session.AuthHash && Session.LastKnownAPIVersion < 21 && Session.IsSmartDeviceApplication ?
            `${prefix}auth=${Session.AuthHash}` :
            '';
    }

    export function GetBadgeCounterText(count: number) {
        if (isNaN(count) || count < 0) {
            return null;
        }

        return count >= 1000 ? `${Math.floor(count / 1000)}k` : count;
    }

    export function GetIndividualDataTypeOfElement(element: Model.Elements.Element): string {
        if (!element || element.Type !== Enums.ElementType.IndividualData) {
            return null;
        }

        if (!Utils.HasProperties(element.AdditionalSettings)) {
            return null;
        }

        return element.AdditionalSettings.Types[0];
    }

    export function GetSanitizedFilename(filename: string): string | null {
        if (!filename) {
            return null;
        }

        return filename.replace(/[/\\?%*:|"<>&]/g, '-');
    }

    /***
     * Prüft, ob die übergebenen Daten Arrays selber Länge und mit den selben Items darstellen.
     * @param arrayA
     * @param arrayB
     * @constructor
     */
    export function CompareArrays(arrayA: Array<any>, arrayB: Array<any>): boolean {
        if (!(arrayA instanceof Array) || !(arrayB instanceof Array)) {
            return false;
        }

        arrayA = arrayA || [];
        arrayB = arrayB || [];

        if (arrayA.length !== arrayB.length) {
            return false;
        }

        for (let i = 0, len = arrayA.length; i < len; i++) {
            const itemA = arrayA[i];

            if (arrayB.indexOf(itemA) === -1) {
                return false;
            }
        }

        return true;
    }

    export function IsCameraPresent(): Deferred {
        const deferred = $.Deferred();

        cordova
            .plugins
            .diagnostic
            .isCameraPresent(deferred.resolve, deferred.reject);

        return deferred.promise();
    }

    export module Overlay {
        const DEFAULT_OVERLAY_TIMEOUT = 150;

        let $previousOverlay = null;
        let timeoutID: number;

        export function Generate(id: string, zIndex: number, fn?: Function) {
            // prüfen ob vorangehendes overlay ersetzt werden kann
            if ($previousOverlay) {
                resetTimeout();
            }

            const $overlay = $(`<div id="${id}" class="overlay"></div>`);

            if (+zIndex) {
                $overlay.css('z-index', zIndex);
            }

            if (fn && fn instanceof Function) {
                $overlay.on('click', fn);
            }

            if ($previousOverlay) {
                $previousOverlay.replaceWith($overlay);
                $previousOverlay = null;
            } else {
                $('body').append($overlay);
            }

            return $overlay;
        }

        export function DestroyWithTimeout($overlay, timeout?: number) {
            if (!$overlay) {
                return;
            }

            if (isNaN(timeout) || timeout === null) {
                timeout = DEFAULT_OVERLAY_TIMEOUT;
            }

            if ($previousOverlay) {
                resetTimeout();
                // vorheriges overlay entfernen
                $previousOverlay.remove();
            }

            $previousOverlay = $overlay;

            if ($previousOverlay) {
                $previousOverlay.addClass('fade-out');
            }

            timeoutID = setTimeout(function() {
                timeoutID = null;

                if ($previousOverlay) {
                    // overlay komplett entfernen
                    $previousOverlay.remove();
                    $previousOverlay = null;
                }
            }, timeout);
        }

        function resetTimeout() {
            if (timeoutID && !isNaN(timeoutID)) {
                clearTimeout(timeoutID);
                timeoutID = null;
            }
        }
    }
}